import { useCallback, useEffect, useMemo, useState } from "react";
import { Card, Button, Nav } from "react-bootstrap";
import axios from "axios";
import { Avatar, Box, Skeleton } from "@mui/material";
import UpdateRoundedIcon from "@mui/icons-material/UpdateRounded";
import {
  PageViewIcon,
  StatsIcon,
  VisitsIcon,
} from "../../../../Components/Common/SvgIcons";
import { Spinner } from "reactstrap";
import common from "../../../../Components/Common/common";
import { ToastMessage } from "../../../../Components/Common/ToastMessage";
// import { useSelector } from "react-redux";
// import { useParams } from "react-router-dom";

const AnalyticsTable = ({ paramsId, assetsData }) => {
  const role = localStorage.getItem("role");
  // const params = useParams();

  const [googleData, setGoogleData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [fetchingData, setFetchingData] = useState(true);
  const [activeTab, setActiveTab] = useState("30");

  // const { apiDataLoading, apiLoadingAsset } = useSelector(
  //   (state) => state.onlineAssets
  // );

  const handleClickDays = (days, module) => {
    if (module === "analytics") {
      fetchAnalyticsData(days);
      setActiveTab(days);
    }
  };

  const fetchAnalyticsData = useCallback(
    async (days) => {
      try {
        setFetchingData(true);
        let response = await axios.post("asset/analytics-data", {
          assets_id: paramsId,
          day: days || "30",
        });

        const decryptedData = common.decrypt(response.data);
        response.data = decryptedData;

        if (response.status === "success") {
          setGoogleData(response.data);
        }
      } catch (error) {
        console.error("Error fetching analytics data:", error);
      } finally {
        setFetchingData(false); // Stop showing skeleton
      }
    },
    [paramsId]
  );

  useEffect(() => {
    fetchAnalyticsData();
  }, [fetchAnalyticsData]);

  // useEffect(() => {
  //   let intervalId;


  //   const startPolling = () => {
  //     intervalId = setInterval(() => {
  //       console.log("4444444444");
  //       fetchAnalyticsData();
  //     }, 30000);
  //   };

  //   if (assetsData?.isAPIFetched === false) {
  //     startPolling();
  //   } else if (assetsData?.isAPIFetched === true && intervalId) {
    
  //     clearInterval(intervalId);
  //   }
  //   return () => {
  //     if (intervalId) {
  //       clearInterval(intervalId);
  //     }
  //   };
  // }, [assetsData?.isAPIFetched, fetchAnalyticsData]);

  useMemo(() => {
    let intervalId;

    const startPolling = () => {
      intervalId = setInterval(() => {

        fetchAnalyticsData();
      }, 30000);
    };

    if (assetsData?.isAPIFetched === false) {
      startPolling();
    }

    return () => {
      if (intervalId) {
        clearInterval(intervalId);
      }
    };
  }, [assetsData?.isAPIFetched, fetchAnalyticsData]);

  const getRunTestApiData = async () => {
    try {
      setLoading(true);
      await axios.post("site-data/analytics-data-test", {
        assets_id: paramsId.paramsId,
      });
      ToastMessage.Success("your date has been updated.");
      fetchAnalyticsData("30");
      setActiveTab("30");
    } catch (error) {
      ToastMessage.Error("your date hasn't been updated. Please try again");
    } finally {
      setLoading(false);
    }
  };

  const DataofAnalytics = [
    {
      id: 1,
      icon: <VisitsIcon />,
      title: "Visits",
      value: googleData?.avg_visits != null ? googleData?.avg_visits : "-",
      AvatarBg: "#f6d2cf",
    },
    {
      id: 2,
      icon: <PageViewIcon />,
      title: "Pageviews",
      value:
        googleData?.avg_pageviews != null ? googleData?.avg_pageviews : "-",
      AvatarBg: "#e9c6d5",
    },
    {
      id: 3,
      icon: <UpdateRoundedIcon />,
      title: "Visit Time",
      value:
        googleData?.avg_visit_duration != null
          ? googleData?.avg_visit_duration
          : "-",
      AvatarBg: "#fbe6c5",
    },
    {
      id: 4,
      icon: <StatsIcon />,
      title: "Bounce Rate",
      value:
        googleData?.avg_bounce_rate != null ? googleData?.avg_bounce_rate : "-",
      AvatarBg: "#d1d9eb",
    },
    {
      id: 5,
      icon: <UpdateRoundedIcon />,
      title: "Session Time",
      value:
        googleData?.avg_avg_session_duration != null
          ? googleData?.avg_avg_session_duration
          : "-",
      AvatarBg: "#f6d2cf",
    },
  ];

  const renderAnalytics = DataofAnalytics.map((data) => {
    const mainvalue = data.value;
    return (
      <Box className="analytics-bar" key={data.id}>
        <Box className="d-flex justify-content-between align-items-center">
          <Box className="d-flex align-items-center">
            <Avatar
              sx={{
                width: 40,
                height: 40,
                background: data.AvatarBg,
              }}
              className="avatar"
            >
              {fetchingData ? (
                <Skeleton variant="circular" width={40} height={40} />
              ) : (
                data.icon
              )}
            </Avatar>
            <h6 className="title mb-0">
              {fetchingData ? <Skeleton width={60} /> : data.title}
            </h6>
          </Box>
          <span>
            <h6 className="value">
              {fetchingData ? (
                <Skeleton width={80} />
              ) : googleData?.mainvalue === null ? (
                <> - </>
              ) : (
                <>{mainvalue}</>
              )}
            </h6>
          </span>
        </Box>
      </Box>
    );
  });

  return (
    <>
      <Card>
        <Card.Header>
          <h4 className="card-title">Analytics</h4>
        </Card.Header>
        {/* <Card.Body className="nv_tab_com">
          {assetsData?.isAPIFetched === false ? (
            <div className="d-flex flex-column flex-grow-1 justify-content-center align-items-center mt-5">
              <div className="card-body text-center">
                <h5 className="text-muted">
                  We are currently retrieving the analytics data. Please hold on
                  for a moment while we gather all the details.
                </h5>
              </div>
            </div>
          ) : (
            <>
              <Nav
                className="nav-pills nav-justified mb-3 w-100"
                role="tablist"
              >
                <Nav.Item>
                  <button
                    onClick={() => handleClickDays("1", "analytics")}
                    className={`nav-link text-truncate ${
                      activeTab === "1" ? "active" : ""
                    }`}
                    title="Yesterday"
                    data-bs-toggle="tab"
                    role="tab"
                  >
                    Today
                  </button>
                </Nav.Item>
                <Nav.Item>
                  <button
                    onClick={() => handleClickDays("7", "analytics")}
                    title="Last 7d"
                    data-bs-toggle="tab"
                    role="tab"
                    className={`nav-link text-truncate ${
                      activeTab === "7" ? "active" : ""
                    }`}
                  >
                    Last 7d
                  </button>
                </Nav.Item>
                <Nav.Item>
                  <button
                    onClick={() => handleClickDays("30", "analytics")}
                    title="Last 30d"
                    data-bs-toggle="tab"
                    role="tab"
                    className={`nav-link text-truncate ${
                      activeTab === "30" ? "active" : ""
                    }`}
                  >
                    Last 30d
                  </button>
                </Nav.Item>
              </Nav>

              <Box className="tab-content text-muted mt-2">
                <Box
                  className="tab-pane active"
                  id="alast-hour"
                  role="tabpanel"
                >
                  {renderAnalytics}
                </Box>
              </Box>
            </>
          )}
          {role === "Super Admin" && (
            <>
              <Button
                title="RUN SCAN"
                onClick={getRunTestApiData}
                style={{ width: "100%", marginTop: "20px" }}
                disabled={loading}
              >
                {loading ? (
                  <>
                    <Spinner
                      as="span"
                      animation="border"
                      size="sm"
                      role="status"
                      aria-hidden="true"
                    />{" "}
                    Running Scan...
                  </>
                ) : (
                  "Run Scan"
                )}
              </Button>
              {loading && (
                <Box
                  className="alert alert-borderless alert-warning text-center mb-2 mx-2 mt-4"
                  role="alert"
                >
                  Please wait sometimes as we are running a scan
                </Box>
              )}
            </>
          )}
        </Card.Body> */}
        <Card.Body className="nv_tab_com">
          {assetsData?.isAPIFetched === false ? (
            <div className="d-flex flex-column flex-grow-1 justify-content-center align-items-center mt-5">
              <div className="card-body text-center">
                <h5 className="text-muted">
                  We are currently retrieving the analytics data. Please hold on
                  for a moment while we gather all the details.
                </h5>
              </div>
            </div>
          ) : (
            <>
              <Nav
                className="nav-pills nav-justified mb-3 w-100"
                role="tablist"
              >
                <Nav.Item>
                  {loading ? (
                    <Skeleton variant="rectangular" width="100%" height={50} />
                  ) : (
                    <button
                      onClick={() => handleClickDays("1", "analytics")}
                      className={`nav-link text-truncate ${
                        activeTab === "1" ? "active" : ""
                      }`}
                      title="Yesterday"
                      data-bs-toggle="tab"
                      role="tab"
                    >
                      Today
                    </button>
                  )}
                </Nav.Item>
                <Nav.Item>
                  {loading ? (
                    <Skeleton variant="rectangular" width="100%" height={50} />
                  ) : (
                    <button
                      onClick={() => handleClickDays("7", "analytics")}
                      title="Last 7d"
                      data-bs-toggle="tab"
                      role="tab"
                      className={`nav-link text-truncate ${
                        activeTab === "7" ? "active" : ""
                      }`}
                    >
                      Last 7d
                    </button>
                  )}
                </Nav.Item>
                <Nav.Item>
                  {loading ? (
                    <Skeleton variant="rectangular" width="100%" height={50} />
                  ) : (
                    <button
                      onClick={() => handleClickDays("30", "analytics")}
                      title="Last 30d"
                      data-bs-toggle="tab"
                      role="tab"
                      className={`nav-link text-truncate ${
                        activeTab === "30" ? "active" : ""
                      }`}
                    >
                      Last 30d
                    </button>
                  )}
                </Nav.Item>
              </Nav>

              <Box className="tab-content text-muted mt-2">
                <Box
                  className="tab-pane active"
                  id="alast-hour"
                  role="tabpanel"
                >
                  {loading ? (
                    <Skeleton variant="rectangular" width="100%" height={200} />
                  ) : (
                    renderAnalytics
                  )}
                </Box>
              </Box>
            </>
          )}

          {role === "Super Admin" && (
            <>
              <Button
                title="RUN SCAN"
                onClick={getRunTestApiData}
                style={{ width: "100%", marginTop: "20px" }}
                disabled={loading}
              >
                {loading ? (
                  <>
                    <Spinner
                      as="span"
                      animation="border"
                      size="sm"
                      role="status"
                      aria-hidden="true"
                    />{" "}
                    Running Scan...
                  </>
                ) : (
                  "Run Scan"
                )}
              </Button>
              {loading && (
                <Box
                  className="alert alert-borderless alert-warning text-center mb-2 mx-2 mt-4"
                  role="alert"
                >
                  Please wait sometimes as we are running a scan
                </Box>
              )}
            </>
          )}
        </Card.Body>
      </Card>
    </>
  );
};

export default AnalyticsTable;
