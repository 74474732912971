import DataTable from "react-data-table-component";
import { CustomTableStyle } from "../../Components/Common/constants";
import { useCallback, useEffect, useRef, useState } from "react";
import { ToastMessage } from "../../Components/Common/ToastMessage";
import common from "../../Components/Common/common";
import axios from "axios";
import { formatDateTime } from "../../Components/Common/FormatDateTIme";
import LoopIcon from "@mui/icons-material/Loop";
import { useDispatch, useSelector } from "react-redux";
import { initList } from "../../store/notification/action";
import { useLocation } from "react-router-dom";
import { Card } from "react-bootstrap";
import { Box } from "@mui/material";
import Flatpickr from "react-flatpickr";
import { Button } from "reactstrap";
import moment from "moment";

const NotificationPage = () => {
  const [notifications, setNotifications] = useState([]);
  const [loading, setLoading] = useState(false);
  const [totalRows, setTotalRows] = useState(0);
  const [perPage, setPerPage] = useState(10);
  const [selectedDate, setSelectedDate] = useState([]);
  const loginUser = localStorage.getItem("user_id");
  const location = useLocation();
  const { notificationList } = location.state || {};
  const flatpickrRef = useRef(null);

  const dispatch = useDispatch();

  const generalSettingData = useSelector(
    (state) => state.settingsData.settingsData
  );

  // const { notificationList, unreadCount } = useSelector(
  //   (state) => state.notification
  // );

  const otherSettingsData = generalSettingData?.filter(
    (item) => item.settings_category === "other"
  );

  const dateFormat = otherSettingsData?.find(
    (date) => date.field_name === "DEFAULT_DATE_FORMAT"
  )?.field_value;

  const timeZone = otherSettingsData?.find(
    (time) => time.field_name === "DEFAULT_TIME_FORMAT"
  )?.field_value;

  const timeFormat = otherSettingsData?.find(
    (zone) => zone.field_name === "DEFAULT_TIME_ZONE"
  )?.field_value;

  const handleDateChange = (selectedDates) => {
    if (selectedDates[0] !== undefined) {
      setSelectedDate(selectedDates);
    }
  };

  let [startDate, endDate] = selectedDate;
  if (startDate) {
    startDate = moment(startDate).format("YYYY-MM-DD");
  }
  if (endDate) {
    endDate = moment(endDate).format("YYYY-MM-DD");
  }

  const getAllNotifications = useCallback(
    async (page, perPageSize, startDate, endDate) => {
      try {
        setLoading(true);

        const params = {
          userId: loginUser,
          page: page,
          limit: perPageSize,
          startDate,
          endDate,
        };

        await axios.get(
          `${process.env.REACT_APP_BASE_URL}notifications/get-updatenotificationlist`,
          { params }
        );
        let response = await axios.get(
          `${process.env.REACT_APP_BASE_URL}notifications/get-notification`,
          { params }
        );

        if (response.status === "success") {
          const decryptedData = common.decrypt(response.data);
          response.data = decryptedData;
          setNotifications(response.data?.rows || []);
          setTotalRows(response.data?.count || 0);

          let finalResult = {
            count: response.data.count,
            rows: notificationList,
            unreadCount: response.data.unreadCount,
          };

          dispatch(initList(finalResult));
        } else if (response.data.status === "fail") {
          ToastMessage.Error(response.data.message);
        }
      } catch (error) {
        console.error("Error fetching data", error);
      } finally {
        setLoading(false);
      }
    },
    [loginUser, dispatch, notificationList]
  );

  useEffect(() => {
    getAllNotifications(1, perPage, startDate, endDate);
  }, [perPage, getAllNotifications, startDate, endDate]);

  const handlePageChange = (page) => {
    getAllNotifications(page, perPage, startDate, endDate);
  };

  const handlePerRowsChange = async (newPerPage) => {
    setPerPage(newPerPage);
    getAllNotifications(1, newPerPage, startDate, endDate); // Reset to first page with new perPage value
  };

  //Modified one
  const columns = [
    {
      name: "Notification",
      selector: (row) => row.txt_notification,
      sortable: true,
      cell: (row) => (
        <div style={{ whiteSpace: "normal", wordWrap: "break-word" }}>
          {row.txt_notification}
        </div>
      ),
    },
    {
      name: "Date",
      selector: (row) =>
        formatDateTime(row.createdAt, dateFormat, timeZone, timeFormat).timeZone
          .formattedDateFinal +
        " - " +
        formatDateTime(row.createdAt, dateFormat, timeZone, timeFormat).timeZone
          .formattedTimed,
      sortable: true,
      width: "180px",
    },
  ];

  const handleReset = () => {
    if (flatpickrRef.current) {
      flatpickrRef.current.flatpickr.clear();
    }
    setSelectedDate([]);
  };

  return (
    <Card className="table-main-card">
      <Box className="table-card-header">
        <Box className="left-side">
          {/* Select date for email logs */}
          <Box className="dateinput">
            <Flatpickr
              placeholder="Select Date"
              ref={flatpickrRef}
              className="form-control"
              options={{
                dateFormat: `${dateFormat}`,
                mode: "range",
              }}
              value={selectedDate}
              onChange={handleDateChange}
            />
          </Box>
          {/* Clear filter for email logs */}
          <Button className="btn btn-primary reset-btn" onClick={handleReset}>
            <LoopIcon />
          </Button>
        </Box>
      </Box>
      <Card.Body>
        <DataTable
          fixedHeight="500px"
          columns={columns}
          data={notifications}
          progressPending={loading}
          pagination
          customStyles={CustomTableStyle}
          paginationServer
          paginationTotalRows={totalRows}
          onChangePage={handlePageChange}
          onChangeRowsPerPage={handlePerRowsChange}
          paginationRowsPerPageOptions={[10, 20, 30, 40, 50]}
          className="table-design"
        />
      </Card.Body>
    </Card>
  );
};

export default NotificationPage;
