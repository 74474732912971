import React, { useCallback, useEffect, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import DataTable from "react-data-table-component";
import { ToastMessage } from "../../../Components/Common/ToastMessage";
import { Button, OverlayTrigger, Spinner, Tooltip } from "react-bootstrap";
import { useFormik } from "formik";
import * as Yup from "yup";
import ExtractDomain from "../../../Components/Common/extractDomain";
import { formatDateTime } from "../../../Components/Common/FormatDateTIme";
import { useDispatch, useSelector } from "react-redux";
import _ from "lodash";
import { ONLINE_ASSETS } from "../../../Components/Common/ModuleName";
import { socket } from "../../../Components/Common/socket";
import Swal from "sweetalert2";
import axios from "axios";
import { addpowerPanelSitesData } from "../../../store/actions";
import OnlineAssetModal from "../OnlineAssetModal";
import { CustomTableStyle } from "../../../Components/Common/constants";
import {
  Box,
  Card,
  FormControl,
  Tab,
  Autocomplete,
  TextField,
} from "@mui/material";
import LoopIcon from "@mui/icons-material/Loop";
import {
  DeleteIcon,
  DiscoverIcon,
  LightbulbOnIcon,
  StatsIcon,
  WindowAltIcon,
  WindowAltOutlineIcon,
} from "../../../Components/Common/SvgIcons";
import { TabContext, TabList } from "@mui/lab";
import {
  AutoDropdownStyle,
  MenuHeight,
  PerformanceTabStyle,
} from "../../NewDashboard/constant";
import { API_ROUTES } from "../../../Api/ApiRoutes";
import common from "../../../Components/Common/common";
import InfoIcon from "@mui/icons-material/Info";

const OnlineAssets = ({
  isDashboardView = false,
  paginationDisable = false,
  isAssetDetail = false,
  dragHandleProps,
}) => {
  const userId = localStorage.getItem("user_id");
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();

  // USER PERMISSION
  const permissionData = useSelector(
    (state) => state.userDetails.userPermission
  );
  const hasPermission = permissionData?.find(
    (item) => item.module_name === ONLINE_ASSETS
  );
  const userData = useSelector((state) => state.userDetails.userData);

  // Tabs
  const [tabValue, setTabValue] = useState("3");

  const [show, setShow] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [postIsLoading, setPostIsLoading] = useState(false);

  const [data, setData] = useState([]);
  const [filteredData, setFilteredData] = useState([]);
  const [selectedOption, setSelectedOption] = useState(null);
  const [searchTerm, setSearchTerm] = useState("");

  const [siteIds, setSiteIds] = useState([]);
  const [siteOfflineFlag, setSiteOfflineFlag] = useState(null);
  const [siteSlowFlag, setSiteSlowFlag] = useState(null);

  const [assetTypeOpt, setAssetTypeOpt] = useState(null);
  const [websiteGroupOpt, setWebsiteGroupOpt] = useState(null);

  const [clickedItemId, setClickedItemId] = useState(null);

  const [pillsTab, setpillsTab] = useState("");
  const pillsToggle = (tab) => {
    if (pillsTab !== tab) {
      setpillsTab(tab);
    }
  };

  useEffect(() => {
    if (location.state?.globalSearch) {
      setSearchTerm(location.state?.globalSearch);
    }
  }, [location.state]);

  // DATE TIME FORMAT
  const generalSettingData = useSelector(
    (state) => state.settingsData.settingsData
  );
  const otherSettingsData = generalSettingData?.filter(
    (item) => item.settings_category === "other"
  );
  const dateFormat = otherSettingsData?.find(
    (date) => date.field_name === "DEFAULT_DATE_FORMAT"
  )?.field_value;
  const timeZone = otherSettingsData?.find(
    (time) => time.field_name === "DEFAULT_TIME_FORMAT"
  )?.field_value;
  const timeFormat = otherSettingsData?.find(
    (zone) => zone.field_name === "DEFAULT_TIME_ZONE"
  )?.field_value;

  const validation = useFormik({
    enableReinitialize: true,

    initialValues: {
      website_short_name: "",
      website_full_name: "",
      organization: "",
      assets_type: "",
      website_url: "",
      department_id: "",
      ga_view_id: "",
      status: "Active",
      short_description: "",
    },

    validationSchema: Yup.object({
      organization: Yup.string()
        .trim()
        .min(5, "Organization short name must be at least 5 characters.")
        .max(255, "Organization short name must be less than 255 characters."),
      // .required("Organization is required."),
      assets_type: Yup.string().required("Please select asset type."),
      website_url: Yup.string()
        .trim()
        .test("is-valid-url", "Invalid URL format.", (value) => {
          if (!value) {
            return true; // Allow empty values (you can change this if needed)
          }
          const urlPattern =
            /^(https?:\/\/)?(www\.)?[-a-zA-Z0-9@:%._+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_+.~#?&//=]*)/g;
          return urlPattern.test(value);
        })
        .min(5, "Website URL must be at least 5 characters.")
        .max(255)
        .required("Please enter website URL."),
      website_short_name: Yup.string()
        .trim()
        .min(5, "Website short name must be at least 5 characters.")
        .max(255)
        .required("Please enter website short name."),
      website_full_name: Yup.string()
        .trim()
        .min(5, "Website Full Name must be at least 5 characters.")
        .max(255)
        .required("Please enter website full name."),
      department_id: Yup.string().required("Please select website category."),
      ga_view_id: Yup.string()
        .trim()
        .min(5, "Google Analytics ID must be at least 5 characters.")
        .max(255)
        .required("Please enter google analytics ID."),
      // status: Yup.string().required("Status is required."),
      short_description: Yup.string()
        .trim()
        .min(5, "Short Description must be at least 5 characters.")
        .max(255, "Short Description must be less than 255 characters."),
    }),

    onSubmit: (values) => {
      postOnlineAssets(values);
    },
  });

  const handleClose = () => {
    validation.resetForm();
    setAssetTypeOpt(null);
    setWebsiteGroupOpt(null);

    setShow(false);
  };

  const powerPanelSitesData = useSelector(
    (state) => state.onlineAssets.sitesData
  );

  useEffect(() => {
    setSiteIds(powerPanelSitesData);
  }, [powerPanelSitesData]);

  const handleFilter = () => {
    setSiteSlowFlag(null);
    setSiteOfflineFlag(true);
    // fetchData(searchTerm, true, null, isDashboardView);
  };

  const handleSlowFilter = () => {
    setSiteOfflineFlag(null);
    setSiteSlowFlag(true);
    // fetchData(searchTerm, null, true, isDashboardView);
  };

  const handleReset = () => {
    const slowFilteredItems = data.filter((item) => item.page_speed < 100);
    setFilteredData(slowFilteredItems);
    setSelectedOption(null);
    fetchData(searchTerm, null, null, isDashboardView);
    setSearchTerm("");
  };

  const navigateToTicktingSystem = (assetId) => {
    navigate(`/online-assets/${assetId}`, { state: { tab: "7" } });
  };

  const navigateToComment = (assetId) => {
    navigate(`/online-assets/${assetId}`, { state: { tab: "8" } });
  };

  const [websiteGroupOptions, setWebsiteGroupOptions] = useState([]);

  const fetchWebsiteCategories = async () => {
    try {
      let { status, data } = await axios.get(API_ROUTES.GET_WEBSITE_CATEGORIES);
      const responseData = common.decrypt(data);
      if (status === "success" && responseData) {
        setWebsiteGroupOptions(
          responseData.map((item) => {
            return {
              label: item.category,
              value: item.category,
              id: item.id,
            };
          })
        );
      }
    } catch (err) {
      console.error(err.message);
      setWebsiteGroupOptions([]);
    }
  };

  useEffect(() => {
    fetchWebsiteCategories();
  }, []);

  const fetchData = useCallback(
    async (
      searchedParams,
      siteOffline = null,
      slowSite = null,
      isDashboardView
    ) => {
      // setIsLoading(true);
      try {
        let apiUrl = `${process.env.REACT_APP_BASE_URL}asset/online-assets?searchParams=${searchedParams}`;
        if (siteOffline !== null) {
          apiUrl += `&siteOffline=${siteOffline}`;
        }
        if (slowSite !== null) {
          apiUrl += `&slowSite=${slowSite}`;
        }
        if (isDashboardView) {
          apiUrl += `&isDashboardView=${isDashboardView}`;
        }
        let { code, status, data } = await axios.get(apiUrl);
        if (code === 403) {
          navigate("/auth-404-cover");
        }
        if (status === "success") {
          const responseData = common.decrypt(data);
          setData(responseData);
          setIsLoading(false);
        }
      } catch (error) {
        console.error("Error during data fetching:", error);
      }
    },
    [navigate]
  );

  useEffect(() => {
    const debouncedFetchData = _.debounce(() => {
      fetchData(searchTerm, siteOfflineFlag, siteSlowFlag, isDashboardView);
    }, 500);

    debouncedFetchData();

    return () => {
      debouncedFetchData.cancel();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchTerm, siteOfflineFlag, siteSlowFlag]);

  // const fetchApiData = async (assetId) => {
  //   try {
  //     let response = await axios.post(`online-assets/fetch-api-data`, {
  //       assetId: assetId,
  //     });
  //     const decryptedData = common.decrypt(response.data);
  //     response.data = decryptedData;
  //     return response;
  //   } catch (error) {
  //     console.error(error);
  //   }
  // };

  // useEffect(() => {
  //   dispatch(fetchApiData(197));
  // }, []);

  const getPowerPanelSitesList = async () => {
    try {
      let { status, data } = await axios.get(
        `online-assets/get-powerpanel-sites`
      );
      if (status === "success") {
        dispatch(addpowerPanelSitesData(data));
      }
    } catch (error) {
      console.error(error);
    }
  };

  const postOnlineAssets = async (values) => {
    try {
      const dataValues = {
        ...values,
        user_id: userId,
      };
      setPostIsLoading(true);

      // const { status, code, message, data } = await axios.post(
      //   `online-assets/online-assets-post`,
      //   common.encrypt(dataValues)
      // );

      const response = await axios.post(
        `online-assets/online-assets-post`,
        common.encrypt(dataValues)
      );
      let id;
      if (response.status !== "fail") id = common.decrypt(response.data).Id;
      if (response.code === 403) {
        navigate("/auth-404-cover");
      }
      if (response.status === "success") {
        setShow(false);
        ToastMessage.Success(
          "asset added successfully, \nplease do not refresh the page until we are fetching the website security data.",
          10000
        );
        navigate("/online-assets");
        socket.emit("addOnlineAsset");
        fetchData(" ");
        validation.resetForm();
        setAssetTypeOpt(null);
        setWebsiteGroupOpt(null);
        setPostIsLoading(false);
        getPowerPanelSitesList();
      }
      if (response.status === "fail") {
        ToastMessage.Error(response.message);
        setAssetTypeOpt(null);
        setWebsiteGroupOpt(null);
        setPostIsLoading(false);
      }
    } catch (error) {
      console.error(error);
      ToastMessage.Error(error);
    } finally {
      setPostIsLoading(false);
    }
  };

  const calculateDowntimeLabel = (downtimeStr) => {
    // Check if the format is "HH:MM:SS"
    if (downtimeStr.includes(":")) {
      const [hours, minutes, seconds] = downtimeStr.split(":").map(Number);
      const totalDowntimeMinutes = hours * 60 + minutes + seconds / 60;
      return totalDowntimeMinutes;
    }
    // Check if the format is "X MINUTES"
    else if (downtimeStr.includes("MINUTES")) {
      const minutes = parseInt(downtimeStr.split(" ")[0], 10);
      return minutes;
    }
    // Handle any other format as 0 minutes
    else {
      return 0;
    }
  };

  const navigateToPowerpanel = _.debounce(async (row) => {
    try {
      const parsedUrl = new URL(row?.website_url);
      let domain = common.encrypt(parsedUrl?.hostname);

      await axios
        .post(`users-website/sso-login`, {
          domain: domain.data,
        })
        .then(async (response) => {
          let { data } = await common.decrypt(response.data);

          window.open(data, "_blank");
          setClickedItemId(row.id);
        });
    } catch (error) {
      console.error(error);
    }
  }, 1000);

  const deleteonlineasset = async (id) => {
    try {
      await axios
        .post(`online-assets/online-assets-delete/${id}`, { userId })
        .then((response) => {
          if (response.code === 403) {
            navigate("/auth-404-cover");
          }
          if (response.status === "success") {
            navigate("/online-assets");
            socket.emit("deleteonlineasset");
            fetchData(" ");
          }
          if (response.status === "fail") {
            fetchData(" ");
            navigate("/online-assets");
          }
        });
    } catch (error) {
      console.error(error);
    }
  };

  const handleDeleteAlert = (id) => {
    const swalWithBootstrapButtons = Swal.mixin({
      customClass: {
        confirmButton: "btn btn-dark mx-2",
        cancelButton: "btn btn-primary mx-2",
      },
      buttonsStyling: false,
    });

    swalWithBootstrapButtons
      .fire({
        title: "Are you sure?",
        text: "You won't be able to revert this!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes, delete it!",
        cancelButtonText: "No, cancel!",
        reverseButtons: true,
      })
      .then((result) => {
        if (result.isConfirmed) {
          deleteonlineasset(id);
          swalWithBootstrapButtons.fire(
            "Deleted!",
            "Online asset has been deleted.",
            "success"
          );
        } else if (result.dismiss === Swal.DismissReason.cancel) {
          swalWithBootstrapButtons.fire(
            "Cancelled",
            "Online asset is safe :)",
            "error"
          );
        }
      });
  };

  const columns = [
    {
      name: <span>Sites</span>,
      selector: (row) => (
        <span>
          <Link
            to={"/online-assets/" + row.id}
            className="fw-bold link-primary"
          >
            {ExtractDomain(row.website_url)}
          </Link>
        </span>
      ),
      grow: 2.5,
    },
    {
      name: <span></span>,
      selector: (row) => (
        <>
          {siteIds?.includes(row.id) ? (
            <span>
              <OverlayTrigger
                key="top"
                placement="top"
                overlay={
                  <Tooltip id={`tooltip-${row.id}`}>Instant Login</Tooltip>
                }
              >
                <span
                  style={{ opacity: clickedItemId === row.id ? "0.5" : "1" }}
                  className="mdi mdi-shield-key fs-4 text-success cursor-pointer"
                  onClick={() => navigateToPowerpanel(row)}
                ></span>
              </OverlayTrigger>
            </span>
          ) : (
            <> -</>
          )}
        </>
      ),
      width: "50px",
      center: true,
    },
    {
      // name: <span>Metrics</span>,
      name: (
        <Box className="d-flex align-items-center">
          <span>Metrics</span>
          <OverlayTrigger
            key="right-info-icon"
            placement="right"
            overlay={
              <Tooltip id="tooltip-right-info">
                <Box className="d-flex flex-column gap-2">
                  <Box className="d-flex align-items-center">
                    <span className="mdi mdi-shield-key fs-4 text-success cursor-pointer">
                      {/* <InfoIcon width={18} height={18} /> */}
                    </span>
                    <span className="ms-2">Instant Login</span>
                  </Box>
                  <Box className="d-flex align-items-center">
                    <span className="mdi mdi-lightbulb-on fs-4 text-success">
                      {/* <InfoIcon width={18} height={18} /> */}
                    </span>
                    <span className="ms-2">Website Up</span>
                  </Box>
                  <Box className="d-flex align-items-center">
                    {/* <span className="mdi mdi-lightbulb-on fs-4 text-danger">
                    
                    </span> */}
                    <span
                      className="icon-md mdi mdi-lightbulb-on fs-4"
                      style={{ color: "red" }}
                    ></span>
                    <span className="ms-2">Website Down</span>
                  </Box>
                  <Box className="d-flex align-items-center">
                    <span className="mdi mdi-window-alt-outline fs-4">
                      <WindowAltOutlineIcon width={18} height={18} />
                    </span>
                    <span className="ms-2">Page Speed</span>
                  </Box>
                  <Box className="d-flex align-items-center">
                    <span className="mdi mdi-chart-bar fs-4">
                      {/* <InfoIcon width={18} height={18} /> */}
                    </span>
                    <span className="ms-2">Traffic Statistics</span>
                  </Box>
                </Box>
              </Tooltip>
            }
          >
            <span className="ms-2">
              <InfoIcon width={18} height={18} fontSize="small" />
            </span>
          </OverlayTrigger>
        </Box>
      ),
      selector: (row) => (
        <Box className="d-flex align-items-center">
          <span className="website-up-down px-3">
            <OverlayTrigger
              key="top"
              placement="top"
              overlay={
                <Tooltip id="tooltip-top">
                  {" "}
                  <span>
                    {row.website_status?.includes("U") ? (
                      <> Website Up </>
                    ) : row.website_status?.includes("D") ? (
                      <>
                        {" "}
                        Website Down Since{" "}
                        {
                          formatDateTime(
                            row.downtime,
                            dateFormat,
                            timeZone,
                            timeFormat
                          ).timeZone.formattedDateFinal
                        }
                        .
                      </>
                    ) : (
                      <> - </>
                    )}
                  </span>
                </Tooltip>
              }
            >
              {row.website_status?.includes("U") ? (
                <span className="icon-md mdi mdi-lightbulb-on fs-4 text-success"></span>
              ) : row.website_status?.includes("D") ? (
                calculateDowntimeLabel(row.downtime) > 60 ? (
                  <span
                    style={{ color: "red" }}
                    className="icon-md mdi mdi-lightbulb fs-4"
                  ></span>
                ) : (
                  // <span className="icon-md mdi mdi-lightbulb-on fs-4 text-danger"></span>
                  <span
                    className="icon-md mdi mdi-lightbulb-on fs-4"
                    style={{ color: "red" }}
                  ></span>
                )
              ) : (
                <>-</>
              )}
            </OverlayTrigger>
          </span>
          <span className="pagespeed px-3 d-flex align-items-center">
            <OverlayTrigger
              key="top"
              placement="top"
              overlay={<Tooltip id="tooltip-top">Page Speed</Tooltip>}
            >
              {row.page_speed ? (
                <span>
                  <WindowAltOutlineIcon width={18} height={18} />
                  &nbsp;&nbsp;
                </span>
              ) : (
                <> </>
              )}
            </OverlayTrigger>
            <span>
              {" "}
              {row.page_speed ? (
                <span className="fs-14"> {row.page_speed} </span>
              ) : (
                <span> - </span>
              )}{" "}
            </span>
            <i className="bi bi-reception-4"></i>
          </span>
          <Box className="traffic px-3">
            {row.traffic ? (
              <span>
                <StatsIcon width={18} height={18} />
                &nbsp;
                <OverlayTrigger
                  key="top"
                  placement="top"
                  overlay={
                    <Tooltip id="tooltip-top">Traffic Statistics</Tooltip>
                  }
                >
                  <span className="fs-14"> {row.traffic} </span>
                </OverlayTrigger>
              </span>
            ) : (
              <> - </>
            )}
          </Box>
        </Box>
      ),
      grow: 1.5,
      width: "250px",
    },

    {
      name: <span>Updates</span>,
      selector: (row) => (
        <>{row.updates ? <span>{row.version}</span> : <> - </>}</>
      ),
      minWidth: "80px",
      center: true,
    },
    {
      // name: <span>SSL</span>,
      name: (
        <Box className="d-flex align-items-center">
          <span>SSL</span>
          <OverlayTrigger
            key="right-info-icon"
            placement="right"
            overlay={
              <Tooltip id="tooltip-right-info">
                <Box className="d-flex flex-column gap-2">
                  <Box className="d-flex align-items-center">
                    <span
                      style={{ color: "green" }}
                      className="mdi mdi-shield-lock-open fs-4"
                    ></span>
                    <span className="ms-2">SSL Installed</span>
                  </Box>
                  <Box className="d-flex align-items-center">
                    <span
                      style={{ color: "red" }}
                      className="mdi mdi-shield-lock-open fs-4"
                    ></span>
                    <span className="ms-2">SSL Expired</span>
                  </Box>
                </Box>
              </Tooltip>
            }
          >
            <span className="ms-2">
              <InfoIcon width={18} height={18} fontSize="small" />
            </span>
          </OverlayTrigger>
        </Box>
      ),
      selector: (row) => (
        <>
          {row.has_ssl_tls === 0 ? (
            <span>
              <OverlayTrigger
                key="top"
                placement="top"
                overlay={<Tooltip id="tooltip-top">SSL Expired</Tooltip>}
              >
                <span
                  style={{ color: "red" }}
                  className="mdi mdi-shield-lock-open fs-4"
                >
                  {" "}
                </span>
              </OverlayTrigger>
            </span>
          ) : row.has_ssl_tls === 1 ? (
            <span>
              <OverlayTrigger
                key="top"
                placement="top"
                overlay={<Tooltip id="tooltip-top">SSL Installed</Tooltip>}
              >
                <span
                  style={{ color: "green" }}
                  className="mdi mdi-shield-lock-open fs-4"
                >
                  {" "}
                </span>
              </OverlayTrigger>
            </span>
          ) : (
            <> - </>
          )}
        </>
      ),
      minWidth: "50px",
      center: true,
    },

    {
      name: <span>Down Time</span>,
      selector: (row) => {
        if (row.website_status === "D") {
          const remainingMinutes = common.calculateRemainingDowntimeMinutes(
            row.downtime
          );
          const formattedTime =
            common.downTimeformatDifference(remainingMinutes);

          return (
            <span className="badge bg-soft-success text-uppercase rounded-4 px-2 py-1">
              {formattedTime}
            </span>
          );
        }

        return (
          <span className="badge bg-soft-success text-uppercase rounded-4 px-2 py-1">
            0 M
          </span>
        );
      },
      minWidth: "150px",
      center: true,
    },
    {
      name: <span>Security</span>,
      selector: (row) => (
        <>
          <span>
            {row.security
              ? formatDateTime(row.security, dateFormat, timeZone, timeFormat)
                  .timeZone.formattedDateFinal
              : "-"}
          </span>
        </>
      ),
      minWidth: "120px",
    },
    {
      name: <span>Comments</span>,
      selector: (row) => (
        <>
          {row.comments === 0 ? (
            <>
              <span className="badge bg-soft-primary text-uppercase">
                No comments
              </span>
            </>
          ) : (
            <>
              <span
                className="badge bg-soft-primary text-uppercase"
                style={{ cursor: "pointer" }}
                onClick={() => navigateToComment(row.id)}
              >
                {row.comments} comments
              </span>
            </>
          )}
        </>
      ),
      minWidth: "160px",
      center: true,
    },
    // {
    //   name: <span>Tickets</span>,
    //   selector: (row) => (
    //     <>
    //       {row?.ticket_details?.ticket_id ? (
    //         <>
    //           <span
    //             className="status text-center"
    //             style={{ cursor: "pointer" }}
    //           >
    //             <span
    //               className="badge bg-soft-success"
    //               onClick={() => navigateToTicktingSystem(row.id)}
    //             >
    //               #{row?.ticket_details?.ticket_id}
    //             </span>
    //           </span>
    //         </>
    //       ) : (
    //         <> - </>
    //       )}
    //     </>
    //   ),
    //   width: "80px",
    //   center: true,
    // },
    {
      name: <span>Tickets</span>,
      selector: (row) => (
        <>
          {row?.ticket_details?.ticket_id ? (
            <>
              <span
                className="status text-center"
                style={{ cursor: "pointer" }}
              >
                <span
                  // className="badge bg-soft-success"
                  style={{
                    display: "inline-block",
                    padding: "0.35em 0.7em",
                    lineHeight: "1",
                    color: "#fff",
                    textAlign: "center",
                    whiteSpace: "nowrap",
                    verticalAlign: "baseline",
                    borderRadius: "30px",
                    backgroundColor: common.getColor(
                      row?.ticket_details?.status
                    ),
                  }}
                  onClick={() => navigateToTicktingSystem(row.id)}
                >
                  #{row?.ticket_details?.ticket_id}
                </span>
              </span>
            </>
          ) : (
            <> - </>
          )}
        </>
      ),
      width: "80px",
      center: true,
    },

    {
      name: (
        <span className="text-center">
          {" "}
          {!isDashboardView && hasPermission && hasPermission?.canDelete != null
            ? "Actions"
            : ""}
        </span>
      ),
      cell: (row) => (
        <span className="text-center">
          {!isDashboardView &&
          hasPermission &&
          hasPermission?.canDelete != null ? (
            <OverlayTrigger
              placement="top"
              overlay={
                <Tooltip id={`tooltip-delete-${row.id}`}>Delete</Tooltip>
              }
            >
              <span
                className="cursor-pointer"
                onClick={() => handleDeleteAlert(row.id)}
              >
                <DeleteIcon />
              </span>
            </OverlayTrigger>
          ) : (
            ""
          )}
        </span>
      ),
      width: "85px",
      center: true,
    },
  ];

  useEffect(() => {
    let filtered = data;

    if (selectedOption) {
      filtered = data.filter(
        (item) => item.department_id === selectedOption.id
      );
    }

    setFilteredData(filtered);
  }, [selectedOption, data]);

  const handleChangeParformance = (event, newValue) => {
    setTabValue(newValue);
    setIsLoading(true);
    setTimeout(() => {
      let filtered = data;
      if (selectedOption) {
        filtered = data.filter(
          (item) => item.department_id === selectedOption.id
        );
      }
      setFilteredData(filtered);
      setIsLoading(false);
    }, 500);
  };

  const handleHardReset = () => {
    if (pillsTab === "2" || pillsTab === 2) {
      setSiteOfflineFlag(null);
      setSiteSlowFlag(true);
      setSearchTerm("");
      setSelectedOption(null);
      pillsToggle("2");
    } else if (pillsTab === "1" || pillsTab === 1) {
      setSiteSlowFlag(null);
      setSiteOfflineFlag(true);
      setSearchTerm("");
      setSelectedOption(null);
      pillsToggle("1");
    } else {
      setSiteOfflineFlag(null);
      setSiteSlowFlag(null);
      setSearchTerm("");
      setSelectedOption(null);
      pillsToggle("3");
    }
  };

  return (
    <React.Fragment>
      <ToastContainer closeButton={false} />

      <Box className="site-table-container">
        <Card>
          <Box
            className="card-header gap-2"
            // {...dragHandleProps}
            {...(isDashboardView ? dragHandleProps : {})}
            style={{
              // cursor: "grab",
              cursor: isDashboardView ? "grab" : "default",
            }}
          >
            <TabContext value={tabValue}>
              <Box>
                <TabList
                  onChange={handleChangeParformance}
                  variant="scrollable"
                  allowScrollButtonsMobile
                  sx={PerformanceTabStyle}
                >
                  <Tab
                    icon={<DiscoverIcon />}
                    label="All Sites"
                    value="3"
                    iconPosition="start"
                    onClick={() => {
                      setSiteOfflineFlag(null);
                      setSiteSlowFlag(null);
                      pillsToggle("3");
                      handleReset(" ");
                    }}
                  />
                  <Tab
                    icon={<LightbulbOnIcon />}
                    label="Site Offline"
                    value="1"
                    iconPosition="start"
                    onClick={() => {
                      pillsToggle("1");
                      handleFilter();
                    }}
                  />
                  <Tab
                    icon={<WindowAltIcon />}
                    label="Slow Site"
                    value="2"
                    iconPosition="start"
                    onClick={() => {
                      pillsToggle("2");
                      handleSlowFilter();
                    }}
                  />
                </TabList>
              </Box>
            </TabContext>

            <Box className="d-flex overflow-auto ms-md-auto">
              <Box className="d-flex align-items-start">
                {!isDashboardView && (
                  <Box className="ms-4 m-2">
                    <Box className="search-box">
                      <input
                        type="text"
                        className="form-control search"
                        placeholder="Search website..."
                        value={searchTerm}
                        onChange={(e) => setSearchTerm(e.target.value)}
                      />
                      <i className="ri-search-line search-icon"></i>
                    </Box>
                  </Box>
                )}

                {!isDashboardView &&
                  (userData?.role === "Super Admin" ||
                    userData?.role === "Admin") && (
                    <>
                      {/* <FormControl
                        fullWidth
                        variant="filled"
                        sx={{
                          width: "150px",
                          minWidth: "150px",
                          display: "inline-block",
                          margin: "0.5rem",
                        }}
                      >
                        <Select
                          displayEmpty
                          fullWidth
                          MenuProps={MenuHeight}
                          value={
                            websiteGroupOptions.find(
                              (option) => option.value === selectedOption?.value
                            ) || ""
                          }
                          onChange={(event) =>
                            setSelectedOption(event.target.value)
                          }
                          sx={DropdownStyle}
                        >
                          <MenuItem value="" disabled>
                            Select Website Category
                          </MenuItem>
                          {websiteGroupOptions.map((option) => (
                            <MenuItem
                              key={option.id}
                              value={option}
                              sx={{ whiteSpace: "break-spaces" }}
                            >
                              {option.label}
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl> */}
                      <FormControl
                        fullWidth
                        variant="filled"
                        sx={{
                          width: "220px",
                          margin: "0.5rem 0.9rem 0.5rem 0.5rem",
                        }}
                      >
                        <Autocomplete
                          options={websiteGroupOptions}
                          getOptionLabel={(option) => option.label}
                          value={selectedOption || null}
                          onChange={(event, newValue) =>
                            setSelectedOption(newValue)
                          }
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              label="Select Website Category"
                            />
                          )}
                          fullWidth
                          MenuProps={MenuHeight}
                          sx={AutoDropdownStyle}
                          isOptionEqualToValue={(option, value) =>
                            option.value === value?.value
                          }
                          disableClearable
                          renderOption={(props, option) => (
                            <li
                              {...props}
                              style={{ whiteSpace: "break-spaces" }}
                            >
                              {option.label}
                            </li>
                          )}
                        />
                      </FormControl>
                    </>
                  )}
                {!isDashboardView && (
                  <Button
                    className="btn btn-primary reset-btn mt-2 me-2"
                    onClick={handleHardReset}
                  >
                    <LoopIcon />
                  </Button>
                )}
              </Box>
              <Box className="ms-auto">
                {!isDashboardView && (
                  <>
                    {hasPermission && hasPermission.canCreate != null ? (
                      <>
                        <Button
                          // type="button"
                          className="btn btn-primary d-flex ms-auto view-btn online-btn"
                          style={{ width: "185px" }}
                          onClick={() => setShow(true)}
                        >
                          <i className="ri-add-line align-bottom me-1"></i>
                          Online Asset
                        </Button>
                      </>
                    ) : null}
                  </>
                )}
                {isDashboardView && (
                  <button
                    className="btn btn-primary d-flex ms-auto view-btn"
                    onClick={() => navigate("/online-assets")}
                  >
                    View All
                  </button>
                )}
              </Box>
            </Box>
          </Box>
          <Box className="p-4">
            {/* {isLoading && filteredData.length !== 0 ? ( */}
            {isLoading ? (
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  // height: "30vh",
                  height: isDashboardView ? "30vh" : "50vh",
                }}
              >
                <Spinner height={100} width={100} />
              </div>
            ) : (
              <DataTable
                columns={columns}
                data={filteredData}
                fixedHeader
                fixedHeaderScrollHeight="500px"
                pagination={!isDashboardView}
                paginationPerPage={10}
                // customStyles={customStyles}
                customStyles={CustomTableStyle}
                className="table-design"
              />
            )}
          </Box>
        </Card>
      </Box>

      <OnlineAssetModal
        validation={validation}
        show={show}
        handleClose={handleClose}
        postIsLoading={postIsLoading}
        assetTypeOpt={assetTypeOpt}
        setAssetTypeOpt={setAssetTypeOpt}
        websiteGroupOptions={websiteGroupOptions}
        websiteGroupOpt={websiteGroupOpt}
        setWebsiteGroupOpt={setWebsiteGroupOpt}
      />
    </React.Fragment>
  );
};

export default OnlineAssets;
