import React, { useCallback, useEffect, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { Card, CardBody } from "reactstrap";
import { Modal, Row } from "react-bootstrap";
import SwiperCore, { Autoplay } from "swiper";
import { ToastMessage } from "../../../Components/Common/ToastMessage";
import ProfileImage from "../../../Components/Common/ProfileImage";
import { ToastContainer } from "react-toastify";
import Projects from "./Projects";
import { useSelector } from "react-redux";
import { DEVELOPER_PROFILE } from "../../../Components/Common/ModuleName";
import {
  Avatar,
  Badge,
  Box,
  Chip,
  IconButton,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Skeleton,
  Stack,
  Tab,
} from "@mui/material";
import { EditIcon } from "../../../Components/Common/SvgIcons";
import { TabContext, TabList, TabPanel } from "@mui/lab";
import { PerformanceTabStyle } from "../../NewDashboard/constant";
import CallIcon from "@mui/icons-material/Call";
import EmailIcon from "@mui/icons-material/Email";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import EditDeveloperProfile from "./EditDeveloperProfile";
import { useFormik } from "formik";
import { socket } from "../../../Components/Common/socket";
import coverImg from "../../../assets/images/profile-bg.jpg";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import ProfileDetail from "./ProfileDetail";
import common from "../../../Components/Common/common";

const DeveloperProfileDetails = (props) => {
  const permissionData = useSelector(
    (state) => state.userDetails.userPermission
  );
  const hasPermission = permissionData?.find(
    (item) => item.module_name === DEVELOPER_PROFILE
  );

  const token = localStorage.getItem("token");
  const [selectedOptionsForAssets, setSelectedOptionsForAssets] = useState([]);
  // const [unmatchedAssets, setUnmatchedAssets] = useState([]);
  // const [allAssetsId, setAallAssetsId] = useState([]);

  const [updatedValue, setUpdatedValue] = useState(false);

  const [show, setShow] = useState(false);
  const [selectedCoverImage, setSelectedCoverImage] = useState(null);
  const [BgImage, setBgImage] = useState(false);
  const [selectedBgImage, setSelectedBgImage] = useState(null);
  const [dataLoading, setDataLoading] = useState(false);
  const navigate = useNavigate();
  const params = useParams();

  SwiperCore.use([Autoplay]);
  const [data, setData] = useState([]);

  /////*  FOR SKILLS   */////
  const [selectedOptions, setSelectedOptions] = useState([]);

  const skillsEndPoint = `${process.env.REACT_APP_BASE_URL}skills/get-skills`;
  const newSkillsEndPoint = `${process.env.REACT_APP_BASE_URL}developer-profile/get-developer-profile-id/${params.id}`;

  useEffect(() => {
    fetch(skillsEndPoint, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    })
      .then((response) => response.json())
      .then((data) => {
        const responseData = common.decrypt(data.data);
        const skillIds = responseData?.map((item) => ({
          label: item.skill_name,
          value: item.id,
        }));

        fetch(newSkillsEndPoint, {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        })
          .then((response) => response.json())
          .then((newData) => {
            const responseData = common.decrypt(newData.data);
            newData.data = responseData;
            const newSkillIds = JSON.parse(newData.data.skills);
            const matchedOptions = skillIds.filter((option) =>
              newSkillIds.includes(option.value)
            );
            setSelectedOptions(matchedOptions);
          });
      });
  }, [skillsEndPoint, newSkillsEndPoint, token, updatedValue]);

  const getUserData = useCallback(async () => {
    setDataLoading(true);
    let url = `${process.env.REACT_APP_BASE_URL}developer-profile/get-developer-profile-id/${params.id}`;
    let reqData = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    };

    try {
      let result = await fetch(url, reqData);
      let response = await result.json();
      if (response.status === "success") {
        const decryptedData = common.decrypt(response.data);
        response.data = decryptedData;
        setData(response.data);
        setDataLoading(false);
      } else if (response.status === "fail") {
        ToastMessage.Error(
          "Error in fetching the data, Please try again later"
        );
      }
    } catch (error) {
      setDataLoading(false);
      ToastMessage.Error(
        "An unexpected error occurred. Please try again later."
      );
      console.error("Error fetching user data:", error);
    } finally {
      setDataLoading(false);
    }
  }, [token, params.id]);

  useEffect(() => {
    getUserData();
  }, [getUserData, updatedValue]);

  const assetUrlManagementEndpoint = `${process.env.REACT_APP_BASE_URL}online-assets/online-assets-list`;
  const newAssetUrlManagementEndpoint = `${process.env.REACT_APP_BASE_URL}developer-profile/get-developer-profile-id/${params.id}`;

  useEffect(() => {
    fetch(assetUrlManagementEndpoint, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    })
      .then((response) => response.json())
      .then((data) => {
        const responseData = common.decrypt(data.data);
        const assetsIds = responseData?.map((item) => ({
          label: item.website_url,
          value: item.id,
        }));
        // setAallAssetsId(assetsIds);
        // setUnmatchedAssets(assetsIds);
        fetch(newAssetUrlManagementEndpoint, {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        })
          .then((response) => response.json())
          .then((newData) => {
            const responseData = common.decrypt(newData.data);
            newData.data = responseData;
            const newAssetIds = JSON.parse(newData.data.asset_url_management);
            const matchedOptions = assetsIds.filter((option) =>
              newAssetIds?.includes(option.value)
            );

            setSelectedOptionsForAssets(matchedOptions);

            // const selectedAssets = matchedOptions.map((option) => option.value);
            // const newUnmatchedOptions = assetsIds.filter(
            //   (option) => !selectedAssets?.includes(option.value)
            // );

            // setUnmatchedAssets(newUnmatchedOptions);
          });
      });
  }, [assetUrlManagementEndpoint, newAssetUrlManagementEndpoint, token]);

  //images

  const validation = useFormik({
    enableReinitialize: true,

    initialValues: {
      developers_profile_image: data ? data.developers_profile_image : "",
      developers_cover_image: data ? data.developers_cover_image : "",
      organization: data ? data.organization : "",
      user_name: data ? data.user_name : "",
      contact_no: data ? data.contact_no : "",
      email: data ? data.email : "",
      organization_url: data ? data.organization_url : "",
      designation: data ? data.designation : "",
      skills: selectedOptions
        ? selectedOptions.length > 0
          ? selectedOptions.map((item) => item.value)
          : ""
        : "",
      location: data ? data.location : "",
      projects: data ? data.projects : "",
      tasks: data ? data.tasks : "",
      description: data ? data.description : "",
      asset_url_management: selectedOptionsForAssets
        ? selectedOptionsForAssets.length > 0
          ? selectedOptionsForAssets.map((item) => item.value)
          : // : data.asset_url_management
            ""
        : "",
    },

    onSubmit: (values) => {
      updateUsers(values);
    },
  });

  const handleShow = () => setShow(true);
  const handleBg = () => setBgImage(true);

  const updateUsers = async (values) => {
    let data = new FormData();
    if (
      values.developers_profile_image &&
      values.developers_profile_image.constructor === File
    ) {
      data.append("developers_profile_image", values.developers_profile_image);
    }
    if (
      values.developers_cover_image &&
      values.developers_cover_image.constructor === File
    ) {
      data.append("developers_cover_image", values.developers_cover_image);
    }
    data.append("organization", values.organization);
    data.append("user_name", values.user_name);
    data.append("contact_no", values.contact_no || "");
    data.append("email", values.email);
    data.append("organization_url", values.organization_url);
    data.append("designation", values.designation || "");
    data.append("skills", values.skills);
    data.append("location", values.location || "");
    data.append("projects", values.projects);
    data.append("tasks", values.tasks);
    data.append("description", values.description);
    data.append("asset_url_management", values.asset_url_management);

    // const formDataObject = {
    //   organization: values.organization,
    //   user_name: values.user_name,
    //   contact_no: values.contact_no || "",
    //   email: values.email,
    //   organization_url: values.organization_url,
    //   designation: values.designation || "",
    //   skills: values.skills,
    //   location: values.location || "",
    //   projects: values.projects,
    //   tasks: values.tasks,
    //   description: values.description,
    //   asset_url_management: values.asset_url_management,
    // };

    // const encryptedData = common.encrypt(formDataObject);
    // data.append("encryptedData", encryptedData.data);

    let url = `${process.env.REACT_APP_BASE_URL}developer-profile/update-developer-profile/${params.id}`;
    let reqData = {
      method: "POST",
      headers: {
        Authorization: `Bearer ${token}`,
      },
      body: data,
    };
    let result = await fetch(url, reqData);
    let response = await result.json();
    if (response.status === "success") {
      ToastMessage.Success("Details has been successfully updated.");
      setTimeout(() => {
        navigate(`/developer-profile/` + params.id);
      }, 1000);
      socket.emit("updateDeveloperProfile");
      getUserData();
    }
    if (response.status === "fail") {
      getUserData();
      ToastMessage.Error("An error occurred. Please try again later.");
    }
  };
  const handleChangeImage = (event) => {
    const file = event.target.files[0];

    if (file) {
      const allowedFormats = [
        "image/jpeg",
        "image/jpg",
        "image/png",
        "image/svg+xml",
      ];
      const validSize = file.size <= 1024 * 1024;

      if (!allowedFormats.includes(file.type)) {
        validation.setFieldError(
          "developers_profile_image",
          "Invalid file format. Only JPEG, JPG, PNG, and SVG files are allowed."
        );
        event.target.value = null;
      } else if (!validSize) {
        validation.setFieldError(
          "developers_profile_image",
          "Failed to upload an image. The image maximum size is 1MB."
        );
        event.target.value = null;
      } else {
        validation.setFieldError("developers_profile_image", "");
        validation.setFieldValue("developers_profile_image", file);
        const imageUrl = URL.createObjectURL(file);
        setSelectedCoverImage(imageUrl);
      }
    }
  };

  const handleChangeCoverImage = (event) => {
    const file = event.target.files[0];

    if (file) {
      const allowedFormats = [
        "image/jpeg",
        "image/jpg",
        "image/png",
        "image/svg+xml",
      ];
      const validSize = file.size <= 1024 * 1024;

      if (!allowedFormats.includes(file.type)) {
        validation.setFieldError(
          "developers_cover_image",
          "Invalid file format. Only JPEG, JPG, PNG, and SVG files are allowed."
        );
        event.target.value = null;
      } else if (!validSize) {
        validation.setFieldError(
          "developers_cover_image",
          "Failed to upload an image. The image maximum size is 1MB."
        );
        event.target.value = null;
      } else {
        validation.setFieldError("developers_cover_image", "");
        validation.setFieldValue("developers_cover_image", file);
        const imageUrl = URL.createObjectURL(file);
        setSelectedBgImage(imageUrl);
      }
    }
  };

  const handleSaveChanges = () => {
    validation.handleSubmit();
    handleClose();
  };

  const handleClose = () => {
    validation.setFieldValue("departmentImage", null);
    setShow(false);
    setSelectedCoverImage(false);
    setBgImage(false);
    setSelectedBgImage(false);
  };

  // const extractTextFromHTML = (htmlData) => {
  //   const parser = new DOMParser();
  //   const doc = parser.parseFromString(htmlData, "text/html");
  //   const textContent = doc.body.textContent;
  //   return textContent;
  // };

  // const extractTextAndImagesFromHTML = (htmlData) => {
  //   const parser = new DOMParser();
  //   const doc = parser.parseFromString(htmlData, "text/html");

  //   // Remove all elements except <img>
  //   const elements = doc.body.querySelectorAll("*:not(img)");
  //   elements.forEach((el) => el.remove());

  //   // Return the remaining HTML, which includes text and images
  //   return doc.body.innerHTML;
  // };

  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const initialTab = searchParams.get("tab") || "1"; // Default to "1" if no tab parameter is found

  const [profilevalue, setProfilevalue] = useState(initialTab);
  const handleChangeProfile = (event, newValue) => {
    setProfilevalue(newValue);
  };

  const handleCancelEditProfile = () => {
    setProfilevalue("1");
  };

  const skillsString = data?.skills ? data?.skills?.replace(/"/g, "") : "";
  const skillsArray = skillsString ? skillsString?.split(",") : [];
  const skillsCount = skillsArray?.length;

  return (
    <Box className="developerprofile-details">
      <ToastContainer closeButton={true} />

      <Card className="custom-card">
        <CardBody>
          <div className="panel profile-cover">
            <div className="profile-cover__img">
              <Badge
                overlap="circular"
                anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
                badgeContent={
                  hasPermission && hasPermission.canModify ? (
                    <span className="cursor-pointer" onClick={handleShow}>
                      <EditIcon />
                    </span>
                  ) : null
                }
                sx={{
                  "& .MuiBadge-badge": {
                    width: "24px",
                    height: "24px",
                    background: "#9ea3ab",
                    outline: "2px solid #fff",
                    padding: "4px",
                    borderRadius: "50%",
                    "svg path": {
                      fill: "#fff",
                    },
                    svg: {
                      width: "12px",
                    },
                  },
                }}
              >
                {data.developers_profile_image != null ? (
                  <Avatar
                    src={`${process.env.REACT_APP_BASE_URL}${data.developers_profile_image}`}
                    alt="profile"
                    sx={{ width: 74, height: 74, background: "#1A2232" }}
                    className="avatar-img"
                  />
                ) : (
                  <ProfileImage
                    username={data.user_name || "Demo User"}
                    height="6rem"
                    width="6rem"
                    fontSize="30px"
                  />
                )}
              </Badge>

              {dataLoading ? (
                <Skeleton variant="text" width={150} height={30} />
              ) : (
                <>
                  <h3 className="h3">{data.user_name}</h3>
                </>
              )}
            </div>
            <div className="profile-cover_action">
              {/* <img src={coverImg} alt="Cover img" /> */}
              {data.developers_cover_image != null ? (
                <img
                  src={`${process.env.REACT_APP_BASE_URL}${data.developers_cover_image}`}
                  alt="profile"
                  sx={{ width: 100, height: 100 }}
                  // className="avatar-img"
                />
              ) : (
                <img src={coverImg} alt="Cover img" />
              )}
              {hasPermission && hasPermission?.canModify ? (
                <IconButton className="edit-icon" onClick={handleBg}>
                  <EditIcon />
                </IconButton>
              ) : (
                ""
              )}
            </div>
            <div className="profile-cover__info">
              <ul className="nav">
                {dataLoading ? (
                  <Skeleton variant="text" width={300} height={50} />
                ) : (
                  <>
                    <li>
                      <strong>{data.projects || "-"}</strong>Projects
                    </li>
                    <li>
                      <strong>{skillsCount || "-"}</strong>Skills
                    </li>
                    <li>
                      <strong>{data.tasks || "-"}</strong>Tasks
                    </li>
                  </>
                )}
              </ul>
            </div>
          </div>
        </CardBody>
      </Card>
      <Card style={{ minHeight: "400px" }}>
        <TabContext value={profilevalue}>
          <Box>
            <TabList
              onChange={handleChangeProfile}
              variant="scrollable"
              // scrollButtons
              allowScrollButtonsMobile
              sx={PerformanceTabStyle}
            >
              <Tab label="About" value="1" />
              {hasPermission && hasPermission.canModify && (
                <Tab label="Edit Profile" value="2" />
              )}
              <Tab label="Websites" value="3" />
            </TabList>
          </Box>
          <TabPanel value="1" className="performance-tab-panel">
            <Box>
              <h5 className="headeing">Info</h5>
              {dataLoading ? (
                <Skeleton variant="text" width={200} height={30} />
              ) : (
                <>
                  {/* <p>
                    {data[0]?.description !== "" &&
                      extractTextFromHTML(data.description)}
                  </p> */}
                  <p>
                    {data?.description && (
                      <span
                        dangerouslySetInnerHTML={{ __html: data.description }}
                      />
                    )}
                  </p>
                </>
              )}
              <List>
                <ListItem>
                  <ListItemIcon>
                    <CallIcon />
                  </ListItemIcon>
                  {dataLoading ? (
                    <Skeleton variant="text" width={100} height={30} />
                  ) : (
                    <>
                      <a
                        href={`tel:${data.contact_no}`}
                        style={{ marginRight: "8px" }}
                      >
                        <ListItemText
                          primary={data.contact_no ? data.contact_no : "-"}
                        />
                      </a>
                    </>
                  )}
                </ListItem>
                <ListItem>
                  <ListItemIcon>
                    <EmailIcon />
                  </ListItemIcon>
                  {dataLoading ? (
                    <Skeleton variant="text" width={100} height={30} />
                  ) : (
                    <>
                      <a
                        href={`mailto:${data.email}`}
                        style={{ marginRight: "8px" }}
                      >
                        <ListItemText primary={data.email ? data.email : "-"} />
                      </a>
                    </>
                  )}
                </ListItem>
                <ListItem>
                  <ListItemIcon>
                    <LocationOnIcon />
                  </ListItemIcon>
                  {dataLoading ? (
                    <Skeleton variant="text" width={100} height={30} />
                  ) : (
                    <>
                      <ListItemText
                        primary={data.location ? data.location : "-"}
                      />
                    </>
                  )}
                </ListItem>
              </List>
            </Box>
            <Box>
              <h5 className="headeing">Details</h5>
              <Row>
                <Box className="col-sm-12 col-md-12 col-lg-8 col-xl-6 col-xxl-5 col-xxxl-4">
                  {/* {renderAbout} */}
                  <ProfileDetail data={data} dataLoading={dataLoading} />
                </Box>
              </Row>
            </Box>
            <Box className="skill-box">
              <h5 className="headeing">Skill</h5>
              {selectedOptions?.length > 0 ? (
                <Stack direction="row" flexWrap="wrap" spacing={1}>
                  {selectedOptions.map((skill, index) => (
                    <React.Fragment key={skill.id || index}>
                      {dataLoading ? (
                        <Skeleton
                          key={index}
                          variant="text"
                          width={100}
                          height={30}
                        />
                      ) : (
                        <>
                          <Chip key={index} label={skill.label} />
                        </>
                      )}
                    </React.Fragment>
                  ))}
                </Stack>
              ) : (
                <p>No skills available</p>
              )}
            </Box>
          </TabPanel>
          <TabPanel value="2" className="performance-tab-panel">
            <EditDeveloperProfile
              setUpdatedValue={setUpdatedValue}
              updatedValue={updatedValue}
              hasPermission={hasPermission}
              onCancel={handleCancelEditProfile}
            />
          </TabPanel>
          <TabPanel value="3" className="performance-tab-panel">
            <Box className="row g-3">
              <Projects id={params.id} />
            </Box>
          </TabPanel>
        </TabContext>
      </Card>
      <Modal show={show} onHide={handleClose}>
        <Modal.Body className="p-3 profile-setting-img">
          <Box className="overlay-content mb-2">
            <Box className="profile-photo-edit">
              <input
                id="profile-cover-img-file-input"
                type="file"
                className="profile-foreground-img-file-input"
                name="developers_profile_image"
                accept="image/*"
                onChange={(e) => {
                  handleChangeImage(e);
                  validation.setFieldTouched(
                    "developers_profile_image",
                    true,
                    false
                  );
                }}
                onBlur={validation.handleBlur}
              />
              <label
                htmlFor="profile-cover-img-file-input"
                className="profile-photo-edit d-flex gap-2 mb-0"
              >
                <CloudUploadIcon />
                Upload
              </label>
            </Box>
            {validation.errors.developers_profile_image &&
            validation.touched.developers_profile_image ? (
              <span className="error-text">
                {validation.errors.developers_profile_image}
              </span>
            ) : null}
          </Box>
          {selectedCoverImage || data.developers_profile_image ? (
            <Box className="profile-wid-img">
              <img
                name="developers_profile_image"
                src={
                  selectedCoverImage ||
                  `${process.env.REACT_APP_BASE_URL}${data.developers_profile_image}`
                }
                alt="user-profile"
              />
            </Box>
          ) : (
            <Box className="profile-wid-img">
              <ProfileImage
                username={data.user_name || "Demo User"}
                height="6rem"
                width="6rem"
                fontSize="30px"
              />
            </Box>
          )}
        </Modal.Body>
        <Modal.Footer>
          <button className="btn btn-primary" onClick={handleClose}>
            Close
          </button>
          <button
            className="btn btn-dark"
            type="submit"
            onClick={handleSaveChanges}
          >
            Save Changes
          </button>
        </Modal.Footer>
      </Modal>
      <Modal show={BgImage} onHide={handleClose}>
        <Modal.Body className="p-3 profile-setting-img">
          <Box className="overlay-content mb-2">
            <Box className="profile-photo-edit">
              <input
                id="profile-cover-img-file-input"
                type="file"
                className="profile-foreground-img-file-input"
                name="developers_cover_image"
                accept="image/*"
                onChange={(e) => {
                  handleChangeCoverImage(e);
                  validation.setFieldTouched(
                    "developers_cover_image",
                    true,
                    false
                  );
                }}
                onBlur={validation.handleBlur}
              />
              <label
                htmlFor="profile-cover-img-file-input"
                className="profile-photo-edit d-flex gap-2 mb-0"
              >
                <CloudUploadIcon />
                Upload
              </label>
            </Box>
            {validation.errors.developers_cover_image &&
            validation.touched.developers_cover_image ? (
              <span className="error-text">
                {validation.errors.developers_cover_image}
              </span>
            ) : null}
          </Box>
          {selectedBgImage || data.developers_cover_image ? (
            <Box className="profile-wid-img">
              <img
                name="developers_cover_image"
                src={
                  selectedBgImage ||
                  `${process.env.REACT_APP_BASE_URL}${data.developers_cover_image}`
                }
                alt="user-profile"
              />
            </Box>
          ) : (
            <Box className="profile-wid-img">
              <img src={coverImg} alt="Cover img" />
            </Box>
          )}
        </Modal.Body>
        <Modal.Footer>
          <button className="btn btn-primary" onClick={handleClose}>
            Close
          </button>
          <button
            className="btn btn-dark"
            type="submit"
            onClick={handleSaveChanges}
          >
            Save Changes
          </button>
        </Modal.Footer>
      </Modal>
    </Box>

    // <CommonProfile
    //   data={data}
    //   extractTextFromHTML={extractTextFromHTML}
    //   id={params.id}
    //   validation={validation}
    //   handleShow={handleShow}
    //   handleBg={handleBg}
    //   skillsCount={skillsCount}
    // />
  );
};

export default DeveloperProfileDetails;
