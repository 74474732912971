import {
  Button,
  Form,
  Col,
  FormFeedback,
  Input,
  Row,
  Spinner,
} from "reactstrap";
import { Modal, ModalBody, ModalHeader, ModalTitle } from "react-bootstrap";
import {
  Autocomplete,
  Checkbox,
  FormControl,
  InputLabel,
  ListItemText,
  MenuItem,
  Select,
  TextField,
  Tooltip,
} from "@mui/material";
import { MenuHeight } from "../../NewDashboard/constant";
import common from "../../../Components/Common/common";

const AddDeveloperModal = (props) => {
  const {
    show,
    handleClose,
    validation,
    postIsLoading,
    skillsIds,
    selectedOptions,
    assetSelectedOptions,
    handleAssetsChange,
    allAssetIds,
    handleSelectChange,
  } = props;

  return (
    <>
      <Modal
        show={show}
        onHide={handleClose}
        centered
        scrollable
        size="lg"
        contentClassName="border-0"
      >
        <ModalHeader closeButton>
          <ModalTitle> Add New Developer</ModalTitle>
        </ModalHeader>
        <ModalBody>
          <Form
            onSubmit={validation.handleSubmit}
            className="needs-validation"
            noValidate
            action="index"
          >
            <Row className="g-4">
              <Col lg={12}>
                <TextField
                  fullWidth
                  label="Name *"
                  id="outlined-size-small"
                  name="name"
                  placeholder="Enter Developer name."
                  value={validation.values.name}
                  onChange={validation.handleChange}
                  onBlur={validation.handleBlur}
                />
                {validation.errors.name && validation.touched.name ? (
                  <span className="error-text">{validation.errors.name}</span>
                ) : null}
              </Col>
              <Col lg={6}>
                <TextField
                  fullWidth
                  label="Contact No."
                  id="outlined-size-small"
                  name="contact_no"
                  placeholder="Enter Developer contact_no."
                  value={validation.values.contact_no}
                  onChange={validation.handleChange}
                  onBlur={validation.handleBlur}
                />
                {validation.errors.contact_no &&
                validation.touched.contact_no ? (
                  <span className="error-text">
                    {validation.errors.contact_no}
                  </span>
                ) : null}
              </Col>
              <Col lg={6}>
                <TextField
                  label="Email *"
                  fullWidth
                  name="email"
                  placeholder="Enter Developer email."
                  value={validation.values.email}
                  onChange={validation.handleChange}
                  onBlur={validation.handleBlur}
                />
                {validation.errors.email && validation.touched.email ? (
                  <span className="error-text">{validation.errors.email}</span>
                ) : null}
              </Col>
              <Col lg={6}>
                <TextField
                  fullWidth
                  label="Organization *"
                  id="outlined-size-small"
                  name="organization"
                  placeholder="Enter Developer organization."
                  value={validation.values.organization}
                  onChange={validation.handleChange}
                  onBlur={validation.handleBlur}
                />
                {validation.errors.organization &&
                validation.touched.organization ? (
                  <span className="error-text">
                    {validation.errors.organization}
                  </span>
                ) : null}
              </Col>
              <Col lg={6}>
                <TextField
                  label="Organizaion URL *"
                  fullWidth
                  id="outlined-size-small"
                  name="organization_url"
                  placeholder="Enter Developer organization_url."
                  value={validation.values.organization_url}
                  onChange={validation.handleChange}
                  onBlur={validation.handleBlur}
                />
                {validation.errors.organization_url &&
                validation.touched.organization_url ? (
                  <span className="error-text">
                    {validation.errors.organization_url}
                  </span>
                ) : null}
              </Col>
              <Col lg={6}>
                <TextField
                  fullWidth
                  label="Designation"
                  id="outlined-size-small"
                  name="designation"
                  placeholder="Enter Developer designation."
                  value={validation.values.designation}
                  onChange={validation.handleChange}
                  onBlur={validation.handleBlur}
                />
                {validation.errors.designation &&
                validation.touched.designation ? (
                  <span className="error-text">
                    {validation.errors.designation}
                  </span>
                ) : null}
              </Col>
              <Col lg={6}>
                <TextField
                  label="Location"
                  fullWidth
                  id="outlined-size-small"
                  name="location"
                  placeholder="Enter Developer location."
                  value={validation.values.location}
                  onChange={validation.handleChange}
                  onBlur={validation.handleBlur}
                />
                {validation.errors.location && validation.touched.location ? (
                  <span className="error-text">
                    {validation.errors.location}
                  </span>
                ) : null}
              </Col>
              {/* <Col lg={6}>
                <FormControl fullWidth>
                  <InputLabel>Asset URL Management *</InputLabel>
                  <Select
                    label="Asset URL Management *"
                    id="asset-url-management"
                    multiple
                    MenuProps={MenuHeight}
                    value={assetSelectedOptions}
                    onChange={(event) => handleAssetsChange(event.target.value)}
                    onBlur={validation.handleBlur}
                    renderValue={(selected) =>
                      selected?.map((item) => item.label).join(", ")
                    } // Display only labels
                  >
                    {Array.isArray(allAssetIds) &&
                      allAssetIds?.map((asset) => (
                        <MenuItem key={asset.value} value={asset}>
                          {asset.label}
                        </MenuItem>
                      ))}
                  </Select>
                </FormControl>
                {validation.errors.asset_url_management &&
                validation.touched.asset_url_management ? (
                  <span className="error-text">
                    {validation.errors.asset_url_management}
                  </span>
                ) : null}
              </Col> */}
              <Col lg={6}>
                <FormControl fullWidth>
                  <Autocomplete
                    multiple
                    id="asset-url-management"
                    options={allAssetIds}
                    getOptionLabel={(option) => option.label}
                    value={assetSelectedOptions}
                    onChange={(event, newValue) => handleAssetsChange(newValue)}
                    onBlur={validation.handleBlur}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="Asset URL Management *"
                        placeholder="Select assets"
                      />
                    )}
                  />
                </FormControl>
                {validation.errors.asset_url_management &&
                validation.touched.asset_url_management ? (
                  <span className="error-text">
                    {validation.errors.asset_url_management}
                  </span>
                ) : null}
              </Col>

              {/* <Col lg={6}>
                <FormControl fullWidth>
                  <Autocomplete
                    multiple
                    options={allAssetIds} // The list of all assets
                    getOptionLabel={(option) => option.label} // How to display the label
                    value={assetSelectedOptions} // The selected values
                    onChange={(event, newValue) => {
                      handleAssetsChange(newValue); // Update the selected assets
                      validation.setFieldValue(
                        "asset_url_management",
                        newValue
                      ); // Set the field value for validation
                    }}
                    onBlur={validation.handleBlur}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="Asset URL Management *"
                        // error={Boolean(
                        //   validation.errors.asset_url_management &&
                        //     validation.touched.asset_url_management
                        // )}
                        // helperText={
                        //   validation.errors.asset_url_management &&
                        //   validation.touched.asset_url_management
                        //     ? validation.errors.asset_url_management
                        //     : ""
                        // }
                      />
                    )}
                    isOptionEqualToValue={(option, value) =>
                      option.value === value.value
                    } // For custom equality check
                  />
                </FormControl>
                {validation.errors.asset_url_management &&
                validation.touched.asset_url_management ? (
                  <span className="error-text">
                    {validation.errors.asset_url_management}
                  </span>
                ) : null}
              </Col> */}
              <Col lg={6}>
                <FormControl fullWidth>
                  <InputLabel id="skills-label">Skills *</InputLabel>
                  <Select
                    labelId="skills-label"
                    multiple
                    MenuProps={MenuHeight}
                    value={selectedOptions}
                    onChange={handleSelectChange}
                    onBlur={validation.handleBlur}
                    renderValue={(selected) => selected.join(", ")}
                    label="Skills *"
                  >
                    {Array.isArray(skillsIds) &&
                      skillsIds.map((option) => (
                        <MenuItem
                          key={option.value}
                          value={option.label}
                          sx={{ whiteSpace: "break-spaces" }}
                        >
                          <ListItemText primary={option.label} />
                        </MenuItem>
                      ))}
                  </Select>
                </FormControl>
                {validation.errors.skills && validation.touched.skills ? (
                  <span className="error-text">{validation.errors.skills}</span>
                ) : null}
              </Col>
              <Col lg={6}>
                <TextField
                  label="Projects"
                  fullWidth
                  id="outlined-size-small"
                  name="projects"
                  placeholder="Enter Developer projects."
                  value={validation.values.projects}
                  onChange={validation.handleChange}
                  onBlur={validation.handleBlur}
                />
                {validation.errors.projects && validation.touched.projects ? (
                  <span className="error-text">
                    {validation.errors.projects}
                  </span>
                ) : null}
              </Col>
              <Col lg={6}>
                <TextField
                  label="Tasks"
                  fullWidth
                  id="outlined-size-small"
                  name="tasks"
                  placeholder="Enter Developer tasks."
                  value={validation.values.tasks}
                  onChange={validation.handleChange}
                  onBlur={validation.handleBlur}
                />
                {validation.errors.tasks && validation.touched.tasks ? (
                  <span className="error-text">{validation.errors.tasks}</span>
                ) : null}
              </Col>
              <Col lg={12}>
                <TextField
                  label="Short Description"
                  id="outlined-size-small"
                  name="description"
                  placeholder="Enter Short Description"
                  variant="outlined"
                  multiline
                  fullWidth
                  rows={2}
                  value={validation.values.description}
                  onBlur={validation.handleBlur}
                  onChange={validation.handleChange}
                />
                {validation.errors.description &&
                  validation.touched.description && (
                    <span className="error-text">
                      {validation.errors.description}
                    </span>
                  )}
              </Col>
              <Col lg={12}>
                <label className="d-flex align-items-center">
                  Developer Image{" "}
                  <Tooltip
                    title={common.LOGOTOOLTIP}
                    placement="top-start"
                    arrow
                  >
                    <i
                      className="ri-information-line fs-18 cursor-pointer ms-2"
                      style={{
                        color: "#ced4da",
                      }}
                    ></i>
                  </Tooltip>{" "}
                </label>
                <Input
                  className="form-control"
                  type="file"
                  id="imageFile"
                  name="imageFile"
                  accept="image/*"
                  onChange={(event) => {
                    validation.setFieldValue(
                      "imageFile",
                      event.currentTarget.files[0]
                    );
                  }}
                />
                {validation.errors.imageFile &&
                  validation.touched.imageFile && (
                    <FormFeedback type="invalid" style={{ color: "red" }}>
                      {validation.errors.imageFile}
                    </FormFeedback>
                  )}
              </Col>
            </Row>
          </Form>
        </ModalBody>
        <Modal.Footer>
          <Form
            onSubmit={validation.handleSubmit}
            className="needs-validation"
            noValidate
            action="index"
          >
            <Button className="btn btn-primary me-2" onClick={handleClose}>
              Cancel
            </Button>
            <Button
              className="btn btn-dark"
              type="submit"
              disabled={postIsLoading}
            >
              {postIsLoading ? <Spinner size="sm" variant="light" /> : "Submit"}
            </Button>
          </Form>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default AddDeveloperModal;
