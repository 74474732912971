import React, { useState } from "react";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import { Dropdown, DropdownMenu, DropdownToggle } from "reactstrap";

//import imagesver
import NetLogoM from "../assets/images/netclues.gif";
import CloseIcon from "@mui/icons-material/Close";

import axios from "axios";

//import Components
import NotificationDropdown from "../Components/Common/NotificationDropdown";
import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import {
  addGeneralSettingData,
  addSettingsData,
} from "../store/settings/action";
import {
  addUserData,
  addUserPermissionData,
  fetchDashboardSettingsRequest,
  updateDashboardSettingsRequest,
} from "../store/actions";
import { addpowerPanelSitesData } from "../store/onlineAssets/action";
import SettingsIcon from "@mui/icons-material/Settings";
import {
  Avatar,
  Box,
  Button,
  Checkbox,
  Divider,
  Drawer,
  FormControlLabel,
  ListItemIcon,
  Menu,
  MenuItem,
  Typography,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import InputBase from "@mui/material/InputBase";
import { StyledBadge } from "../Components/Common/constants";
import LoginRoundedIcon from "@mui/icons-material/LoginRounded";
import PermIdentityOutlinedIcon from "@mui/icons-material/PermIdentityOutlined";

import AssignmentOutlinedIcon from "@mui/icons-material/AssignmentOutlined";

import EmailOutlinedIcon from "@mui/icons-material/EmailOutlined";
import EditNotificationsIcon from "@mui/icons-material/EditNotifications";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import BlockOutlinedIcon from "@mui/icons-material/BlockOutlined";
import DashboardCustomizeIcon from "@mui/icons-material/DashboardCustomize";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import ProfileImage from "../Components/Common/ProfileImage";
import {
  AUDIT_LOG,
  BLOCKED_IP,
  NOTIFICATION_SETTINGS,
  ONLINE_ASSETS,
  TICKETING_SYSTEM,
} from "../Components/Common/ModuleName";
import common from "../Components/Common/common";
import { getUserData } from "../helpers/Help/CommomApi";
import { ToastMessage } from "../Components/Common/ToastMessage";
import {
  Dashboard as DashboardIcon,
  TrendingUp as TrendingUpIcon,
  LocalOffer as LocalOfferIcon,
  Info as InfoIcon,
  Security as SecurityIcon,
  Visibility as VisibilityIcon,
  ListAlt as ListAltIcon,
} from "@mui/icons-material";
import { resetInitList } from "../store/notification/action";
import PageTitle from "../Components/Common/PageTitle";
const Search = styled("div")(({ theme }) => ({
  position: "relative",
  borderRadius: "8px",
  marginRight: theme.spacing(2),
  marginLeft: 0,
  width: "100%",
}));

const SearchIconWrapper = styled("div")(({ theme }) => ({
  padding: theme.spacing(0, 2),
  height: "100%",
  position: "absolute",
  pointerEvents: "none",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
}));

const StyledInputBase = styled(InputBase)(({ theme }) => ({
  color: "inherit",
  backgroundColor: "#fff",
  borderRadius: "8px",
  "& .MuiInputBase-input": {
    padding: theme.spacing(1, 1, 1, 0),
    // vertical padding + font size from searchIcon
    paddingLeft: `calc(1em + ${theme.spacing(4)})`,
    transition: theme.transitions.create("width"),
    width: "100%",
    "@media (min-width:1700px)": {
      width: "360px",
      paddingRight: "50px",
    },
    // ["@media (min-width:1700px)"]: {
    //   width: "470px",
    // },
  },
}));

const Header = ({ onChangeLayoutMode, layoutModeType, headerClass }) => {
  const dispatch = useDispatch();

  // const activeTabName = useSelector((state) => state.settingTab.activeTabName);
  const location = useLocation();
  const params = useParams();
  const id = params.id;
  const navigate = useNavigate();

  const [search, setSearch] = useState(false);
  const [searchQuery, setSearchQuery] = useState("");
  const [searchResult, setSearchResult] = useState([]);
  const [show, setShow] = useState(false);
  const settings = useSelector((state) => state.dashboard.settings);

  const permissionData = useSelector(
    (state) => state.userDetails.userPermission
  );
  const checkPermission = permissionData?.find(
    (item) => item.module_name === BLOCKED_IP
  );

  const checkOnlineAsset = permissionData?.find(
    (item) => item.module_name === ONLINE_ASSETS
  );

  const checkTicketSystem = permissionData?.find(
    (item) => item.module_name === TICKETING_SYSTEM
  );

  const checkAuditLog = permissionData?.find(
    (item) => item.module_name === AUDIT_LOG
  );

  const checkNotificationSettings = permissionData?.find(
    (item) => item.module_name === NOTIFICATION_SETTINGS
  );

  const userData = useSelector((state) => state.userDetails.userData);
  const userId = userData?.id;
  const toogleSearch = () => {
    setSearch(!search);
  };

  let UrlRouteName = location.pathname
    .replace("/", "")
    .replace("-", " ")
    .replace("-", " ");

  var splitStr = UrlRouteName.toLowerCase().split(" ");

  for (var i = 0; i < splitStr.length; i++) {
    // You do not need to check if i is larger than splitStr length, as your for does that for you
    // Assign it back to the array
    splitStr[i] =
      splitStr[i].charAt(0).toUpperCase() + splitStr[i].substring(1);
  }

  const toogleMenuBtn = () => {
    var windowSize = document.documentElement.clientWidth;

    if (windowSize > 991)
      document.querySelector(".hamburger-icon").classList.toggle("open");

    //For collapse horizontal menu
    if (document.documentElement.getAttribute("data-layout") === "horizontal") {
      document.body.classList.contains("menu")
        ? document.body.classList.remove("menu")
        : document.body.classList.add("menu");
    }

    //For collapse vertical menu
    if (document.documentElement.getAttribute("data-layout") === "vertical") {
      if (windowSize < 1025 && windowSize > 991) {
        document.body.classList.remove("vertical-sidebar-enable");
        document.documentElement.getAttribute("data-sidebar-size") === "sm"
          ? document.documentElement.setAttribute("data-sidebar-size", "")
          : document.documentElement.setAttribute("data-sidebar-size", "sm");
      } else if (windowSize > 1025) {
        document.body.classList.remove("vertical-sidebar-enable");
        document.documentElement.getAttribute("data-sidebar-size") === "lg"
          ? document.documentElement.setAttribute("data-sidebar-size", "sm")
          : document.documentElement.setAttribute("data-sidebar-size", "lg");
      } else if (windowSize <= 991) {
        document.body.classList.add("vertical-sidebar-enable");
        document.documentElement.setAttribute("data-sidebar-size", "lg");
      }
    }

    //Two column menu
    if (document.documentElement.getAttribute("data-layout") === "twocolumn") {
      document.body.classList.contains("twocolumn-panel")
        ? document.body.classList.remove("twocolumn-panel")
        : document.body.classList.add("twocolumn-panel");
    }
  };

  // dashboard setting start

  useEffect(() => {
    if (userId) {
      dispatch(fetchDashboardSettingsRequest(userId));
    }
  }, [userId, dispatch]);

  const handleClickOpen = () => {
    setShow(true);
  };

  const handleCloseq = () => {
    setShow(false);
  };

  const handleChange = (event) => {
    const { name, checked } = event.target;
    const updatedSettings = { ...settings, [name]: checked };

    dispatch(updateDashboardSettingsRequest(userId, updatedSettings));
    ToastMessage.Success("Dashboard settings updated successfully!");
  };
  // dashboard setting end

  const getSettingData = async () => {
    try {
      let { data } = await axios.get(`settings/get-settings`);
      const decryptedData = common.decrypt(data);
      data = decryptedData;
      const settingData = data?.rows;
      const filterData = settingData.filter(
        (item) => item.settings_category === "general"
      );

      dispatch(addSettingsData(settingData));
      dispatch(addGeneralSettingData(filterData));
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    getSettingData();
    fetchUserData();
    GetPermissions();
    getPowerPanelSitesList();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const fetchUserData = async () => {
    try {
      let response = await getUserData();
      const responseData = common.decrypt(response.data);
      dispatch(addUserData(responseData));
    } catch (error) {
      console.error(error);
    }
  };

  const GetPermissions = async () => {
    try {
      const { data } = await axios.get(`users-website/get-all-permission`);
      const decryptdata = common.decrypt(data);
      dispatch(addUserPermissionData(decryptdata));
    } catch (error) {
      console.error(error);
    }
  };

  const getPowerPanelSitesList = async () => {
    try {
      let { data } = await axios.get(`online-assets/get-powerpanel-sites`);
      const decryptedData = common.decrypt(data);
      dispatch(addpowerPanelSitesData(decryptedData));
    } catch (error) {
      console.error(error);
    }
  };

  const [profilemenu, setProfilemenu] = useState(null);
  const open = Boolean(profilemenu);
  const handleClickMenu = (event) => {
    setProfilemenu(event.currentTarget);
  };

  const handleClose = () => {
    setProfilemenu(null);
  };

  const handleBlockIp = () => {
    navigate("/blocked-ip");
  };

  const handleAuditLogs = () => {
    navigate("/audit-logs");
  };

  const handleEmailLogs = () => {
    navigate("/email-logs");
  };

  const handleNotificationSetting = () => {
    navigate("/notification-setting");
  };

  const handleProfile = () => {
    let id = localStorage.getItem("user_id");
    navigate("/pages-profile/" + id);
    handleClose();
  };

  const navigateToSecurity = () => {
    navigate("/settings", { state: "Security Settings" });
  };

  const handleSearchChange = (e) => {
    setSearchQuery(e.target.value);
  };

  useEffect(() => {
    const getAllSearchData = async () => {
      try {
        let { data } = await axios.get(
          `online-assets/all-search-filter?search=${searchQuery}`
        );
        setSearchResult(data);
      } catch (error) {
        console.error(error);
      }
    };
    getAllSearchData();
  }, [searchQuery]);

  const logOut = () => {
    dispatch(resetInitList());
    localStorage.clear();
    navigate("/auth-logout-cover");
  };

  const logIn = () => {
    navigate("/auth-signin-cover");
  };

  const handleNavigateSearch = (path, value) => {
    navigate(path, { state: { globalSearch: value } });
    setSearchQuery("");
    setSearchResult([]);
    setSearch(false);
  };

  return (
    <React.Fragment>
      <header id="page-topbar" className={headerClass}>
        <Box className="layout-width">
          <Box className="navbar-header">
            <Box className="d-flex align-items-center">
              <button
                onClick={() => {
                  toogleMenuBtn();
                }}
                type="button"
                className="btn btn-sm px-3 fs-16 header-item vertical-menu-btn topnav-hamburger"
                id="topnav-hamburger-icon"
              >
                <span className="hamburger-icon">
                  <span></span>
                  <span></span>
                  <span></span>
                </span>
              </button>

              <div className="d-none d-xl-inline-block"><PageTitle/></div>

              {/* <h5 className="title_breadcum fw-semibold me-4 mb-0">
                {(location.pathname === "/settings" && <span>Settings</span>) ||
                  (location.pathname === "/" && <span>Dashboard</span>) ||
                  (location.pathname === "/dashboard" && (
                    <span>Dashboard</span>
                  )) ||
                  (location.pathname === "/online-assets" && (
                    <span>Online Assets</span>
                  )) ||
                  (location.pathname === `/online-assets/${id}` && (
                    <span>Online Assets</span>
                  )) ||
                  (location.pathname === "/internal-users" && (
                    <span>Internal Users</span>
                  )) ||
                  (location.pathname === "/core-users" && (
                    <span>Core Users</span>
                  )) ||
                  (location.pathname === "/developer-profile" && (
                    <span>Developer Profile</span>
                  )) ||
                  (location.pathname === `/developer-profile/${id}` && (
                    <span>Developer Profile</span>
                  )) ||
                  (location.pathname === "/role-manager/add" && (
                    <span>Add Role</span>
                  )) ||
                  (location.pathname === `/role-manager/add/${id}` && (
                    <span>Edit Role</span>
                  )) ||
                  (location.pathname === "/role-manager" && (
                    <span>Role Manager</span>
                  )) ||
                  (location.pathname === "/website-guideline" && (
                    <span>Website Guideline</span>
                  )) ||
                  (location.pathname === "/branding-guideline" && (
                    <span>Branding Guideline</span>
                  )) ||
                  (location.pathname === "/website-policies" && (
                    <span>Website Policies</span>
                  )) ||
                  (location.pathname === "/announcements" && (
                    <span>Announcements</span>
                  )) ||
                  (location.pathname === "/ticketing-system" && (
                    <span>Ticketing System</span>
                  )) ||
                  (location.pathname === `/ticketing-system/${id}` && (
                    <span>Ticket Details</span>
                  )) ||
                  (location.pathname === `/add-role/${id}` && (
                    <span>Add Role</span>
                  )) ||
                  (location.pathname === "/uptime-report" && (
                    <span>Uptime Report</span>
                  )) ||
                  (location.pathname === `/uptime-report/${id}` && (
                    <span>Uptime Report Details</span>
                  )) ||
                  (location.pathname === "/hit-report" && (
                    <span>Hit Report</span>
                  )) ||
                  (location.pathname === `/hit-report/${id}` && (
                    <span>Analytics Report Details</span>
                  )) ||
                  (location.pathname === "/performance" && (
                    <span>Performance</span>
                  )) ||
                  (location.pathname === `/performance/${id}` && (
                    <span>Performance Details</span>
                  )) ||
                  (location.pathname === "/web-security" && (
                    <span>Web Security</span>
                  )) ||
                  (location.pathname === `/web-security/${id}` && (
                    <span>Web Security Details</span>
                  )) ||
                  (location.pathname === `/pages-profile/${id}` && (
                    <span>My Profile</span>
                  )) ||
                  (location.pathname === `/pages-profile-settings/${id}` && (
                    <span>My Profile</span>
                  )) ||
                  (location.pathname === `/blocked-ip` && (
                    <span>Blocked IP</span>
                  )) ||
                  (location.pathname === `/audit-logs` && (
                    <span>Audit Logs</span>
                  )) ||
                  (location.pathname === `/compliance` && (
                    <span>Compliance</span>
                  )) ||
                  (location.pathname === `/notification-setting` && (
                    <span>Notification Setting</span>
                  )) ||
                  (location.pathname === `/email-logs` && (
                    <span>Email Logs</span>
                  )) ||
                  (location.pathname === `/notifications` && (
                    <span>Notification Detail</span>
                  ))}
              </h5> */}
              
              <div className="header-search">
                <Search
                  className="d-none d-md-block"
                  style={{ position: "relative", width: "100%" }}
                >
                  <SearchIconWrapper>
                    <i
                      className="ri-search-line search-icon"
                      style={{
                        position: "relative",
                        zIndex: "1",
                        color: "#BABCC1",
                        fontSize: "16px",
                      }}
                    ></i>
                  </SearchIconWrapper>

                  <StyledInputBase
                    placeholder="Search"
                    inputProps={{ "aria-label": "search" }}
                    value={searchQuery}
                    onChange={(e) => handleSearchChange(e)}
                  />

                  <div
                    style={{
                      position: "absolute",
                      right: "24px",
                      top: "50%",
                      transform: "translateY(-50%)",
                      cursor: "pointer",
                      opacity: "0.5",
                    }}
                  >
                    {searchQuery.length > 0 && (
                      <CloseIcon
                        onClick={() =>
                          handleSearchChange({ target: { value: "" } })
                        }
                      />
                    )}
                  </div>
                </Search>

                {searchResult.length > 0 &&
                  location?.state !== searchResult[0]?.data && (
                    <ul className="header-search-visible">
                      {searchResult.length > 0 ? (
                        searchResult.map((result, index) => (
                          <li
                            key={index}
                            style={{
                              padding: "10px",
                              cursor: "pointer",
                              borderBottom:
                                index !== searchResult.length - 1
                                  ? "1px solid #e4e8eb"
                                  : "none",
                            }}
                            onClick={() => {
                              if (result.path === "/ticketing-system") {
                                handleNavigateSearch(
                                  result.path,
                                  result.ticketId
                                );
                              } else {
                                handleNavigateSearch(result.path, result.data);
                              }
                            }}
                          >
                            <div>
                              <span className="fw-semibold">{result.data}</span>{" "}
                              <small className="ms-1">({result.modal})</small>
                            </div>
                          </li>
                        ))
                      ) : (
                        <li style={{ padding: "10px" }}>
                          No suggestions available
                        </li>
                      )}
                    </ul>
                  )}
              </div>
            </Box>

            <Box
              className="d-flex align-items-center bg-white ps-3"
              sx={{ borderRadius: "0 0 0 16px" }}
            >
              <Dropdown
                isOpen={search}
                toggle={toogleSearch}
                className="d-md-none topbar-head-dropdown header-item header-search"
              >
                <DropdownToggle
                  type="button"
                  tag="button"
                  className="btn btn-icon btn-topbar btn-ghost-secondary rounded-circle"
                >
                  <i className="bx bx-search fs-22"></i>
                </DropdownToggle>
                <DropdownMenu className="dropdown-menu-lg dropdown-menu-end p-0">
                  <Search>
                    <SearchIconWrapper>
                      <i
                        className="ri-search-line search-icon"
                        style={{ position: "relative", zIndex: "5", color: "#BABCC1", fontSize: "18px" }}
                      ></i>
                    </SearchIconWrapper>
                    <StyledInputBase
                      placeholder="Search"
                      inputProps={{ "aria-label": "search" }}
                      value={searchQuery}
                      onChange={(e) => handleSearchChange(e)}
                    />

                    {searchResult.length > 0 &&
                      location?.state !== searchResult[0]?.data && (
                        <ul className="header-search-visible">
                          {searchResult.length > 0 ? (
                            searchResult.map((result, index) => (
                              <li
                                key={index}
                                style={{
                                  padding: "10px",
                                  cursor: "pointer",
                                  borderBottom:
                                    index !== searchResult.length - 1
                                      ? "1px solid #e4e8eb"
                                      : "none",
                                }}
                                onClick={() => {
                                  if (result.path === "/ticketing-system") {
                                    handleNavigateSearch(
                                      result.path,
                                      result.ticketId
                                    );
                                  } else {
                                    handleNavigateSearch(
                                      result.path,
                                      result.data
                                    );
                                  }
                                }}
                              >
                                <div>
                                  <span className="fw-semibold">
                                    {result.data}
                                  </span>{" "}
                                  <small className="ms-1">
                                    ({result.modal})
                                  </small>
                                </div>
                              </li>
                            ))
                          ) : (
                            <li style={{ padding: "10px" }}>
                              No suggestions available
                            </li>
                          )}
                        </ul>
                      )}
                  </Search>
                </DropdownMenu>
              </Dropdown>

              <span title="Notifications">
                <NotificationDropdown />
              </span>
              <span>
                <Button
                  className="d-flex align-items-center cursor-pointer"
                  sx={{
                    margin: { sm: "0 20px", xxl: "0 40px" },
                    "&:hover": {
                      background: "transparent !important",
                    },
                  }}
                  onClick={handleClickMenu}
                  endIcon={<ArrowDropDownIcon sx={{ color: "#BABCC1" }} />}
                >
                  <StyledBadge
                    overlap="circular"
                    anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
                    variant="dot"
                  >
                    {userData?.user_image !== "null" ? (
                      <Avatar
                        alt="logo"
                        src={`${process.env.REACT_APP_BASE_URL}${userData?.user_image}`}
                        sx={{ background: "#000" }}
                      />
                    ) : (
                      <ProfileImage
                        username={userData?.user_name || "Demo User"}
                        height="100%"
                        width="100%"
                        fontSize="30px"
                      />
                    )}
                  </StyledBadge>
                  <h6
                    className="mb-0 ms-2 ms-xl-3 d-none d-md-block login-uname text-truncate"
                  >
                    {userData?.user_name}
                  </h6>
                </Button>
                <Menu
                  anchorEl={profilemenu}
                  id="account-menu"
                  open={open}
                  onClose={handleClose}
                  onClick={handleClose}
                  PaperProps={{
                    elevation: 0,
                    sx: {
                      overflow: "visible",
                      boxShadow: "0 0 17px 0 rgba(0, 0, 0, 0.08)",
                      borderRadius: "8px",
                      // mt: 1.5,
                      "& .MuiAvatar-root": {
                        width: 32,
                        height: 32,
                        ml: -0.5,
                        mr: 1,
                      },
                    },
                  }}
                  transformOrigin={{ horizontal: "right", vertical: "top" }}
                  anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
                  className="login-dropdown"
                >
                  <MenuItem                    
                    onClick={() => {
                      handleProfile();
                    }}
                  >
                    <ListItemIcon>
                      <PermIdentityOutlinedIcon />
                    </ListItemIcon>
                    My Profile
                  </MenuItem>
                  {userData?.role === "Super Admin" && (
                    <MenuItem onClick={navigateToSecurity}>
                      <ListItemIcon>
                        <LockOutlinedIcon />
                      </ListItemIcon>
                      Security Setting
                    </MenuItem>
                  )}
                  {checkPermission && checkPermission?.canList != null && (
                    <MenuItem onClick={handleBlockIp}>
                      <ListItemIcon>
                        <BlockOutlinedIcon />
                      </ListItemIcon>
                      Blocked IP
                    </MenuItem>
                  )}
                  {/* {userData?.role === "Super Admin" && ( */}
                  {checkAuditLog && checkAuditLog?.canList != null && (
                    <MenuItem onClick={handleAuditLogs}>
                      <ListItemIcon>
                        {/* <PermIdentityOutlinedIcon /> */}
                        <AssignmentOutlinedIcon />
                      </ListItemIcon>
                      Audit Log
                    </MenuItem>
                  )}
                  {userData?.role === "Super Admin" && (
                    <MenuItem onClick={handleEmailLogs}>
                      <ListItemIcon>
                        <EmailOutlinedIcon />
                      </ListItemIcon>
                      Email Log
                    </MenuItem>
                  )}
                  {/* {userData?.role === "Super Admin" && ( */}
                  {checkNotificationSettings &&
                    checkNotificationSettings?.canList != null && (
                      <MenuItem onClick={handleNotificationSetting}>
                        <ListItemIcon>
                          <EditNotificationsIcon />
                        </ListItemIcon>
                        Notification Setting
                      </MenuItem>
                    )}
                  <MenuItem onClick={handleClickOpen}>
                    <ListItemIcon>
                      <DashboardCustomizeIcon />
                    </ListItemIcon>
                    Dashboard Setting
                  </MenuItem>
                  <Divider />

                  {localStorage.getItem("token") ? (
                    <MenuItem onClick={logOut}>
                      <ListItemIcon>
                        <LoginRoundedIcon fontSize="small" />
                      </ListItemIcon>
                      Logout
                    </MenuItem>
                  ) : (
                    <>
                      <MenuItem onClick={logIn}>
                        <ListItemIcon>
                          <LoginRoundedIcon fontSize="small" />
                        </ListItemIcon>
                        Log In
                      </MenuItem>
                    </>
                  )}
                </Menu>
                <img
                  src={NetLogoM}
                  alt="control-base"
                  className="d-none"
                  style={{ height: "30px", margin: "0 10px" }}
                />
              </span>

              {(userData?.role === "Admin" ||
                userData?.role === "Super Admin") && (
                <Link
                  to="/settings"
                  id="settings"
                  title="Settings"
                  className="setting-btn"
                >
                  <SettingsIcon />
                </Link>
              )}
            </Box>
          </Box>
        </Box>

        <Drawer anchor="right" open={show} onClose={handleCloseq}>
          <div className="dashboard-setting">
            <h4 className="main-title fw-bold">Dashboard Settings</h4>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                gap: 10,
                marginTop: 30,
              }}
            >
              <FormControlLabel
                className="label-grid"
                control={
                  <Checkbox
                    checked={settings.activeWebsiteCard}
                    onChange={handleChange}
                    name="activeWebsiteCard"
                    className="cm-input"
                  />
                }
                label={
                  <div className="label-info">
                    <DashboardIcon className="svg-icon" />
                    <Typography variant="body1">Active Website Card</Typography>
                  </div>
                }
              />
              <FormControlLabel
                className="label-grid"
                control={
                  <Checkbox
                    checked={settings.avgUserCard}
                    onChange={handleChange}
                    name="avgUserCard"
                    className="cm-input"
                  />
                }
                label={
                  <div className="label-info">
                    <TrendingUpIcon className="svg-icon" />
                    <Typography variant="body1">Average User Card</Typography>
                  </div>
                }
              />
              <FormControlLabel
                className="label-grid"
                control={
                  <Checkbox
                    checked={settings.activeTicketCard}
                    onChange={handleChange}
                    name="activeTicketCard"
                    className="cm-input"
                  />
                }
                label={
                  <div className="label-info">
                    <LocalOfferIcon className="svg-icon" />
                    <Typography variant="body1">Active Ticket Card</Typography>
                  </div>
                }
              />
              <FormControlLabel
                className="label-grid"
                control={
                  <Checkbox
                    checked={settings.hitDetailCard}
                    onChange={handleChange}
                    name="hitDetailCard"
                    className="cm-input"
                  />
                }
                label={
                  <div className="label-info">
                    <InfoIcon className="svg-icon" />
                    <Typography variant="body1">Hit Detail Card</Typography>
                  </div>
                }
              />
              <FormControlLabel
                className="label-grid"
                control={
                  <Checkbox
                    checked={settings.complianceCard}
                    onChange={handleChange}
                    name="complianceCard"
                    className="cm-input"
                  />
                }
                label={
                  <div className="label-info">
                    <SecurityIcon className="svg-icon" />
                    <Typography variant="body1">Compliance Card</Typography>
                  </div>
                }
              />
              {checkOnlineAsset && checkOnlineAsset?.canList != null && (
                <FormControlLabel
                  className="label-grid"
                  control={
                    <Checkbox
                      checked={settings.onlineAssetList}
                      onChange={handleChange}
                      name="onlineAssetList"
                      className="cm-input"
                    />
                  }
                  label={
                    <div className="label-info">
                      <VisibilityIcon className="svg-icon" />
                      <Typography variant="body1">Online Asset List</Typography>
                    </div>
                  }
                />
              )}
              {checkTicketSystem && checkTicketSystem?.canList != null && (
                <FormControlLabel
                  className="label-grid"
                  control={
                    <Checkbox
                      checked={settings.ticketList}
                      onChange={handleChange}
                      name="ticketList"
                      className="cm-input"
                    />
                  }
                  label={
                    <div className="label-info">
                      <ListAltIcon className="svg-icon" />
                      <Typography variant="body1">Ticket List</Typography>
                    </div>
                  }
                />
              )}
            </div>
          </div>
        </Drawer>
      </header>
    </React.Fragment>
  );
};

export default Header;
