export const ListHitReportReq = (props) => {
  const { query, startDate, endDate, isDashboardView, token } = props;

  const url = new URL(
    `${process.env.REACT_APP_BASE_URL}asset/analytics-hit-report?searchParams=${query}&isDashboardView=${isDashboardView}`
  );
  const selectionRange = {
    startDate: startDate,
    endDate: endDate,
  };

  const requestOptions = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
    body: JSON.stringify(selectionRange),
    redirect: "follow",
  };

  return { url: url, requestOptions };
};
export const DashboardListHitReportReq = (props) => {
  const { startDate, endDate, isDashboardView, token } = props;

  const url = new URL(
    `${
      process.env.REACT_APP_BASE_URL
    }asset/desktop-analytics-hit-report?searchParams=${""}&isDashboardView=${isDashboardView}`
  );
  const selectionRange = {
    startDate: startDate,
    endDate: endDate,
  };

  const requestOptions = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
    body: JSON.stringify(selectionRange),
    redirect: "follow",
  };

  return { url: url, requestOptions };
};
