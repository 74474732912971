import React, { useCallback, useEffect, useRef, useState } from "react";
import { Card } from "react-bootstrap";
import { Box, FormControl, MenuItem, Select } from "@mui/material";
import { Button, Spinner } from "reactstrap";
import LoopIcon from "@mui/icons-material/Loop";
import DataTable from "react-data-table-component";
import { CustomTableStyle } from "../../Components/Common/constants";
import { DropdownStyle, MenuHeight } from "../NewDashboard/constant";
import axios from "axios";
// import { useNavigate } from "react-router-dom";
// import FormatDate from "../../Components/Common/formatDate";
import InfoIcon from "@mui/icons-material/Info";
import AuditLogsInfoModal from "./AuditLogsInfoModal";
import moment from "moment";
import Flatpickr from "react-flatpickr";
import { formatDateTime } from "../../Components/Common/FormatDateTIme";
import { useSelector } from "react-redux";
import common from "../../Components/Common/common";

const useDebounce = (value, delay) => {
  const [debouncedValue, setDebouncedValue] = useState(value);
  useEffect(() => {
    const handler = setTimeout(() => {
      setDebouncedValue(value);
    }, delay);
    return () => {
      clearTimeout(handler);
    };
  }, [value, delay]);
  return debouncedValue;
};

const AuditLogs = () => {
  const [query, setQuery] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [actionStatus, setActionStatus] = useState("");
  const [moduleStatusId, setModuleStatusId] = useState("");
  const [data, setData] = useState([]);
  const [moduleNames, setModuleNames] = useState([]);
  const [currentRow, setCurrentRow] = useState(null);
  const [modalShow, setModalShow] = useState(false);
  const [selectedDate, setSelectedDate] = useState([]);
  const [totalRowCount, setTotalRowCount] = useState("");
  const [perPage, setPerPage] = useState(10);
  const flatpickrRef = useRef(null);

  const generalSettingData = useSelector(
    (state) => state.settingsData.settingsData
  );

  const otherSettingsData = generalSettingData?.filter(
    (item) => item.settings_category === "other"
  );

  const dateFormat = otherSettingsData?.find(
    (date) => date.field_name === "DEFAULT_DATE_FORMAT"
  )?.field_value;

  const timeZone = otherSettingsData?.find(
    (time) => time.field_name === "DEFAULT_TIME_FORMAT"
  )?.field_value;

  const timeFormat = otherSettingsData?.find(
    (zone) => zone.field_name === "DEFAULT_TIME_ZONE"
  )?.field_value;

  const token = localStorage.getItem("token");

  const handleReset = () => {
    if (flatpickrRef.current) {
      flatpickrRef.current.flatpickr.clear();
    }
    setSelectedDate([]);
    setQuery("");
    setActionStatus("");
    setModuleStatusId("");
  };

  const getLogData = useCallback(
    async (page, perPageSize, action, search, moduleId, startDate, endDate) => {
      setIsLoading(true);
      let params = {
        page,
        limit: perPageSize,
      };
      if (action) params.action = action;
      if (search) params.search = search;
      if (moduleId) params.moduleId = moduleId;
      if (startDate) params.startDate = startDate;
      if (endDate) params.endDate = endDate;
      try {
        let response = await axios.get("auditLogs/get-audit-logs", {
          params,
          headers: { Authorization: `Bearer ${token}` },
        });
        const decryptedData = common.decrypt(response.data);
        response.data = decryptedData;
        if (response.status === "success") {
          setData(response.data?.rows);
          setTotalRowCount(response.data?.count);
          setIsLoading(false);
        }
      } catch (error) {
        setIsLoading(false);
      }
    },
    [token]
  );

  const getAllModules = useCallback(async () => {
    setIsLoading(true);
    try {
      let response = await axios.get("auditLogs/get-audit-modules", {
        headers: { Authorization: `Bearer ${token}` },
      });

      const decryptedData = common.decrypt(response.data);
      response.data = decryptedData;
      setModuleNames(response?.data);
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
    }
  }, [token]);

  const titleUpperCase = (str) => {
    let splitStr = str.toLowerCase().split(" ");
    for (let i = 0; i < splitStr.length; i++) {
      splitStr[i] =
        splitStr[i].charAt(0).toUpperCase() + splitStr[i].substring(1);
    }
    return splitStr.join(" ");
  };

  const debouncedQuery = useDebounce(query, 500);

  const columns = [
    {
      name: "user name",
      selector: (row) => row.user_name,
    },
    {
      name: "IP address",
      selector: (row) => row.ip_address,
    },
    {
      name: "role",
      selector: (row) => row.name,
    },
    {
      name: "module",
      selector: (row) => titleUpperCase(row.module_name.replace("-", " ")),
    },
    {
      name: "created time",
      selector: (row) =>
        formatDateTime(row.createdAt, dateFormat, timeZone, timeFormat).timeZone
          .formattedDateFinal +
        " - " +
        formatDateTime(row.createdAt, dateFormat, timeZone, timeFormat).timeZone
          .formattedTimed,
    },
    {
      name: "Action",
      selector: (row) => row.action,
    },
    {
      name: "details",
      cell: (row) => (
        <div
          onClick={() => {
            setCurrentRow(row);
            setModalShow(true);
          }}
        >
          <InfoIcon style={{ cursor: "pointer" }} />
        </div>
      ),
    },
  ];

  const departmentOptions = [
    { value: "UPDATE", label: "UPDATED" },
    { value: "DELETE", label: "DELETED" },
    { value: "ADD", label: "ADDED" },
  ];

  const handleDateChange = (selectedDates) => {
    if (selectedDates[0] !== undefined) {
      setSelectedDate(selectedDates);
    }
  };

  let [startDate, endDate] = selectedDate;
  if (startDate) {
    startDate = moment(startDate).format("YYYY-MM-DD");
  }
  if (endDate) {
    endDate = moment(endDate).format("YYYY-MM-DD");
  }

  useEffect(() => {
    getAllModules();
  }, [getAllModules]);

  // useEffect(() => {
  //   getLogData(
  //     1,
  //     perPage,
  //     actionStatus,
  //     debouncedQuery,
  //     moduleStatusId,
  //     startDate,
  //     endDate
  //   );
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [
  //   actionStatus,
  //   debouncedQuery,
  //   moduleStatusId,
  //   selectedDate,
  //   getLogData,
  //   perPage,
  // ]);

  // const handlePageChange = (page) => {
  //   getLogData(
  //     page,
  //     perPage,
  //     actionStatus,
  //     debouncedQuery,
  //     moduleStatusId,
  //     startDate,
  //     endDate
  //   );
  // };

  // const handlePerRowsChange = async (newPerPage) => {
  //   setPerPage(newPerPage);
  //   getLogData(1, newPerPage);
  // };

  // Assuming getLogData is memoized with useCallback
  useEffect(() => {
    getLogData(
      1, // initial page load
      perPage,
      actionStatus,
      debouncedQuery,
      moduleStatusId,
      startDate,
      endDate
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    actionStatus,
    debouncedQuery,
    moduleStatusId,
    startDate, // added missing startDate and endDate
    endDate,
    perPage,
    getLogData,
  ]);

  const handlePageChange = (page) => {
    getLogData(
      page,
      perPage, // keep the current perPage
      actionStatus,
      debouncedQuery,
      moduleStatusId,
      startDate,
      endDate
    );
  };

  const handlePerRowsChange = (newPerPage) => {
    setPerPage(newPerPage);
    // Reset page to 1 when changing rows per page
    getLogData(
      1, // reset to page 1
      newPerPage,
      actionStatus,
      debouncedQuery,
      moduleStatusId,
      startDate,
      endDate
    );
  };

  return (
    <Card className="table-main-card">
      <Box className="table-card-header">
        <Box className="left-side">
          {/* Search box */}
          <Box className="search-box">
            <input
              type="text"
              value={query}
              className="form-control"
              placeholder="Search log users..."
              onChange={(e) => {
                setQuery(e.target.value);
              }}
            />
            <i className="ri-search-line search-icon"></i>
          </Box>
          {/* Select tag for actions */}
          <FormControl
            fullWidth
            variant="filled"
            sx={{
              width: "150px",
              minWidth: "150px",
            }}
          >
            <Select
              displayEmpty
              fullWidth
              value={actionStatus}
              onChange={(e) => {
                setActionStatus(e.target.value);
              }}
              sx={DropdownStyle}
              MenuProps={MenuHeight}
            >
              <MenuItem disabled value="">
                Select Action
              </MenuItem>
              {departmentOptions.map((option) => (
                <MenuItem
                  key={option.value}
                  value={option.value}
                  sx={{ whiteSpace: "break-spaces" }}
                >
                  {option.label}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          {/* Select tag for module */}
          <FormControl
            fullWidth
            variant="filled"
            sx={{
              width: "150px",
              minWidth: "150px",
            }}
          >
            <Select
              displayEmpty
              fullWidth
              value={moduleStatusId}
              onChange={(e) => {
                setModuleStatusId(e.target.value);
              }}
              sx={DropdownStyle}
              MenuProps={MenuHeight}
            >
              <MenuItem disabled value="">
                Select module
              </MenuItem>
              {moduleNames.map((option) => {
                return (
                  <MenuItem
                    key={option.module_id}
                    value={option.module_id}
                    sx={{ whiteSpace: "break-spaces" }}
                  >
                    {option.title}
                  </MenuItem>
                );
              })}
            </Select>
          </FormControl>
          {/* Select date for audit logs */}
          <Box className="dateinput">
            <Flatpickr
              placeholder="Select Date"
              ref={flatpickrRef}
              className="form-control"
              options={{
                dateFormat: `${dateFormat}`,
                mode: "range",
              }}
              value={selectedDate}
              onChange={handleDateChange}
            />
          </Box>

          <Button className="btn btn-primary reset-btn" onClick={handleReset}>
            <LoopIcon />
          </Button>
        </Box>
      </Box>
      <Card.Body>
        {/* {isLoading ? (
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              height: "50vh",
            }}
          >
            <Spinner animation="border" />
          </div>
        ) : (
          <DataTable
            fixedHeight="500px"
            pointerOnHover
            columns={columns}
            data={data}
            // progressPending={loading}
            pagination
            paginationServer
            paginationTotalRows={totalRowCount}
            onChangePage={handlePageChange}
            onChangeRowsPerPage={handlePerRowsChange}
            customStyles={CustomTableStyle}
            className="table-design"
            paginationRowsPerPageOptions={[10, 20, 30, 40, 50]}
          />
        )} */}

        <DataTable
          fixedHeight="500px"
          pointerOnHover
          columns={columns}
          data={data}
          // progressPending={loading}
          pagination
          paginationServer
          paginationTotalRows={totalRowCount}
          onChangePage={handlePageChange}
          onChangeRowsPerPage={handlePerRowsChange}
          customStyles={CustomTableStyle}
          className="table-design"
          paginationRowsPerPageOptions={[10, 20, 30, 40, 50]}
        />
      </Card.Body>
      {modalShow && (
        <AuditLogsInfoModal
          show={modalShow}
          onHide={() => setModalShow(false)}
          currentRow={currentRow}
        />
      )}
    </Card>
  );
};

export default AuditLogs;
