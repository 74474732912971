import React, { useCallback, useEffect, useState } from "react";
import DataTable from "react-data-table-component";
import { useFormik } from "formik";
import * as Yup from "yup";
import { Button, Spinner } from "reactstrap";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import Swal from "sweetalert2";
import "./InternalUsers.css";
import { ToastMessage } from "../../../Components/Common/ToastMessage";
import ProfileImage from "../../../Components/Common/ProfileImage";
import { API_ROUTES } from "../../../Api/ApiRoutes";
import noSelectedImage from "../../../assets/images/users/user-dummy-img.jpg";
import {
  DeleteDepartment,
  changeInternalUserStatusReq,
  getInternalUsersReq,
  updateInternalUsersReq,
} from "../../../Api/Users/InternalUsers/InternalUsersReq";
import {
  DeleteDepartmentRes,
  changeInternalUserStatusRes,
  getInternalUsersRes,
  updateInternalUsersRes,
} from "../../../Api/Users/InternalUsers/InternalUsersRes";
import _ from "lodash";
import { USERS } from "../../../Components/Common/ModuleName";
import { useSelector } from "react-redux";
import InternalUsersModal from "./InternalUsersModal";
import { Card, OverlayTrigger, Tooltip } from "react-bootstrap";
import { Autocomplete, Box, FormControl, TextField } from "@mui/material";
import { DeleteIcon } from "../../../Components/Common/SvgIcons";
import { CustomTableStyle } from "../../../Components/Common/constants";
import LoopIcon from "@mui/icons-material/Loop";
import { AutoDropdownStyle } from "../../NewDashboard/constant";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import { socket } from "../../../Components/Common/socket";
import common from "../../../Components/Common/common";

const InternalUsers = () => {
  const token = localStorage.getItem("token");
  const user_id = Number(localStorage.getItem("user_id"));

  const navigate = useNavigate();

  const permissionData = useSelector(
    (state) => state.userDetails.userPermission
  );
  const userData = useSelector((state) => state.userDetails.userData);
  const hasPermission = permissionData?.find(
    (item) => item.module_name === USERS
  );

  const [query, setQuery] = useState("");
  const [selectedImage, setSelectedImage] = useState(null);
  const [roleType, setRoleType] = useState([]);
  const [roleSelectOpt, setRoleSelectOpt] = useState(null);
  const [departmentSelectOpt, setDepartmentSelectOpt] = useState(null);
  const [websiteCategory, setWebsiteCategory] = useState([]);
  const [statusSelectOpt, setStatusSelectOpt] = useState({
    value: "Active",
    label: "Active",
  });
  const [selectAllWebsites, setSelectAllWebsites] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [selectedDepartment, setSelectedDepartment] = useState(null);
  const [data, setData] = useState([]);
  const [show, setShow] = useState(false);
  const [hiddenPassword, setHiddenPassword] = useState("");
  const [selectedOptions, setSelectedOptions] = useState([]);
  const [allOptions, setAllOptions] = useState([]);
  const [checkboxes, setCheckboxes] = useState({
    web_security_read: false,
    web_security_execute: false,
    notes_read: false,
    notes_execute: false,
  });
  const [postIsLoading, setPostIsLoading] = useState(false);
  const [departmentStatus, setDepartmentStatus] = useState("");

  const handleShow = () => setShow(true);

  const validation = useFormik({
    enableReinitialize: true,

    initialValues: {
      id: null,
      fullName: "",
      roleSelect: "",
      departmentSelect: null,
      asset_url_management: null,
      email: "",
      password: "",
      confirmPassword: "",
      hiddenPassword: hiddenPassword,
      phone: "",
      statusOption: "Active",
      departmentImage: null,
    },

    validationSchema: Yup.object({
      fullName: Yup.string()
        .trim()
        .max(60)
        .required("Please enter the full name."),
      roleSelect: Yup.string().max(255).required("Please select role."),

      departmentSelect: Yup.mixed().when("roleSelect", {
        is: (val) => val !== "Super Admin" && val !== "Admin",
        then: () =>
          Yup.mixed()
            .test(
              "is-number-or-empty",
              "Please select a department",
              (value) =>
                value === "" ||
                (Number.isInteger(Number(value)) && Number(value) > 0)
            )
            .required("Please select department."),
        otherwise: () => Yup.mixed().nullable(),
      }),

      asset_url_management: Yup.array()
        .min(1, "Please select at least one website.")
        .nullable(),
      email: Yup.string()
        .email("Must be a valid email.")
        .max(70)
        .required("Please enter the Email."),
      password: Yup.string().test("password-condition", function (value) {
        if (this.parent.hiddenPassword) {
          return true; // Skip validation if hiddenPassword exists (edit mode)
        }
        if (!value) {
          return this.createError({ message: "Password is required" });
        }
        if (value.length < 8) {
          return this.createError({
            message: "Password must be at least 8 characters",
          });
        }
        if (!/[a-z]/.test(value)) {
          return this.createError({
            message: "Password must include at least one lowercase letter",
          });
        }
        if (!/[A-Z]/.test(value)) {
          return this.createError({
            message: "Password must include at least one uppercase letter",
          });
        }
        if (!/[0-9]/.test(value)) {
          return this.createError({
            message: "Password must include at least one number",
          });
        }
        return true;
      }),
      confirmPassword: Yup.string().when("hiddenPassword", {
        is: (hiddenPassword) => !hiddenPassword,
        then: (schema) =>
          schema
            .oneOf([Yup.ref("password"), null], "Passwords must match")
            .required("Please confirm your password."),
        otherwise: (schema) => schema,
      }),
      phone: Yup.string().matches(
        /^(\(\d{3}\)[- .]?)?\d{3}[- .]?\d{4}|\d{10}$/,
        "Please enter a valid phone number"
      ),
      statusOption: Yup.string().max(255).required("Please select the status."),
    }),

    onSubmit: (values, { resetForm }) => {
      // updateUsers(values);
      const submitValues = {
        ...values,
        departmentSelect:
          values.departmentSelect === ""
            ? null
            : Number(values.departmentSelect),
      };
      updateUsers(submitValues);
    },
  });

  let id = validation?.values?.id;

  const handleSelectChange = (selectedOptions) => {
    setSelectedOptions(selectedOptions);
    const allValues = allOptions.map((option) => option.value);
    const selectedValues = selectedOptions.map((option) => option.value);

    validation.setFieldValue(
      "asset_url_management",
      selectedOptions.map((item) => item.value)
    );

    setSelectAllWebsites(selectedValues.length === allValues.length);
  };

  const handleCheckboxChange = (event) => {
    const { name, checked } = event.target;
    if (name === "selectAllWebsites") {
      if (checked) {
        validation.setFieldValue(
          "asset_url_management",
          allOptions.map((item) => item.value)
        );
      } else {
        validation.setFieldValue("asset_url_management", []);
      }
      setSelectAllWebsites(checked);
      setSelectedOptions(checked ? allOptions : []);
    } else {
      setCheckboxes({ ...checkboxes, [name]: checked });
    }
  };

  const getInternalUsers = useCallback(
    async (searchParams) => {
      try {
        setIsLoading(true);

        let params = {
          searchParams: searchParams || " ",
          departmentNumber: departmentStatus || " ",
        };

        const { config } = getInternalUsersReq({ params, token });

        const response = await axios.request(config);

        getInternalUsersRes({
          response,
          selectedDepartment,
          setData,
          navigate,
        });
      } catch (error) {
        ToastMessage.Error(error.message);
      } finally {
        setIsLoading(false);
      }
    },
    [navigate, selectedDepartment, token, departmentStatus]
  );

  useEffect(() => {
    getInternalUsers();
  }, [getInternalUsers, selectedDepartment, departmentStatus]);

  useEffect(() => {
    const debouncedFetchData = _.debounce(() => {
      getInternalUsers(query);
    }, 500);

    debouncedFetchData();

    return () => {
      debouncedFetchData.cancel();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [query, selectedDepartment, departmentStatus]);

  const getUserType = useCallback(async () => {
    try {
      let { data, status, message } = await axios.get(API_ROUTES.GET_ROLE);
      const decryptedData = common.decrypt(data);

      if (status === "success") setRoleType(decryptedData);
      if (status === "fail") ToastMessage.Error(message);
    } catch (error) {
      console.error(error.message);
    }
  }, []);

  useEffect(() => {
    getUserType();
  }, [getUserType]);

  let security = [];

  if (checkboxes.web_security_read === true) {
    security.push("read");
  }

  if (checkboxes.web_security_execute === true) {
    security.push("execute");
  }

  let notes = [];

  if (checkboxes.notes_read === true) {
    notes.push("read");
  }

  if (checkboxes.notes_execute === true) {
    notes.push("execute");
  }

  const getWebsiteData = useCallback(async (searchParams) => {
    try {
      const { status, data } = await axios.get(
        `websiteCategories/get-category`
      );

      const responseData = common.decrypt(data);
      if (status === "success") setWebsiteCategory(responseData);
    } catch (error) {
      console.error("Error fetching website data:", error);
    }
  }, []);

  useEffect(() => {
    getWebsiteData();
  }, [getWebsiteData]);

  const assetsIdArray = selectedOptions.map((item) => item.value);

  let assetUrlManagement = {
    assetsId: assetsIdArray,
    web_security: security,
    notes: notes,
  };

  const updateData = async (row) => {
    console.log("d options", departmentOptions);
    console.log("d select befpre", departmentSelectOpt);
    setDepartmentSelectOpt(
      departmentOptions.find((option) => option.value === row.department_id)
    );
    console.log("d select after", departmentSelectOpt);

    setRoleSelectOpt(roleOptions.find((option) => option.value === row.role));
    setStatusSelectOpt(options.find((option) => option.value === row.status));

    let allValues = await fetchAssetsByDepartment(
      { value: row.department_id },
      row.role
    );

    const assetManagementData = JSON.parse(row.asset_url_management);
    const { notes, web_security } = assetManagementData;

    let selectedValues = allValues.filter((option) =>
      assetManagementData.assetsId.includes(option.value)
    );

    setSelectedOptions(selectedValues);

    setCheckboxes({
      web_security_read: web_security.includes("read"),
      web_security_execute: web_security.includes("execute"),
      notes_read: notes.includes("read"),
      notes_execute: notes.includes("execute"),
    });

    console.log("row departement", row.department_id);

    validation.setValues({
      id: row.id,
      fullName: row.user_name,
      roleSelect: row.role,
      departmentSelect: row.department_id || 0,
      email: row.email,
      hiddenPassword: row.password,
      password: "", // Set this to the current password
      // confirmPassword: row.password, // Set confirmPassword to match the password
      phone: row.contact_no || "",
      statusOption: row.status,
      departmentImage: row.user_image,
    });
    setShow(true);
  };

  console.log("validation values", validation.values);

  const updateUsers = async (values) => {
    setPostIsLoading(true);
    try {
      const { url, reqData } = updateInternalUsersReq(
        values,
        id,
        assetUrlManagement,
        hiddenPassword
      );

      let result = await fetch(url, reqData);
      let response = await result.json();

      const decryptedData = common.decrypt(response.data);
      response.data = decryptedData;

      if (response.status === "success") {
        response.message.message === "User updated successfully"
          ? socket.emit("updateCoreUser")
          : socket.emit("addCoreUser");
      }

      updateInternalUsersRes({
        response,
        navigate,
        clearState,
        getInternalUsers,
        setShow,
        ToastMessage,
        id,
      });

      setTimeout(() => {
        setAllOptions([]);
        setHiddenPassword("");
        validation.resetForm();
        setSelectedImage(null);
        setRoleSelectOpt(null);
        setDepartmentSelectOpt(null);
        clearState();
        setStatusSelectOpt({ value: "Active", label: "Active" });
      }, 2000);
      setPostIsLoading(false);
    } catch (error) {
      setPostIsLoading(false);
      console.error("error", error);
    } finally {
      // Ensure postIsLoading is set to false after the request
      setPostIsLoading(false);
    }
  };

  const deleteDepartment = async (id, userId) => {
    try {
      const { url, reqData } = DeleteDepartment(id, userId);

      let result = await fetch(url, reqData);
      let response = await result.json();

      if (response.message === "success") socket.emit("deleteCoreUser");

      DeleteDepartmentRes({ response, navigate, getInternalUsers });
    } catch (error) {
      console.error(error);
    }
  };

  const updateStatus = async (id, newStatus, email) => {
    try {
      const { url, reqData } = changeInternalUserStatusReq(
        id,
        newStatus,
        email
      );

      let result = await fetch(url, reqData);
      let response = await result.json();

      changeInternalUserStatusRes({
        response,
        navigate,
        getInternalUsers,
        ToastMessage,
      });
    } catch (error) {
      return null;
    }
  };

  const handleDeleteAlert = (id) => {
    const swalWithBootstrapButtons = Swal.mixin({
      customClass: {
        confirmButton: "btn btn-dark mx-2",
        cancelButton: "btn btn-primary mx-2",
      },
      buttonsStyling: false,
    });

    swalWithBootstrapButtons
      .fire({
        title: "Are you sure?",
        text: "You won't be able to revert this!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes, delete it!",
        cancelButtonText: "No, cancel!",
        reverseButtons: true,
      })
      .then((result) => {
        if (result.isConfirmed) {
          deleteDepartment(id);
          swalWithBootstrapButtons.fire(
            "Deleted!",
            "User has been deleted.",
            "success"
          );
        } else if (result.dismiss === Swal.DismissReason.cancel) {
          swalWithBootstrapButtons.fire(
            "Cancelled",
            "User is safe :)",
            "error"
          );
        }
      });
  };

  const columns = [
    {
      name: "Name",
      cell: (row) => (
        <>
          <Box className="d-flex align-items-center">
            <Box className="flex-shrink-0 me-2">
              {row.user_image != null ? (
                <img
                  src={`${process.env.REACT_APP_BASE_URL}${row.user_image}`}
                  height="32px"
                  width="32px"
                  alt=""
                  style={{ borderRadius: "100px", background: "#f1f1f1" }}
                />
              ) : (
                <ProfileImage username={row.user_name} />
              )}
            </Box>
            <Box className="flex-grow-1">
              {<strong>{row.user_name}</strong>}
            </Box>
          </Box>
        </>
      ),
      selector: (row) => row.user_name,
      sortable: true,
      grow: 1.5,
      minWidth: "170px",
    },
    {
      name: "Role",
      selector: (row) => row.role || "-",
      minWidth: "150px",
    },
    {
      name: "Department",
      cell: (row) => {
        const departmentLabel = websiteCategory.find(
          (category) => category.id === row.department_id
        )?.category;
        return departmentLabel || "-";
      },
      grow: 1.5,
      minWidth: "200px",
    },
    {
      name: "E-mail ID",
      cell: (row) => (
        <>
          <a href={`mailto:${row.email}`} style={{ marginRight: "8px" }}>
            {row.email || "-"}
          </a>
        </>
      ),
      sortable: true,
      grow: 1.5,
      minWidth: "200px",
    },
    {
      name: "Phone No.",
      cell: (row) => (
        <>
          <a href={`tel:${row.contact_no}`} style={{ marginRight: "8px" }}>
            {row.contact_no || "-"}
          </a>
        </>
      ),
      sortable: true,
      grow: 1.5,
      minWidth: "200px",
    },
    {
      name: "Status",
      cell: (row) => {
        return row.status.includes("In-Active") ? (
          <>
            <span
              className="badge bg-soft-danger cursor-pointer"
              onClick={() => {
                updateStatus(row.id, "In-Active", row.email);
              }}
            >
              In-Active
            </span>
          </>
        ) : (
          <>
            <span
              className={`badge bg-soft-success ${
                row.id !== user_id ? "cursor-pointer" : ""
              }`}
              style={row.id === user_id ? { opacity: 0.6 } : {}}
              onClick={
                row.id !== user_id
                  ? () => updateStatus(row.id, "Active", row.email)
                  : undefined
              }
            >
              Active
            </span>
          </>
        );
      },
      center: true,
      minWidth: "130px",
    },
    {
      name: (
        <span className="text-center">
          {hasPermission &&
          (hasPermission?.canModify != null || hasPermission?.canDelete != null)
            ? "Actions"
            : ""}
        </span>
      ),
      cell: (row) => (
        <span className="table-action">
          {hasPermission && hasPermission?.canModify != null ? (
            <>
              <OverlayTrigger
                placement="top"
                overlay={<Tooltip id={`tooltip-edit-${row.id}`}>Edit</Tooltip>}
              >
                <span
                  id="create-btn"
                  className="cursor-pointer"
                  onClick={() => updateData(row, assetUrlManagement)}
                >
                  <EditOutlinedIcon />
                </span>
              </OverlayTrigger>
            </>
          ) : (
            ""
          )}
          {hasPermission && hasPermission?.canDelete != null ? (
            <>
              <OverlayTrigger
                placement="top"
                overlay={
                  <Tooltip id={`tooltip-delete-${row.id}`}>Delete</Tooltip>
                }
              >
                <span
                  className={` ${row.id !== user_id ? "cursor-pointer" : ""}`}
                  style={row.id === user_id ? { opacity: 0.6 } : {}}
                  onClick={() =>
                    row.id !== user_id ? handleDeleteAlert(row.id) : undefined
                  }
                >
                  <DeleteIcon />
                </span>
              </OverlayTrigger>
            </>
          ) : (
            ""
          )}
        </span>
      ),
      minWidth: "120px",
      center: true,
    },
  ];

  const options = [
    { value: "Active", label: "Active" },
    { value: "In-Active", label: "In-Active" },
  ];

  const roleOptions = roleType.map((d) => ({
    value: d.name,
    label: d.name,
  }));

  const departmentOptions = websiteCategory.map((d) => ({
    value: d.id,
    label: d.category,
  }));

  const fetchAssetsByDepartment = async (
    departmentSelectOpt,
    role = null,
    dontResetFlag = true
  ) => {
    try {
      let departmentId =
        (departmentSelectOpt && departmentSelectOpt.value) || null;
      const { data } = await axios.post(
        "online-assets/online-assets-department-list",
        {
          department_id: departmentId,
          role,
        }
      );

      const decrypedData = common.decrypt(data);

      const assetIds = decrypedData.map((item) => ({
        label: item.website_url,
        value: item.id,
      }));
      setAllOptions(assetIds);
      if (!dontResetFlag) return assetIds;

      setCheckboxes({
        web_security_read: false,
        web_security_execute: false,
        notes_read: false,
        notes_execute: false,
      });
      setSelectedOptions([]);

      return assetIds;
    } catch (error) {
      console.error("Error fetching assets:", error);
    }
  };

  const handleChangeImage = (event) => {
    const file = event.target.files[0];

    if (file) {
      const allowedFormats = [
        "image/jpeg",
        "image/jpg",
        "image/png",
        "image/svg+xml",
      ];
      const validSize = file.size <= 1024 * 1024;

      if (!allowedFormats.includes(file.type)) {
        validation.setFieldError(
          "departmentImage",
          "Invalid file format. Only JPEG, JPG, PNG, and SVG files are allowed."
        );
        event.target.value = null;
      } else if (!validSize) {
        validation.setFieldError(
          "departmentImage",
          "Failed to upload an image. The image maximum size is 1MB."
        );
        event.target.value = null;
      } else {
        validation.setFieldError("departmentImage", "");
        validation.setFieldValue("departmentImage", file);
        const imageUrl = URL.createObjectURL(file);
        setSelectedImage(imageUrl);
      }
    }
  };

  const clearState = () => {
    validation.resetForm();
    validation.setFieldValue("departmentImage", null);

    setCheckboxes({
      web_security_read: false,
      web_security_execute: false,
      notes_read: false,
      notes_execute: false,
    });
    setSelectedOptions([]);
  };

  const handleClose = () => {
    setShow(false);
    setSelectedOptions([]);
    setAllOptions([]);
    setSelectedImage(null);
    setRoleSelectOpt(null);
    setDepartmentSelectOpt(null);
    setStatusSelectOpt({ value: "Active", label: "Active" });
    setTimeout(() => {
      clearState();
    }, 500);
  };

  const handleReset = () => {
    setSelectedDepartment(null);
    setDepartmentStatus("");
    setQuery("");
    getInternalUsers();
  };

  return (
    <>
      <Card className="table-main-card">
        <Box className="table-card-header">
          <Box className="left-side">
            <Box className="search-box">
              <input
                type="text"
                value={query}
                className="form-control"
                placeholder="Search core users..."
                onChange={(e) => {
                  setQuery(e.target.value);
                }}
              />
              <i className="ri-search-line search-icon"></i>
            </Box>
            {(userData?.role === "Super Admin" ||
              userData?.role === "Admin") && (
              <FormControl
                fullWidth
                variant="filled"
                sx={{
                  width: "220px",
                  minWidth: "190px",
                }}
              >
                <Autocomplete
                  options={departmentOptions}
                  getOptionLabel={(option) => option.label}
                  value={
                    departmentOptions.find(
                      (option) => option.value === departmentStatus
                    ) || null
                  }
                  onChange={(event, newValue) => {
                    setDepartmentStatus(newValue?.value || "");
                  }}
                  renderInput={(params) => (
                    <TextField {...params} label="Select Department" />
                  )}
                  fullWidth
                  sx={AutoDropdownStyle}
                  isOptionEqualToValue={(option, value) =>
                    option.value === value
                  }
                  disableClearable
                  renderOption={(props, option) => (
                    <li {...props} style={{ whiteSpace: "break-spaces" }}>
                      {option.label}
                    </li>
                  )}
                />
              </FormControl>
            )}
            <Button className="btn btn-primary reset-btn" onClick={handleReset}>
              <LoopIcon />
            </Button>
          </Box>
          {hasPermission && hasPermission?.canCreate != null && (
            <>
              <button
                type="button"
                className="btn btn-primary view-btn"
                id="create-btn"
                onClick={handleShow}
                style={{ minWidth: "162px" }}
              >
                <i className="ri-user-add-line icon-md me-2 fs-15 align-middle"></i>
                Add User
              </button>
            </>
          )}
        </Box>
        <Card.Body>
          {isLoading ? (
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                height: "50vh",
              }}
            >
              <Spinner height={100} width={100} />
            </div>
          ) : (
            <DataTable
              fixedHeight="500px"
              columns={columns}
              data={data}
              pagination
              customStyles={CustomTableStyle}
              className="table-design"
            />
          )}
        </Card.Body>
      </Card>

      <InternalUsersModal
        show={show}
        id={id}
        validation={validation}
        fetchAssetsByDepartment={fetchAssetsByDepartment}
        handleClose={handleClose}
        statusSelectOpt={statusSelectOpt}
        setStatusSelectOpt={setStatusSelectOpt}
        options={options}
        checkboxes={checkboxes}
        handleCheckboxChange={handleCheckboxChange}
        selectedOptions={selectedOptions}
        handleSelectChange={handleSelectChange}
        allOptions={allOptions}
        selectAllWebsites={selectAllWebsites}
        handleChangeImage={handleChangeImage}
        roleOptions={roleOptions}
        roleSelectOpt={roleSelectOpt}
        setRoleSelectOpt={setRoleSelectOpt}
        departmentOptions={departmentOptions}
        noSelectedImage={noSelectedImage}
        selectedImage={selectedImage}
        departmentSelectOpt={departmentSelectOpt}
        setDepartmentSelectOpt={setDepartmentSelectOpt}
        setSelectedOptions={setSelectedOptions}
        setSelectAllWebsites={setSelectAllWebsites}
        postIsLoading={postIsLoading}
        userData={userData}
      />
    </>
  );
};

export default InternalUsers;
