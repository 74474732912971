import { useState } from "react";
import { LinewithDataLabels } from "../../../Charts/ApexCharts/LineCharts/LineCharts";
import { Box } from "@mui/material";
import { Card } from "react-bootstrap";
// import { useSelector } from "react-redux";
// import { useParams } from "react-router-dom";

const WebsiteAudienceMetrics = (assetsData) => {
  // const params = useParams();

  // const { apiDataLoading, apiLoadingAsset } = useSelector(
  //   (state) => state.onlineAssets
  // );

  const [currentYear, setCurrentYear] = useState(1);

  return (
    <>
      {/* <Card className="main-card metric-card">
        <Card.Header className="d-flex align-items-center justify-content-between flex-wrap gap-3">
          <Box>
            <h4 className="card-title mb-0">Website Audience Metrics</h4>
            <p className="mb-0 mt-2">
              Audience to which the users belonged while on the current range.
            </p>
          </Box>
          {!apiDataLoading &&
            apiLoadingAsset !== (params?.id && JSON.parse(params?.id)) && (
              <Box className="ms-auto">
                <button
                  className="btn btn-primary btn-sm"
                  onClick={() => setCurrentYear("0")}
                >
                  Current Year
                </button>
                <button
                  className="btn btn-sm"
                  onClick={() => setCurrentYear("1")}
                >
                  1Y
                </button>

                <button
                  className="btn btn-sm"
                  onClick={() => setCurrentYear("3")}
                >
                  3Y
                </button>

                <button
                  className="btn btn-sm"
                  onClick={() => setCurrentYear("5")}
                >
                  5Y
                </button>
              </Box>
            )}
        </Card.Header>
        <Box className="card-body">
          {apiDataLoading &&
          apiLoadingAsset === (params?.id && JSON.parse(params?.id)) ? (
            <div className="d-flex flex-column flex-grow-1 justify-content-center align-items-center mt-5">
              <h5 className="card-body text-center">
                We are currently retrieving the analytics data. Please hold on
                for a moment while we gather all the details.
              </h5>
            </div>
          ) : (
            <>
              <LinewithDataLabels
                year={currentYear}
                dataColors='["--vz-primary", "--vz-info"]'
              />
            </>
          )}
        </Box>
      </Card> */}
      <Card className="main-card metric-card">
        <Card.Header className="d-flex align-items-center justify-content-between flex-wrap gap-3">
          <Box>
            <h4 className="card-title mb-0">Website Audience Metrics</h4>
            <p className="mb-0 mt-2">
              Audience to which the users belonged while on the current range.
            </p>
          </Box>
          {assetsData?.assetsData?.isAPIFetched && (
            <Box className="ms-auto">
              <button
                className="btn btn-primary btn-sm"
                onClick={() => setCurrentYear("0")}
              >
                Current Year
              </button>
              <button
                className="btn btn-sm"
                onClick={() => setCurrentYear("1")}
              >
                1Y
              </button>
              <button
                className="btn btn-sm"
                onClick={() => setCurrentYear("3")}
              >
                3Y
              </button>
              <button
                className="btn btn-sm"
                onClick={() => setCurrentYear("5")}
              >
                5Y
              </button>
            </Box>
          )}
        </Card.Header>
        <Box className="card-body">
          {assetsData?.assetsData?.isAPIFetched === false ? (
            <div className="d-flex flex-column flex-grow-1 justify-content-center align-items-center mt-5">
              <div className="card-body text-center">
                <h5 className="text-muted">
                  We are currently retrieving the analytics data. Please hold on
                  for a moment while we gather all the details.
                </h5>
              </div>
            </div>
          ) : (
            <>
              <LinewithDataLabels
                year={currentYear}
                dataColors='["--vz-primary", "--vz-info"]'
              />
            </>
          )}
        </Box>
      </Card>
    </>
  );
};

export default WebsiteAudienceMetrics;
