import { useEffect, useMemo, useState } from "react";
import { Card, Col, Row, ProgressBar } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import {
  fetchAnalyticsChartData,
  fetchAnalyticsData,
  // fetchChartData,
  fetchUserSessionChartData,
  setInitialState,
} from "../../../store/actions";
import { Link, useParams } from "react-router-dom";
import LiveUsersByCountry from "../../NewDashboard/DashboardAnalyticTab/common/LiveUsersByCountry";
import {
  AnalyticsDataRadialChart,
  BarChart,
  AreaChart,
  DonutChart,
  GradientChart,
} from "../../Charts/ApexCharts/LineCharts/LineCharts";
import DataTable from "react-data-table-component";
import common from "../../../Components/Common/common";
import {
  Box,
  FormControl,
  IconButton,
  MenuItem,
  Select,
  Skeleton,
} from "@mui/material";
import {
  DesktopIcon,
  LinkIcon,
  MobileIcon,
  TabletIcon,
} from "../../../Components/Common/SvgIcons";
import { CustomTableStyle } from "../../../Components/Common/constants";

const HitReportDetails = () => {
  const dispatch = useDispatch();
  const params = useParams();

  // Pagination
  const [currentPage] = useState(1);
  const [itemsPerPage] = useState(5);
  const [maxValue, setMaxValue] = useState(0);
  const [selectedDays, setSelectedDays] = useState(null);
  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;

  const {
    analyticsData,
    loading,
    fetch_analytics_data_loading,
    fetch_user_session_chart_loading,
    fetch_chart_data_loading,
    userSessionChartData,
    analyticsChartData,
  } = useSelector((state) => ({
    analyticsData: state.analyticsData.analyticsData,
    loading: state.analyticsData.loading,
    userSessionChartData: state.analyticsData?.userSessionChartData,
    analyticsChartData: state.analyticsData?.analyticsChartData,
    fetch_analytics_data_loading:
      state.analyticsData.fetch_analytics_data_loading,
    fetch_user_session_chart_loading:
      state.analyticsData.fetch_user_session_chart_loading,
    fetch_chart_data_loading: state.analyticsData.fetch_chart_data_loading,
  }));

  const [device, setDevice] = useState({ desktop: 0, tablet: 0, mobile: 0 });

  const acquisitionData = useMemo(() => {
    if (analyticsChartData !== undefined)
      return analyticsChartData[0]?.acquisitions_report
        ? JSON.parse(analyticsChartData[0]?.acquisitions_report)
        : [];
  }, [analyticsChartData]);

  const reportByPathData = useMemo(() => {
    if (analyticsChartData !== undefined)
      return analyticsChartData[0]?.report_by_path
        ? JSON.parse(analyticsChartData[0]?.report_by_path)
        : [];
  }, [analyticsChartData]);

  const reportByPageTitle = useMemo(() => {
    if (analyticsChartData !== undefined)
      return analyticsChartData[0]?.report_by_title
        ? JSON.parse(analyticsChartData[0]?.report_by_title)
        : [];
  }, [analyticsChartData]);

  const reportByGender = useMemo(() => {
    if (analyticsChartData !== undefined)
      return analyticsChartData[0]?.report_by_gender
        ? JSON.parse(analyticsChartData[0]?.report_by_gender)
        : [];
  }, [analyticsChartData]);

  useEffect(() => {
    const data = analyticsData?.usersByDevice || {};
    const desktop = parseInt(data.desktop) || 0;
    const mobile = parseInt(data.mobile) || 0;
    const tablet = parseInt(data.tablet) || 0;

    const total = desktop + tablet + mobile;
    if (total > 0) {
      setDevice({
        desktop: ((desktop / total) * 100).toFixed(2),
        tablet: ((tablet / total) * 100).toFixed(2),
        mobile: ((mobile / total) * 100).toFixed(2),
      });
    }
  }, [analyticsData]);

  useEffect(() => {
    dispatch(fetchAnalyticsData({ assetID: params.id, day: selectedDays }));
    dispatch(fetchUserSessionChartData({ assetID: params.id }));
    dispatch(
      fetchAnalyticsChartData({ assetID: params.id, day: selectedDays })
    );
    return () => {
      dispatch(setInitialState());
    };
  }, [dispatch, params.id, selectedDays]);

  useEffect(() => {
    if (Array.isArray(acquisitionData) && acquisitionData.length > 0) {
      const maxTotalUsers = Math.max(
        ...acquisitionData.map((row) => row.totalUsers)
      );
      const maxConversions = Math.max(
        ...acquisitionData.map((row) => row.conversions)
      );
      setMaxValue(Math.max(maxTotalUsers, maxConversions));
    } else if (Array.isArray(reportByPathData) && reportByPathData.length > 0) {
      const maxSessions = Math.max(
        ...reportByPathData.map((row) => row.sessions)
      );
      setMaxValue(maxSessions);
    }
  }, [acquisitionData, reportByPathData]);

  const handleClickDays = (days) => {
    setSelectedDays(days);
  };

  // const sessionColumns = [
  //   {
  //     name: "Sessions",
  //     value: loading ? (
  //       <Box
  //         display="flex"
  //         justifyContent="center"
  //         alignItems="center"
  //         className="mb-3"
  //       >
  //         <Skeleton variant="text" width={100} height={30} />
  //       </Box>
  //     ) : analyticsData &&
  //       analyticsData?.avg_visits !== null &&
  //       analyticsData?.avg_visits !== undefined ? (
  //       common.formatNumberWithCommas(analyticsData?.avg_visits)
  //     ) : (
  //       "-"
  //     ),
  //   },
  //   {
  //     name: "Pages/Sessions",
  //     value: loading ? (
  //       <Box
  //         display="flex"
  //         justifyContent="center"
  //         alignItems="center"
  //         className="mb-3"
  //       >
  //         <Skeleton variant="text" width={100} height={30} />
  //       </Box>
  //     ) : analyticsData &&
  //       analyticsData?.pages_per_session !== null &&
  //       analyticsData?.pages_per_session !== undefined ? (
  //       analyticsData?.pages_per_session
  //     ) : (
  //       "-"
  //     ),
  //   },
  //   {
  //     name: "Avg. Session Duration",
  //     value: loading ? (
  //       <Box
  //         display="flex"
  //         justifyContent="center"
  //         alignItems="center"
  //         className="mb-3"
  //       >
  //         <Skeleton variant="text" width={100} height={30} />
  //       </Box>
  //     ) : analyticsData &&
  //       analyticsData?.avg_avg_session_duration !== null &&
  //       analyticsData?.avg_avg_session_duration !== undefined ? (
  //       analyticsData?.avg_avg_session_duration
  //     ) : (
  //       "-"
  //     ),
  //   },
  //   {
  //     name: "Bounce Rate",
  //     value: loading ? (
  //       <Box
  //         display="flex"
  //         justifyContent="center"
  //         alignItems="center"
  //         className="mb-3"
  //       >
  //         <Skeleton variant="text" width={100} height={30} />
  //       </Box>
  //     ) : analyticsData &&
  //       analyticsData?.avg_bounce_rate !== null &&
  //       analyticsData?.avg_bounce_rate !== undefined ? (
  //       analyticsData?.avg_bounce_rate + " %"
  //     ) : (
  //       "-"
  //     ),
  //   },
  //   {
  //     name: "Pageviews",
  //     value: loading ? (
  //       <Box
  //         display="flex"
  //         justifyContent="center"
  //         alignItems="center"
  //         className="mb-3"
  //       >
  //         <Skeleton variant="text" width={100} height={30} />
  //       </Box>
  //     ) : analyticsData &&
  //       analyticsData?.avg_pageviews !== null &&
  //       analyticsData?.avg_pageviews !== undefined ? (
  //       analyticsData?.avg_pageviews
  //     ) : (
  //       "-"
  //     ),
  //   },
  // ];

  const sessionColumns = [
    {
      name: "Sessions",
      value:
        analyticsData?.avg_visits !== null &&
        analyticsData?.avg_visits !== undefined
          ? common.formatNumberWithCommas(analyticsData?.avg_visits)
          : "-",
    },
    {
      name: "Pages/Sessions",
      value:
        analyticsData?.pages_per_session !== null &&
        analyticsData?.pages_per_session !== undefined
          ? analyticsData?.pages_per_session
          : "-",
    },
    {
      name: "Avg. Session Duration",
      value:
        analyticsData?.avg_avg_session_duration !== null &&
        analyticsData?.avg_avg_session_duration !== undefined
          ? analyticsData?.avg_avg_session_duration
          : "-",
    },
    {
      name: "Bounce Rate",
      value:
        analyticsData?.avg_bounce_rate !== null &&
        analyticsData?.avg_bounce_rate !== undefined
          ? analyticsData?.avg_bounce_rate + " %"
          : "-",
    },
    {
      name: "Pageviews",
      value:
        analyticsData?.avg_pageviews !== null &&
        analyticsData?.avg_pageviews !== undefined
          ? analyticsData?.avg_pageviews
          : "-",
    },
  ];

  const columns = [
    {
      name: <span>User Medium</span>,
      selector: (row) => (
        <span>
          <span className="fw-bold">{row.firstUserDefaultChannelGroup}</span>
        </span>
      ),
      grow: 2,
      width: "160px",
    },
    {
      name: <span>Total Users</span>,
      selector: (row) => (
        <div
          style={{
            display: "flex",
            alignItems: "center",
            flexDirection: "row",
            justifyContent: "end",
          }}
        >
          <span className="me-2">{row?.totalUsers}</span>
          <ProgressBar
            now={(row.totalUsers / maxValue) * 100}
            className="user-progressbar"
            style={{ width: "150px", height: "17px", minWidth: "150px" }}
          />
        </div>
      ),
      center: true,
    },
    {
      name: <span>Conversions</span>,
      selector: (row) => (
        <div
          style={{
            display: "flex",
            alignItems: "center",
            flexDirection: "row",
            justifyContent: "end",
          }}
        >
          <span className="me-2">{row?.conversions}</span>
          <ProgressBar
            now={(row.conversions / maxValue) * 100}
            className="conversions-progressbar"
            style={{ width: "150px", height: "17px", minWidth: "150px" }}
          />
        </div>
      ),
      center: true,
    },
  ];

  const reportByPathColumns = [
    {
      name: <span>Page</span>,
      selector: (row) => (
        <span>
          <span className="fw-bold">{row?.unifiedPagePathScreen}</span>
        </span>
      ),
      grow: 2,
    },
    {
      name: <span>Pages/Sessions</span>,
      selector: (row) => parseFloat(row?.screenPageViewsPerSession).toFixed(2),
      center: true,
    },
    {
      name: <span>Sessions</span>,
      selector: (row) => parseInt(row?.sessions),
      center: true,
    },
    {
      name: <span>Avg. Session Duration</span>,
      selector: (row) => parseFloat(row?.averageSessionDuration).toFixed(2),
      center: true,
    },
    {
      name: <span>New Sessions</span>,
      selector: (row) => parseInt(row?.newUsers),
      center: true,
    },
    {
      name: <span>Bounce Rate</span>,
      selector: (row) => parseFloat(row?.bounceRate).toFixed(2),
      center: true,
    },
  ];

  const reportByPageTileColumn = [
    {
      name: <span>Page Titles</span>,
      selector: (row) => (
        <span>
          <span className="fw-bold">{row?.PageTitle}</span>
        </span>
      ),
      grow: 2,
    },
    {
      name: <span>Sessions</span>,
      selector: (row) => parseInt(row?.sessions),
      center: true,
    },
    {
      name: <span>Avg. Session Duration</span>,
      selector: (row) => parseFloat(row?.averageSessionDuration).toFixed(2),
      center: true,
    },
    {
      name: <span>Bounce Rate</span>,
      selector: (row) => parseFloat(row?.bounceRate).toFixed(2),
      center: true,
    },
  ];

  const reportBySessionColumns = [
    {
      name: <span>Sessions</span>,
      cell: (row) => (
        <Row className="align-items-center py-2 w-100">
          <Col lg={6} className="mb-2">
            <p className="mb-0 fw-medium">{row?.unifiedPagePathScreen}</p>
          </Col>
          <Col lg={6} className="mb-2 text-end">
            <p className="mb-0 fw-semibold">{row?.sessions}</p>
          </Col>
          <Col lg={12} className="mb-2">
            <div className="progress animated-progress">
              <div
                className="progress-bar bg-success"
                role="progressbar"
                style={{
                  width: `${(row.sessions / maxValue) * 100}%`,
                }}
                aria-valuenow={row.sessions}
                aria-valuemin="0"
                aria-valuemax={maxValue}
              ></div>
            </div>
          </Col>
        </Row>
      ),
      width: "100%",
      minWidth: "100%",
    },
  ];

  // Pagination
  const currentData = reportByPathData?.slice(
    indexOfFirstItem,
    indexOfLastItem
  );

  // Calculations for reportByGender
  const totalSessions = reportByGender?.reduce(
    (acc, curr) => acc + parseInt(curr.sessions),
    0
  );
  const totalUsers = reportByGender?.reduce(
    (acc, curr) => acc + parseInt(curr.newUsers),
    0
  );
  const averageBounceRate =
    reportByGender?.reduce(
      (acc, item) => acc + parseFloat(item.bounceRate),
      0
    ) / reportByGender?.length;
  const averagePagesPerSession =
    reportByGender?.reduce(
      (acc, item) => acc + parseFloat(item.screenPageViewsPerSession),
      0
    ) / reportByGender?.length;
  const averageSessionDuration =
    reportByGender?.reduce(
      (acc, item) => acc + parseFloat(item.averageSessionDuration),
      0
    ) / reportByGender?.length;

  const handleClick = () => {
    const url = analyticsData?.url || "";
    if (url) {
      window.open(url, "_blank", "noopener,noreferrer");
    }
  };

  const statusOptions = [
    { value: "1", label: "Today" },
    { value: "7", label: "Last 7 Days" },
    { value: "30", label: "Last 30 Days" },
    { value: "180", label: "Last 6 Months" },
    { value: "365", label: "Last 1 Year" },
  ];

  //Modified one
  return (
    <>
      <Row className="g-4 mb-4">
        <Col lg={12}>
          <Box
            sx={{
              fontSize: "22px",
              fontWeight: "bold",
              wordBreak: "break-all",
            }}
          >
            {loading ? (
              <Skeleton width={200} height={30} />
            ) : (
              <Link to="#">{analyticsData?.url || "No URL"}</Link>
            )}
            {/* <Link to="#">{analyticsData?.url || "No URL"}</Link> */}
            <IconButton
              onClick={handleClick}
              sx={{
                color: "#98a2b5",
                marginLeft: "20px",
                "&.MuiButtonBase-root": {
                  "svg path": {
                    fill: "#98a2b5",
                    stroke: "#98a2b5",
                  },
                },
              }}
            >
              <LinkIcon width={24} height={24} />
            </IconButton>
          </Box>
          <Box>
            <FormControl
              fullWidth
              variant="filled"
              sx={{ width: "175px", display: "inline-block" }}
            >
              <Select
                displayEmpty
                fullWidth
                value={selectedDays || ""}
                onChange={(e) => handleClickDays(e.target.value)}
                sx={{
                  "& .MuiSelect-select": {
                    paddingTop: "9px",
                    background: "transparent",
                    fontSize: "14px",
                  },
                  "&.MuiInputBase-root": {
                    borderRadius: "8px",
                    background: "#fff",
                    "&::before, &::after": {
                      borderBottom: "0 !important",
                    },
                  },
                }}
              >
                <MenuItem value="" disabled>
                  Select the days
                </MenuItem>
                {statusOptions.map((option) => (
                  <MenuItem key={option.value} value={option.value}>
                    {option.label}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Box>
        </Col>
      </Row>

      <Box className="report-grid-card mb-4">
        {Array.isArray(sessionColumns) &&
          sessionColumns.length > 0 &&
          sessionColumns.map((item, index) => (
            <Box key={index} className="item">
              <Card>
                <Card.Header>
                  <Card.Title
                    style={{
                      fontSize: "16px",
                      marginBottom: "15px",
                      fontWeight: 500,
                      textAlign: "center",
                    }}
                  >
                    {item.name}
                  </Card.Title>
                </Card.Header>
                <Card.Body style={{ textAlign: "center" }}>
                  {/* <p
                    style={{
                      fontWeight: 600,
                      fontSize: "26px",
                      marginBottom: 0,
                    }}
                  >
                    {item.value}
                  </p> */}
                  {loading || item.value === "-" ? (
                    <Box
                      display="flex"
                      justifyContent="center"
                      alignItems="center"
                      className="mb-3"
                    >
                      <Skeleton variant="text" width={100} height={30} />
                    </Box>
                  ) : (
                    <p
                      style={{
                        fontWeight: 600,
                        fontSize: "26px",
                        marginBottom: 0,
                      }}
                    >
                      {/* {item.value} */}
                      {item.value !== null && item.value !== undefined
                        ? item.value
                        : "-"}
                    </p>
                  )}
                </Card.Body>
              </Card>
            </Box>
          ))}
      </Box>

      {/* <Row className="g-4 mb-4">
        <Col xl={4}>
          <Card>
            <Card.Header>
              <h4 className="card-title">Session by Device</h4>
            </Card.Header>
            <Card.Body>
              <DonutChart width="380px" data={analyticsData?.usersByDevice} />
              <Box className="d-flex align-content-start justify-content-center gap-3 mt-3">
                {device?.desktop > 0 && (
                  <Box className="pagespeed-card">
                    <DesktopIcon />
                    <h4 className="time">{`${device.desktop}%`}</h4>
                    <p className="title">Desktop</p>
                  </Box>
                )}
                {device?.tablet > 0 && (
                  <Box className="pagespeed-card">
                    <TabletIcon />
                    <h4 className="time">{`${device.tablet}%`}</h4>
                    <p className="title">Tablet</p>
                  </Box>
                )}
                {device?.mobile > 0 && (
                  <Box className="pagespeed-card">
                    <MobileIcon />
                    <h4 className="time">{`${device.mobile}%`}</h4>
                    <p className="title">Mobile</p>
                  </Box>
                )}
              </Box>
            </Card.Body>
          </Card>
        </Col>
        <Col xl={8}>
          {fetch_analytics_data_loading ||
          fetch_user_session_chart_loading ||
          fetch_chart_data_loading ? (
            <div
              style={{
                display: "flex",
                height: "100%",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <Skeleton variant="rounded" width={500} height={300} />
            </div>
          ) : (
            <LiveUsersByCountry />
          )}
        </Col>
      </Row> */}

      <Row className="g-4 mb-4">
        <Col xl={4}>
          <Card>
            <Card.Header>
              <h4 className="card-title">Session by Device</h4>
            </Card.Header>
            <Card.Body>
              <Box display="flex" justifyContent="center" alignItems="center">
                {fetch_analytics_data_loading ? (
                  <Skeleton variant="circular" width={300} height={300} />
                ) : (
                  <DonutChart
                    width="300px"
                    height="300px"
                    loading={fetch_analytics_data_loading}
                    data={analyticsData?.usersByDevice}
                  />
                )}
              </Box>

              <Box className="d-flex align-content-start justify-content-center gap-3 mt-3">
                {/* Skeletons for Device Usage */}
                {fetch_analytics_data_loading ? (
                  <>
                    <Skeleton variant="rectangular" width={100} height={100} />
                    <Skeleton variant="rectangular" width={100} height={100} />
                    <Skeleton variant="rectangular" width={100} height={100} />
                  </>
                ) : (
                  <>
                    {device?.desktop > 0 && (
                      <Box className="pagespeed-card">
                        <DesktopIcon />
                        <h4 className="time">{`${device.desktop}%`}</h4>
                        <p className="title">Desktop</p>
                      </Box>
                    )}
                    {device?.tablet > 0 && (
                      <Box className="pagespeed-card">
                        <TabletIcon />
                        <h4 className="time">{`${device.tablet}%`}</h4>
                        <p className="title">Tablet</p>
                      </Box>
                    )}
                    {device?.mobile > 0 && (
                      <Box className="pagespeed-card">
                        <MobileIcon />
                        <h4 className="time">{`${device.mobile}%`}</h4>
                        <p className="title">Mobile</p>
                      </Box>
                    )}
                  </>
                )}
              </Box>
            </Card.Body>
          </Card>
        </Col>

        <Col xl={8}>
          {fetch_analytics_data_loading ||
          fetch_user_session_chart_loading ||
          fetch_chart_data_loading ? (
            <div
              style={{
                display: "flex",
                height: "100%",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <Skeleton variant="rounded" width={500} height={300} />
            </div>
          ) : (
            <LiveUsersByCountry />
          )}
        </Col>
      </Row>

      <Row className="g-4 mb-4">
        {analyticsChartData?.[0]?.acquisitions_report && (
          <Col xl={6}>
            <Card>
              <Card.Header>
                <h4 className="card-title">Acquisitions Report</h4>
              </Card.Header>
              <AnalyticsDataRadialChart data={analyticsChartData} />
            </Card>
          </Col>
        )}
        <Col xl={6}>
          <Card>
            <Card.Header>
              <h4 className="card-title">Users & Sessions report</h4>
            </Card.Header>
            <AreaChart data={userSessionChartData} />
          </Card>
        </Col>
      </Row>

      <Row className="g-4 mb-4">
        {acquisitionData?.length > 0 && (
          <Col xxl={6} xl={12}>
            <Card>
              <Card.Body>
                <DataTable
                  columns={columns}
                  data={acquisitionData}
                  fixedHeader
                  fixedHeaderScrollHeight="700px"
                  pagination
                />
              </Card.Body>
            </Card>
          </Col>
        )}
        {currentData?.length > 0 && reportBySessionColumns && (
          <Col xxl={6} xl={12}>
            <Card>
              <Card.Body>
                <DataTable
                  columns={reportBySessionColumns}
                  data={currentData}
                  fixedHeader
                  fixedHeaderScrollHeight="700px"
                  pagination
                  paginationRowsPerPageOptions={[5]}
                  className="session-table"
                />
              </Card.Body>
            </Card>
          </Col>
        )}
      </Row>

      <Row className="g-4 mb-4">
        {reportByPathData?.length > 0 && (
          <Col xxl={12} xl={12}>
            <Card>
              <Card.Header>
                <h4 className="card-title">Report by path</h4>
              </Card.Header>
              <Card.Body>
                <DataTable
                  columns={reportByPathColumns}
                  data={reportByPathData}
                  fixedHeader
                  fixedHeaderScrollHeight="700px"
                  pagination
                  customStyles={CustomTableStyle}
                  className="table-design"
                />
              </Card.Body>
            </Card>
          </Col>
        )}
        {reportByPageTitle?.length > 0 && (
          <Col xxl={12} xl={12}>
            <Card>
              <Card.Header>
                <h4 className="card-title">Top Landing Pages</h4>
              </Card.Header>
              <Card.Body>
                <DataTable
                  columns={reportByPageTileColumn}
                  data={reportByPageTitle}
                  fixedHeader
                  fixedHeaderScrollHeight="700px"
                  pagination
                  customStyles={CustomTableStyle}
                  className="table-design"
                />
              </Card.Body>
            </Card>
          </Col>
        )}
      </Row>

      {(totalSessions ||
        totalUsers ||
        averageBounceRate ||
        averagePagesPerSession ||
        averageSessionDuration) && (
        <Row className="g-4 mb-4">
          <Col xxl={12} xl={12}>
            <Card>
              <Card.Header>
                <h4 className="card-title">Report By Gender</h4>
              </Card.Header>
              <Card.Body>
                <div className="table-responsive table-design report-table">
                  <table style={{ width: "100%", borderCollapse: "collapse" }}>
                    <thead>
                      <tr>
                        <th rowSpan="2">Gender</th>
                        <th colSpan="3">Acquisition</th>
                        <th colSpan="3">Behavior</th>
                      </tr>
                      <tr>
                        <th>Sessions</th>
                        <th>% New Sessions</th>
                        <th>New Users</th>
                        <th>Bounce Rate</th>
                        <th>Pages/Session</th>
                        <th>Avg. Session Duration</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td></td>
                        <td>{`${common.formatNumberWithCommas(
                          totalSessions
                        )} (total)`}</td>
                        <td>-</td>
                        <td>{`${totalUsers} (total)`}</td>
                        <td>{`${parseFloat(averageBounceRate).toFixed(
                          2
                        )} (Avg.)`}</td>
                        <td>{`${parseFloat(averagePagesPerSession).toFixed(
                          2
                        )} (Avg.)`}</td>
                        <td>{`${parseFloat(averageSessionDuration).toFixed(
                          2
                        )} (Avg.)`}</td>
                      </tr>
                      {Array.isArray(reportByGender) &&
                        reportByGender.length > 0 &&
                        reportByGender.map((item, index) => {
                          const percentage =
                            (item.sessions / totalSessions) * 100;
                          return (
                            <tr key={index}>
                              <td>
                                {item.userGender === "unknown"
                                  ? "Other"
                                  : item.userGender}
                              </td>
                              <td>{`${item.sessions} (${percentage.toFixed(
                                2
                              )}%)`}</td>
                              <td>{item.newUsers}</td>
                              <td>{item.newUsers}</td>
                              <td>{parseFloat(item.bounceRate).toFixed(2)}</td>
                              <td>
                                {parseFloat(
                                  item.screenPageViewsPerSession
                                ).toFixed(2)}
                              </td>
                              <td>
                                {parseFloat(
                                  item.averageSessionDuration
                                ).toFixed(2)}
                              </td>
                            </tr>
                          );
                        })}
                    </tbody>
                  </table>
                </div>
              </Card.Body>
            </Card>
          </Col>
        </Row>
      )}

      <Row className="g-4">
        <Col xl={6}>
          <Card>
            <Card.Header>
              <h4 className="card-title">Sessions</h4>
            </Card.Header>
            <GradientChart data={analyticsData?.sessionChart} />
          </Card>
        </Col>

        <Col xl={6}>
          <Card>
            <Card.Header>
              <h4 className="card-title">Pageviews</h4>
            </Card.Header>
            <BarChart data={analyticsData?.pageviewChart} />
          </Card>
        </Col>
      </Row>
    </>
  );
};

export default HitReportDetails;
