import React, { useCallback, useEffect, useState } from "react";
import { Button, CloseButton, Offcanvas } from "react-bootstrap";
import { Input, Form, Col, Label, Row, Spinner } from "reactstrap";
import { useFormik } from "formik";
// import Select from "react-select";
import { ToastContainer } from "react-toastify";
import { useLocation, useNavigate } from "react-router-dom";
import { ToastMessage } from "../../../Components/Common/ToastMessage";
import * as Yup from "yup";
import ProfileImage from "../../../Components/Common/ProfileImage";
import Swal from "sweetalert2";
import BootstrapCards from "./BootstrapCards";
import { VALIDATION_MESSAGE } from "../../../Components/Common/Validation";
import * as req from "../../../Api/Guideilne&Policies/WebsiteGuideline/WebsiteGuidelineReq";
import * as res from "../../../Api/Guideilne&Policies/WebsiteGuideline/WebsiteGuidelineRes";
import addGuidelineDefaultImage from "../../../assets/images/no-img.png";
import _ from "lodash";
import { WEBSITE_GUIDELINE } from "../../../Components/Common/ModuleName";
import { useSelector } from "react-redux";
import { API_ROUTES } from "../../../Api/ApiRoutes";
import {
  Box,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Tooltip,
} from "@mui/material";
import { MenuHeight } from "../../NewDashboard/constant";
import PictureAsPdfIcon from "@mui/icons-material/PictureAsPdf";
import CameraAltIcon from "@mui/icons-material/CameraAlt";
import common from "../../../Components/Common/common";
import axios from "axios";

const addGuidelineSchema = Yup.object({
  imageFile: Yup.mixed().required("Please select the Image."),
  pdfFile: Yup.mixed().required("Please select the PDF."),
  title: Yup.string()
    .trim()
    .max(
      VALIDATION_MESSAGE.TITLE.MAX_LENGTH,
      VALIDATION_MESSAGE.TITLE.MAX_LENGTH_MESSAGE
    )
    .required(VALIDATION_MESSAGE.TITLE.TITLE_MESSAGE),
  asset_url_management: Yup.string()
    .min(1, "Please select at least one website.")
    .required("Please select the website."),
  description: Yup.string()
    .trim()
    .nullable()
    .max(250, "Description must be less than 500 characters."),
  // .required(VALIDATION_MESSAGE.DESCRIPTION.DESCRIPTION_MESSAGE),
});

const updateGuidelineSchema = Yup.object({
  title: Yup.string()
    .trim()
    .max(
      VALIDATION_MESSAGE.TITLE.MAX_LENGTH,
      VALIDATION_MESSAGE.TITLE.MAX_LENGTH_MESSAGE
    )
    .required(VALIDATION_MESSAGE.TITLE.TITLE_MESSAGE),
  description: Yup.string()
    .trim()
    .nullable()
    .max(250, "Description must be less than 500 characters."),
  // .required(VALIDATION_MESSAGE.DESCRIPTION.DESCRIPTION_MESSAGE),
});

const WebsiteGuideline = () => {
  const location = useLocation();
  const token = localStorage.getItem("token");
  const permissionData = useSelector(
    (state) => state.userDetails.userPermission
  );
  const hasPermission = permissionData?.find(
    (item) => item.module_name === WEBSITE_GUIDELINE
  );

  const userId = localStorage.getItem("user_id");
  const [query, setQuery] = useState("");
  const [users, setData] = useState([]);
  const [id, setId] = useState("");
  const [selectedImage, setSelectedImage] = useState(null);
  const [selectedPDF, setSelectedPDF] = useState(null);
  const [show, setShow] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [postIsLoading, setPostIsLoading] = useState(false);

  /////*  FOR WEBSITE DROPDOWN  */////
  const [options, setOptions] = useState([]);
  // const [selectedOption, setSelectedOption] = useState(null);

  const navigate = useNavigate();

  useEffect(() => {
    if (location.state?.globalSearch) {
      setQuery(location.state?.globalSearch);
    }
  }, [location.state]);

  const validation = useFormik({
    enableReinitialize: true,

    initialValues: {
      imageFile: null,
      pdfFile: null,
      title: "",
      asset_url_management: "",
      description: "",
    },

    validationSchema: id ? updateGuidelineSchema : addGuidelineSchema,

    onSubmit: (values) => {
      postData(id, values);
    },
  });

  useEffect(() => {
    axios.post(API_ROUTES.GET_ONLINE_ASSETS).then((data) => {
      const responseData = common.decrypt(data.data);

      const updatedOptions = responseData.map((item) => ({
        value: item.id,
        label: item.website_url,
      }));
      setOptions(updatedOptions);
    });
  }, []);

  const getData = useCallback(
    async (searchQuery) => {
      try {
        let query = searchQuery || "";
        setIsLoading(true);
        let { url, reqData } = req.ListWebsiteGuidelineReq({ query });

        let result = await fetch(url, reqData);
        let response = await result.json();

        const responseData = common.decrypt(response.data);

        res.ListWebsiteGuidelineRes(
          response,
          navigate,
          setData,
          setIsLoading,
          responseData
        );
      } catch (error) {
        setIsLoading(false);
        return null;
      }
    },
    [navigate]
  );

  useEffect(() => {
    if (query === "") {
      getData();
    } else {
      const debouncedFetchData = _.debounce(() => {
        getData(query);
      }, 500);

      debouncedFetchData();

      return () => {
        debouncedFetchData.cancel();
      };
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [query, getData]);

  const postData = async (id, values) => {
    try {
      setPostIsLoading(true);
      const { url, reqData } = req.AddEditWebsiteGuidelineReq({
        userId,
        values,
        id,
      });

      let result = await fetch(url, reqData);
      let response = await result.json();

      const decryptedData = common.decrypt(response.data);
      response.data = decryptedData;

      res.AddEditWebsiteGuidelineRes({
        response,
        navigate,
        getData,
        setShow,
        ToastMessage,
        validation,
        setId,
        id,
      });

      setTimeout(() => {
        validation.setFieldValue("imageFile", null);
        setId("");
        validation.resetForm();
        setSelectedImage(null);
        setSelectedPDF(null);
      }, 1000);
      setPostIsLoading(false);
    } catch (error) {
      setPostIsLoading(false);
      return null;
    } finally {
      setPostIsLoading(false);
    }
  };

  const deleteGuideline = async (id, email) => {
    try {
      let { url, reqData } = req.DeleteWebsiteGuidelineReq({ id, email });

      const result = await fetch(url, reqData);
      let response = await result.json();

      res.DeleteWebsiteGuidelineRes(response, navigate, getData);
    } catch (err) {
      return null;
    }
  };

  const handleDeleteAlert = (id, email) => {
    const swalWithBootstrapButtons = Swal.mixin({
      customClass: {
        confirmButton: "btn btn-dark mx-2",
        cancelButton: "btn btn-primary mx-2",
      },
      buttonsStyling: false,
    });

    swalWithBootstrapButtons
      .fire({
        title: "Are you sure?",
        text: "You won't be able to revert this!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes, delete it!",
        cancelButtonText: "No, cancel!",
        reverseButtons: true,
      })
      .then((result) => {
        if (result.isConfirmed) {
          deleteGuideline(id, email);
          swalWithBootstrapButtons.fire(
            "Deleted!",
            "Website Guideline has been deleted.",
            "success"
          );
        } else if (result.dismiss === Swal.DismissReason.cancel) {
          swalWithBootstrapButtons.fire(
            "Cancelled",
            "Website Guideline is safe :)",
            "error"
          );
        }
      });
  };

  const updateGuideline = (user) => {
    setShow(true);
    validation.setValues({
      imageFile: user.website_guideline_image,
      pdfFile: user.website_guideline_pdf,
      title: user.website_guideline_title,
      asset_url_management: user.assets_id,
      description:
        user.website_guideline_description == null
          ? ""
          : user.website_guideline_description,
    });
    setId(user.id);
  };

  const handleChangeImage = (event) => {
    const file = event.target.files[0];

    if (file) {
      const allowedFormats = [
        "image/jpeg",
        "image/jpg",
        "image/png",
        "image/svg+xml",
      ];
      const validSize = file.size <= 1024 * 1024;

      if (!allowedFormats.includes(file.type)) {
        validation.setFieldError(
          "imageFile",
          "Invalid file format. Only JPEG, JPG, PNG, and SVG files are allowed."
        );
        event.target.value = null;
      } else if (!validSize) {
        validation.setFieldError(
          "imageFile",
          "Failed to upload an image. The image maximum size is 1MB."
        );
        event.target.value = null;
      } else {
        validation.setFieldError("imageFile", "");
        validation.setFieldValue("imageFile", file);
        const imageUrl = URL.createObjectURL(file);
        setSelectedImage(imageUrl);
      }
    }
  };

  const handleChangePDF = (event) => {
    const file = event.target.files[0];

    if (file) {
      const allowedFormats = ["application/pdf"];
      const validSize = file.size <= 15 * 1024 * 1024;

      if (!allowedFormats.includes(file.type)) {
        validation.setFieldError(
          "pdfFile",
          "Invalid file format. Only PDF files are allowed."
        );
        event.target.value = null;
      } else if (!validSize) {
        validation.setFieldError(
          "pdfFile",
          "Failed to upload pdf. The pdf maximum size is 15MB."
        );
        event.target.value = null;
      } else {
        validation.setFieldError("pdfFile", "");
        validation.setFieldValue("pdfFile", file);
        const imageUrl = URL.createObjectURL(file);
        setSelectedPDF(imageUrl);
      }
    }
  };

  const handleClose = () => {
    setShow(false);
    setTimeout(() => {
      validation.setFieldValue("imageFile", null);
      setId("");
      validation.resetForm();
      setSelectedImage(null);
      setSelectedPDF(null);
    }, 1000);
  };

  const handleWebsiteChange = (event) => {
    const selectedValue = event.target.value;
    validation.setFieldValue("asset_url_management", selectedValue);
  };

  return (
    <>
      <ToastContainer closeButton={false} />

      <Row className="g-2 mb-4">
        <Col xxl={5} md={6}>
          <Box className="search-box">
            <Input
              type="text"
              className="form-control bg-white"
              placeholder="Search by title & description"
              onChange={(e) => setQuery(e.target.value)}
            />
            <i className="ri-search-line search-icon"></i>
          </Box>
        </Col>
        {hasPermission && hasPermission?.canCreate !== null && (
          <>
            <Col className="col-sm-auto ms-md-auto">
              <Box className="list-grid-nav hstack gap-1">
                <Button
                  type="button"
                  className="btn btn-primary"
                  onClick={() => setShow(true)}
                >
                  <i className="ri-add-fill me-1 align-bottom"></i>Add Website
                  Guideline
                </Button>
              </Box>
            </Col>
          </>
        )}
      </Row>

      <Offcanvas
        show={show}
        onHide={handleClose}
        placement="end"
        backdrop="static"
        style={{ width: "31rem" }}
      >
        <Offcanvas.Header>
          <Offcanvas.Title>
            {id ? "update website Guideline" : "Add Website Guideline"}{" "}
          </Offcanvas.Title>
          <CloseButton onClick={handleClose} />
        </Offcanvas.Header>
        <Offcanvas.Body>
          <Form
            onSubmit={validation.handleSubmit}
            className="needs-validation"
            noValidate
            action="index"
          >
            <Row className="g-3">
              <Col lg={12}>
                <label
                  className="w-100 d-flex align-items-center"
                  htmlFor="imageFile"
                >
                  Upload Image *
                  <Tooltip
                    title={common.LOGOTOOLTIP}
                    placement="top-start"
                    arrow
                  >
                    <i
                      className="ri-information-line fs-18 cursor-pointer ms-2"
                      style={{
                        color: "#ced4da",
                      }}
                    ></i>
                  </Tooltip>
                </label>
                <Box
                  className="profile-user mx-auto"
                  sx={{
                    width: "100%",
                    height: "10rem",
                    border: "1px solid #ddd",
                  }}
                >
                  {selectedImage ? (
                    <img
                      name="developers_profile_image"
                      src={selectedImage}
                      className="user-profile-image"
                      alt="user-profile"
                    />
                  ) : validation.values ? (
                    <img
                      name="developers_profile_image"
                      src={
                        validation.values.imageFile != null
                          ? `${process.env.REACT_APP_BASE_URL}${validation.values.imageFile}`
                          : addGuidelineDefaultImage
                      }
                      className="user-profile-image"
                      alt="user-profile"
                    />
                  ) : (
                    <ProfileImage />
                  )}
                  <Box className="avatar-xs p-0 rounded-circle profile-photo-edit">
                    <Input
                      id="profile-img-file-input"
                      type="file"
                      name="imageFile"
                      accept="image/*"
                      onChange={(e) => {
                        handleChangeImage(e);
                        validation.setFieldTouched("imageFile", true, false);
                      }}
                      className="profile-img-file-input"
                    />
                    <Label
                      htmlFor="profile-img-file-input"
                      className="profile-photo-edit me-2"
                    >
                      <span className="avatar-title rounded-circle bg-light text-body">
                        <CameraAltIcon />
                      </span>
                    </Label>
                  </Box>
                </Box>
                {validation.errors.imageFile && validation.touched.imageFile ? (
                  <span className="error-text">
                    {validation.errors.imageFile}
                  </span>
                ) : null}
              </Col>
              <Col lg={12}>
                <label htmlFor="pdfFile" className="d-flex align-items-center">
                  Upload Website Guidelines PDF *
                  <Tooltip title={common.PDFTOOLTIP} placement="top-end" arrow>
                    <i
                      className="ri-information-line fs-18 cursor-pointer ms-2"
                      style={{
                        color: "#ced4da",
                      }}
                    ></i>
                  </Tooltip>
                </label>
                <Box
                  className="profile-user mx-auto"
                  sx={{
                    width: "100%",
                    height: "10rem",
                    border: "1px solid #ddd",
                  }}
                >
                  {selectedPDF ? (
                    <embed
                      name="developers_profile_pdf"
                      src={selectedPDF}
                      height="100%"
                      width="100%"
                      alt="Website-Guideline-pdf"
                    />
                  ) : validation.values.pdfFile !== null ? (
                    <embed
                      name="developers_profile_pdf"
                      height="100%"
                      width="100%"
                      src={`${process.env.REACT_APP_BASE_URL}${validation.values.pdfFile}`}
                      alt="Website-Guideline-pdf"
                    />
                  ) : (
                    <Box className="h-100 d-flex align-items-center justify-content-center">
                      No PDF selected
                    </Box>
                  )}
                  <Box className="avatar-xs p-0 rounded-circle profile-photo-edit">
                    <Input
                      id="profile-pdf-file-input"
                      type="file"
                      name="developers_profile_pdf"
                      accept=".pdf"
                      onChange={(e) => {
                        handleChangePDF(e);
                        validation.setFieldTouched("pdfFile", true, false);
                      }}
                      className="profile-pdf-file-input profile-img-file-input"
                    />
                    <Label
                      htmlFor="profile-pdf-file-input"
                      className="profile-photo-edit me-2"
                    >
                      <span className="avatar-title rounded-circle bg-light text-body">
                        <PictureAsPdfIcon />
                      </span>
                    </Label>
                  </Box>
                </Box>
                {validation.errors.pdfFile && validation.touched.pdfFile ? (
                  <span className="error-text">
                    {validation.errors.pdfFile}
                  </span>
                ) : null}
              </Col>
              <Col lg={12}>
                {/* <label htmlFor="title">Title *</label> */}
                <TextField
                  fullWidth
                  label="Title *"
                  id="title"
                  name="title"
                  placeholder="Enter title"
                  {...validation.getFieldProps("title")}
                />
                {validation.touched.title && validation.errors.title ? (
                  <span className="error-text">{validation.errors.title}</span>
                ) : null}
              </Col>
              <Col lg={12}>
                <FormControl fullWidth>
                  <InputLabel id="asset-url-management-label">
                    Select an website *
                  </InputLabel>
                  <Select
                    labelId="asset-url-management-label"
                    MenuProps={MenuHeight}
                    // value={selectedOption ? selectedOption.value : ""}
                    value={validation.values.asset_url_management || ""}
                    onChange={handleWebsiteChange}
                    onBlur={validation.handleBlur}
                    label="Select an website *"
                  >
                    {options?.map((option) => (
                      <MenuItem key={option.value} value={option.value}>
                        {option.label}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
                {validation.errors.asset_url_management &&
                validation.touched.asset_url_management ? (
                  <span className="error-text">
                    {validation.errors.asset_url_management}
                  </span>
                ) : null}
              </Col>
              <Col lg={12}>
                <Box>
                  <TextField
                    id="outlined-size-small"
                    name="description"
                    label="Description"
                    placeholder="Enter Short Description"
                    variant="outlined"
                    fullWidth
                    multiline
                    rows={2}
                    value={validation.values.description}
                    onBlur={validation.handleBlur}
                    onChange={validation.handleChange}
                  />
                  {validation.errors.description &&
                    validation.touched.description && (
                      <span className="error-text">
                        {validation.errors.description}
                      </span>
                    )}
                </Box>
              </Col>
              <Col lg={12}>
                <Button
                  className="btn-dark"
                  type="submit"
                  disabled={postIsLoading}
                >
                  {postIsLoading ? (
                    <Spinner size="sm" variant="light" />
                  ) : (
                    // <>{id ? "update" : "Add"}</>
                    "Submit"
                  )}
                </Button>
                <Button onClick={handleClose} className="btn-primary ms-2">
                  Cancel
                </Button>
              </Col>
            </Row>
          </Form>
        </Offcanvas.Body>
      </Offcanvas>

      <Row className="team-list grid-view-filter g-4">
        {isLoading ? (
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              height: "50vh",
            }}
          >
            <Spinner height={100} width={100} />
          </div>
        ) : users.length > 0 ? (
          users.map((user) => (
            <BootstrapCards
              key={user.id}
              user={user}
              color={"info"}
              mapKey={user.id}
              updateGuideline={updateGuideline}
              handleDeleteAlert={handleDeleteAlert}
              hasPermission={hasPermission}
            />
          ))
        ) : (
          !isLoading && (
            <li
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                height: "50vh",
              }}
              className="list-group-item ps-0 pe-0 mt-4 text-center h5"
            >
              No Record Found
            </li>
          )
        )}
      </Row>
    </>
  );
};

export default WebsiteGuideline;
