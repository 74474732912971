import React, { useEffect, useRef, useState } from "react";
import DataTable from "react-data-table-component";
import { CustomTableStyle } from "../../Components/Common/constants";
import { Alert } from "@mui/material";
import axios from "axios";
import { useSelector } from "react-redux";
import { formatDateTime } from "../../Components/Common/FormatDateTIme";
import ViewEmailLogModal from "./ViewEmailLogModal";
import InfoIcon from "@mui/icons-material/Info";
import VisibilityIcon from "@mui/icons-material/Visibility";
import AttachFileIcon from "@mui/icons-material/AttachFile";
import LoopIcon from "@mui/icons-material/Loop";
import Flatpickr from "react-flatpickr";
import moment from "moment";
import { Box, FormControl, MenuItem, Select } from "@mui/material";
import { Button, Spinner } from "reactstrap";
import { Card } from "react-bootstrap";
import common from "../../Components/Common/common";
import { DropdownStyle, MenuHeight } from "../NewDashboard/constant";

const useDebounce = (value, delay) => {
  const [debouncedValue, setDebouncedValue] = useState(value);
  useEffect(() => {
    const handler = setTimeout(() => {
      setDebouncedValue(value);
    }, delay);
    return () => {
      clearTimeout(handler);
    };
  }, [value, delay]);
  return debouncedValue;
};

const EmailLogs = () => {
  const token = localStorage.getItem("token");

  const [emailLogData, setEmailLogData] = useState([]);
  const [totalRowCount, setTotalRowCount] = useState("");
  const [loading, setLoading] = useState(true);
  const [perPage, setPerPage] = useState(10);
  const [modalShow, setModalShow] = useState(false);
  const [currentRowSelected, setCurrentRowSelected] = useState("");
  const [previewTempletFlag, setPreviewTempleFlag] = useState(false);
  const [selectedDate, setSelectedDate] = useState([]);
  const [searchedUserEmail, setSearchedUserEmail] = useState("");
  const [selectedStatusOption, setSelectedStatusOption] = useState("");
  const flatpickrRef = useRef(null);

  const generalSettingData = useSelector(
    (state) => state.settingsData.settingsData
  );
  const otherSettingsData = generalSettingData?.filter(
    (item) => item.settings_category === "other"
  );

  const dateFormat = otherSettingsData?.find(
    (date) => date.field_name === "DEFAULT_DATE_FORMAT"
  )?.field_value;

  const timeZone = otherSettingsData?.find(
    (time) => time.field_name === "DEFAULT_TIME_FORMAT"
  )?.field_value;

  const timeFormat = otherSettingsData?.find(
    (zone) => zone.field_name === "DEFAULT_TIME_ZONE"
  )?.field_value;

  const handleDateChange = (selectedDates) => {
    if (selectedDates[0] !== undefined) {
      setSelectedDate(selectedDates);
    }
  };

  const debouncedQuerySearchUser = useDebounce(searchedUserEmail, 500);

  const statusOptions = [
    { label: "SUCCESS", value: "SUCCESS" },
    { label: "FAILURE", value: "FAILURE" },
  ];

  let [startDate, endDate] = selectedDate;
  if (startDate) {
    startDate = moment(startDate).format("YYYY-MM-DD");
  }
  if (endDate) {
    endDate = moment(endDate).format("YYYY-MM-DD");
  }

  const getAllEmailLogsData = async (
    page,
    perPageSize,
    startDate,
    endDate,
    searchedUserEmail,
    selectedStatusOption
  ) => {
    try {
      // setLoading(true);
      let params = {
        page,
        limit: perPageSize,
      };
      if (startDate) params.startDate = startDate;
      if (endDate) params.endDate = endDate;
      if (searchedUserEmail) params.searchedUserEmail = searchedUserEmail;
      if (selectedStatusOption) params.status = selectedStatusOption;

      let response = await axios.get(`/emailLogs/getEmailLogs`, {
        params,
        headers: { Authorization: `Bearer ${token}` },
      });

      const decryptedData = common.decrypt(response.data);
      response.data = decryptedData;

      if (response.status === "success") {
        setTotalRowCount(response.data?.count);
        setEmailLogData(response.data?.rows);
      }
    } catch (error) {
      console.error("Errror -->", error.message);
    } finally {
      setLoading(false);
    }
  };

  const handleReset = () => {
    if (flatpickrRef.current) {
      flatpickrRef.current.flatpickr.clear();
    }
    setSelectedDate([]);
    setSearchedUserEmail("");
    setSelectedStatusOption("");
  };

  useEffect(() => {
    getAllEmailLogsData(
      1,
      perPage,
      startDate,
      endDate,
      debouncedQuerySearchUser,
      selectedStatusOption
    );
  }, [
    perPage,
    startDate,
    endDate,
    debouncedQuerySearchUser,
    selectedStatusOption,
  ]);

  const handlePageChange = (page) => {
    getAllEmailLogsData(
      page,
      perPage,
      startDate,
      endDate,
      debouncedQuerySearchUser,
      selectedStatusOption
    );
  };

  const handlePerRowsChange = async (newPerPage) => {
    setPerPage(newPerPage);
    getAllEmailLogsData(1, newPerPage);
  };

  const capitalEachLetterOfWord = (str) => {
    let splitStr = str.toLowerCase().split(" ");
    for (let i = 0; i < splitStr.length; i++) {
      splitStr[i] =
        splitStr[i].charAt(0).toUpperCase() + splitStr[i].substring(1);
    }
    return splitStr.join(" ");
  };

  const columns = [
    // {
    //   name: "Sr. No",
    //   selector: (row, index) => index + 1,
    //   width: "100px",
    // },
    {
      name: "Module",
      selector: (row) =>
        capitalEachLetterOfWord(row.module_name.replaceAll("-", " ")),
    },
    {
      name: "Email Send To",
      selector: (row) => row.toEmail,
    },
    {
      name: "Email preview",
      cell: (row) => (
        <div
          onClick={() => {
            setPreviewTempleFlag(true);
            setCurrentRowSelected(row);
            setModalShow(true);
          }}
        >
          {/* <InfoIcon style={{ cursor: "pointer" }} /> */}
          <VisibilityIcon style={{ cursor: "pointer" }} />
        </div>
      ),
      width: "120px",
      center: true,
    },
    {
      name: "Attachment Preview",
      cell: (row) =>
        row.attachments !== null ? (
          <div
            onClick={() => {
              setCurrentRowSelected(row);
              setModalShow(true);
            }}
          >
            <AttachFileIcon style={{ cursor: "pointer" }} />
          </div>
        ) : (
          <div>-</div>
        ),
      width: "170px",
      center: true,
    },
    {
      name: "Status",
      selector: (row) =>
        row.status === "SUCCESS" ? (
          <Alert severity="success">SUCCESS</Alert>
        ) : (
          <Alert severity="error">"FAILURE"</Alert>
        ),
      center: true,
    },
    {
      name: "Email Send At",
      selector: (row) =>
        formatDateTime(row.createdAt, dateFormat, timeZone, timeFormat).timeZone
          .formattedDateFinal +
        " - " +
        formatDateTime(row.createdAt, dateFormat, timeZone, timeFormat).timeZone
          .formattedTimed,
    },
  ];

  return (
    <div>
      <Card className="table-main-card">
        <Box className="table-card-header">
          <Box className="left-side">
            {/* Search box */}
            <Box className="search-box">
              <input
                type="text"
                value={searchedUserEmail}
                className="form-control"
                placeholder="Email send to..."
                onChange={(e) => {
                  setSearchedUserEmail(e.target.value);
                }}
              />
              <i className="ri-search-line search-icon"></i>
            </Box>
            {/* Select tag for status */}
            <FormControl
              fullWidth
              variant="filled"
              sx={{
                width: "150px",
                minWidth: "150px",
              }}
            >
              <Select
                displayEmpty
                fullWidth
                value={selectedStatusOption}
                onChange={(e) => {
                  setSelectedStatusOption(e.target.value);
                }}
                sx={DropdownStyle}
                MenuProps={MenuHeight}
              >
                <MenuItem disabled value="">
                  Select Action
                </MenuItem>
                {statusOptions.map((option) => (
                  <MenuItem
                    key={option.value}
                    value={option.value}
                    sx={{ whiteSpace: "break-spaces" }}
                  >
                    {option.label}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            {/* Select date for email logs */}
            <Box className="dateinput">
              <Flatpickr
                placeholder="Select Date"
                ref={flatpickrRef}
                className="form-control"
                options={{
                  dateFormat: `${dateFormat}`,
                  mode: "range",
                }}
                value={selectedDate}
                onChange={handleDateChange}
              />
            </Box>
            {/* Clear filter for email logs */}
            <Button className="btn btn-primary reset-btn" onClick={handleReset}>
              <LoopIcon />
            </Button>
          </Box>
        </Box>
        <Card.Body>
          {loading ? (
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                height: "50vh",
              }}
            >
              <Spinner animation="border" />
            </div>
          ) : (
            <DataTable
              // title="Email-Logs"
              columns={columns}
              data={emailLogData}
              progressPending={loading}
              pagination
              paginationServer
              paginationTotalRows={totalRowCount}
              onChangePage={handlePageChange}
              onChangeRowsPerPage={handlePerRowsChange}
              customStyles={CustomTableStyle}
              paginationRowsPerPageOptions={[10, 20, 30, 40, 50]}
              className="table-design"
            />
          )}
        </Card.Body>

        {modalShow && (
          <ViewEmailLogModal
            show={modalShow}
            onHide={() => {
              setModalShow(false);
              setPreviewTempleFlag(false);
            }}
            isTemplet={previewTempletFlag}
            currentRow={currentRowSelected}
          />
        )}
      </Card>
    </div>
  );
};

export default EmailLogs;
