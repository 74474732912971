import React, { useCallback, useEffect, useRef, useState } from "react";
import Flatpickr from "react-flatpickr";
import { Card, Form } from "react-bootstrap";
import DataTable from "react-data-table-component";
import "react-toastify/dist/ReactToastify.css";
import _ from "lodash";
import { UptimeReportListReq } from "../../../Api/Reporting/UptimeReport/UptimeReportReq";
import { UptimeReportListRes } from "../../../Api/Reporting/UptimeReport/UptimeReportRes";
import { useNavigate } from "react-router-dom";
import LoopIcon from "@mui/icons-material/Loop";
import { Box } from "@mui/material";
import { CustomTableStyle } from "../../../Components/Common/constants";
import common from "../../../Components/Common/common";
import { Spinner } from "react-bootstrap";
import { useSelector } from "react-redux";
import { changeSidebarState } from "../../../store/actions";
import { useDispatch } from "react-redux";

const UptimeReport = () => {
  const [data, setData] = useState([]);
  const [query, setQuery] = useState("");
  const [selectedDate, setSelectedDate] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const flatpickrRef = useRef(null);
  const navigate = useNavigate();

  const generalSettingData = useSelector(
    (state) => state.settingsData.settingsData
  );

  const otherSettingsData = generalSettingData?.filter(
    (item) => item.settings_category === "other"
  );

  const dateFormat = otherSettingsData?.find(
    (date) => date.field_name === "DEFAULT_DATE_FORMAT"
  )?.field_value;
  const dispatch = useDispatch();

  const handleDateChange = (selectedDates) => {
    setSelectedDate(selectedDates);
    if (selectedDates[1] !== undefined) {
      // fetchData(selectedDates[0], selectedDates[1], query);
    }
  };

  const handleReset = () => {
    setQuery("");
    if (flatpickrRef.current) {
      flatpickrRef.current.flatpickr.clear();
    }
    setSelectedDate(null);
    fetchData(null, null, query);
  };

  const fetchData = useCallback(async (startDate, endDate, params) => {
    try {
      const { url, reqData } = UptimeReportListReq({
        startDate,
        endDate,
        params,
      });

      const response = await fetch(url, reqData);
      const data = await response.json();
      const responseData = common.decrypt(data.data);

      UptimeReportListRes({ data, setData, responseData });
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
      return null;
    }
  }, []);

  useEffect(() => {
    fetchData(null, null, query);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fetchData]);

  useEffect(() => {
    if (selectedDate) fetchData(selectedDate[0], selectedDate[1], query);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedDate]);

  useEffect(() => {
    const debouncedFetchData = _.debounce(() => {
      if (selectedDate) fetchData(selectedDate[0], selectedDate[1], query);
      else fetchData(null, null, query);
    }, 500);

    debouncedFetchData();

    return () => {
      debouncedFetchData.cancel();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [query]);

  const columns = [
    {
      name: <span>URL </span>,
      selector: (row) => (
        <span
          onClick={() => {
            dispatch(changeSidebarState("Reporting"));
            navigate(`/uptime-report/${row.assets_id}`);
          }}
        >
          <span className="fw-bold cursor-pointer">{row.url || "-"}</span>
        </span>
      ),
      grow: 2,
    },
    {
      name: <span>Average Uptime</span>,
      selector: (row) => (
        <span className="badge badge-soft-success">
          {row.avg_uptime != null ? `${row.avg_uptime} %` : " - "}
        </span>
      ),
      center: true,
    },
    {
      name: <span>Last Reboot</span>,
      selector: (row) => (
        <span>{row.last_reboot ? `${row.last_reboot} ago` : " - "}</span>
      ),
    },
    {
      name: <span>Server Type</span>,
      selector: (row) => (
        <span>{row.server?.length > 2 ? <>{row.server}</> : <>-</>}</span>
      ),
    },
  ];

  return (
    <>
      <Card className="table-main-card">
        <Box className="table-card-header">
          <Box className="left-side">
            <Box className="search-box">
              <Form.Control
                className="form-control"
                type="text"
                placeholder="Search for URL..."
                value={query}
                onChange={(e) => setQuery(e.target.value)}
              />
              <i className="ri-search-line search-icon"></i>
            </Box>
            <Box className="dateinput">
              <Flatpickr
                placeholder="Select Date Range"
                ref={flatpickrRef}
                className="form-control"
                options={{
                  dateFormat: `${dateFormat}`,
                  mode: "range",
                }}
                value={selectedDate}
                onChange={handleDateChange}
              />
            </Box>
            <button className="btn btn-primary reset-btn" onClick={handleReset}>
              <LoopIcon />
            </button>
          </Box>
        </Box>
        <Card.Body>
          {isLoading ? (
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                height: "50vh",
              }}
            >
              <Spinner height={100} width={100} />
            </div>
          ) : (
            <DataTable
              columns={columns}
              data={data}
              fixedHeader
              fixedHeaderScrollHeight="700px"
              pagination
              customStyles={CustomTableStyle}
              className="table-design"
            />
          )}
        </Card.Body>
      </Card>
    </>
  );
};

export default UptimeReport;
