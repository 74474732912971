import { styled } from "@mui/material/styles";
import { transform } from "lodash";

const TabStyle = {
  "&.MuiTabs-root": {
    borderRadius: "16px 16px 0 0",
    minHeight: "auto",
  },
  "& .MuiTabScrollButton-root": {
    padding: "0 !important",
  },
  "& .MuiTabScrollButton-root.Mui-disabled": {
    width: 0,
  },
  "& .MuiButtonBase-root": {
    color: "#1A2232",
    background: "#E3EAEE",
    fontSize: "16px",
    fontWeight: 700,
    textTransform: "capitalize",
    padding: "12px 50px",
    margin: "0 1px",
    minHeight: "auto",
    fontFamily: '"Open Sans",sans-serif',
    "&:first-child.Mui-selected": {
      "&::before": {
        background: "transparent",
      },
    },
    "&:last-child.Mui-selected": {
      "&::after": {
        background: "transparent",
      },
    },
  },
  "& .Mui-selected": {
    color: "#1A2232 !important",
    background: "transparent",
    margin: "0 -1px",
    "&::before": {
      content: "''",
      position: "absolute",
      width: "16px",
      height: "100%",
      background: "#e4eaee",
      left: "0px",
      borderRadius: "0 0 16px 0",
      bottom: "0px",
    },
    "&::after": {
      content: "''",
      position: "absolute",
      width: "16px",
      height: "100%",
      background: "#e4eaee",
      right: "0px",
      borderRadius: "0 0 0 16px",
      bottom: "0px",
    },
  },
  "& .MuiTabs-indicator": {
    height: 0,
  },
};

const WebSecuTabStyle = {
  "&.MuiTabs-root": {
    borderRadius: "16px 16px 0 0",
    minHeight: "auto",
    background: "#fff",
  },
  "& .MuiTabScrollButton-root": {
    padding: "0 !important",
  },
  "& .MuiTabs-flexContainer": {
    alignItems: "center",
    justifyContent: "center",
  },
  "& .MuiTabScrollButton-root.Mui-disabled": {
    width: 0,
  },
  "& .MuiButtonBase-root": {
    color: "#1A2232",
    background: "#E3EAEE",
    fontSize: "16px",
    fontWeight: 700,
    textTransform: "capitalize",
    padding: "12px 50px",
    margin: "0 1px",
    minHeight: "auto",
    fontFamily: '"Open Sans",sans-serif',
    "@media (max-width:767.98px)": {
      fontSize: "16px",
      padding: "12px",
    },
    "&:first-of-type.Mui-selected": {
      "&::before": {
        background: "transparent",
      },
    },
    "&:last-child.Mui-selected": {
      "&::after": {
        background: "transparent",
      },
    },
  },
  "& .Mui-selected": {
    color: "#1A2232 !important",
    background: "transparent",
    margin: "0 -1px",
    "&::before": {
      content: "''",
      position: "absolute",
      width: "16px",
      height: "100%",
      background: "#e4eaee",
      left: "0px",
      borderRadius: "0 0 16px 0",
      bottom: "0px",
    },
    "&::after": {
      content: "''",
      position: "absolute",
      width: "16px",
      height: "100%",
      background: "#e4eaee",
      right: "0px",
      borderRadius: "0 0 0 16px",
      bottom: "0px",
    },
  },
  "& .MuiTabs-indicator": {
    height: 0,
  },
};

const PerformanceTabStyle = {
  "&.MuiTabs-root": {
    borderRadius: "16px 16px 0 0",
    minHeight: "auto",
    background: "#fff",
    "@media (min-width:768px)": {
      width: "fit-content",
    },
  },
  "& .MuiTabScrollButton-root": {
    padding: "0 !important",
    background: "#f3f6f9",
    "@media (min-width:768px)": {
      display: "none",
    },
  },
  "& .MuiTabScrollButton-root.Mui-disabled": {
    width: 0,
  },
  "& .MuiTab-root": {
    color: "#1A2232",
    background: "#E3EAEE",
    fontSize: "16px",
    fontWeight: 700,
    textTransform: "capitalize",
    minHeight: "auto",
    fontFamily: '"Open Sans",sans-serif',
    borderRadius: "0 0 16px 16px",
    marginLeft: "-24px",
    padding: "10px 50px",
    position: "relative",
    "&:nth-child(1)": {
      background: "#f3f6f9",
      zIndex: "9",
    },
    "&:nth-child(3)": {
      background: "#dae0e3",
    },
    "&:last-child": {
      borderRadius: "0 0 0 16px",
    },
    "@media (max-width:767.98px)": {
      padding: "10px 50px",
    },
    svg: {
      marginRight: "10px",
    },
  },
  "& .Mui-selected": {
    color: "#1A2232 !important",
    background: "transparent !important",
  },
  "& .MuiTabs-indicator": {
    height: 0,
  },
};

const DropdownStyle = {
  "& .MuiSelect-select": {
    paddingTop: "9px",
    background: "transparent",
    fontSize: "14px",
  },
  "&.MuiInputBase-root": {
    borderRadius: "8px",
    background: "#F5F7F8",
    "&::before, &::after": {
      borderBottom: "0 !important",
    },
  },
  "& .MuiFilledInput-input:focus": {
    background: "transparent",
  },
};
const AutoDropdownStyle={
  "& .MuiFormControl-root": {
    borderRadius: "8px",
    background: "#F5F7F8",
  },
  "& .MuiFormLabel-root": {
    fontSize: "14px",
    margin: "0",    
    top: "-5px",
  },
  "& .MuiFormLabel-root.MuiInputLabel-shrink":{
    top: "2px",
    color: "inherit",
  },
  "& .MuiInputBase-input":{
    fontSize: "14px",
  },
  "& .MuiOutlinedInput-root": {
    padding: "3px 9px",
  },
  fieldset: {
    border: "0",
  }
};
const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuHeight = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

const VisuallyHiddenInput = styled("input")({
  clip: "rect(0 0 0 0)",
  clipPath: "inset(50%)",
  height: 1,
  overflow: "hidden",
  position: "absolute",
  bottom: 0,
  left: 0,
  whiteSpace: "nowrap",
  width: 1,
});

export {
  TabStyle,
  WebSecuTabStyle,
  PerformanceTabStyle,
  DropdownStyle,
  MenuHeight,
  VisuallyHiddenInput,
  AutoDropdownStyle
};
